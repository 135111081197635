import { PublicClientApplication } from "@azure/msal-browser";
import { User } from "@carbon/icons-react";
import { msalConfig } from "authConfig";
import axios from "axios";
import axiosInstance from "axiosintance";
import { getErrorMessageAndCode } from "getErrorFromResponse";
import proimg from "img/profile.png";

export const getSalesForceDataService = async () => {
  const msalInstance = new PublicClientApplication(msalConfig);
  let azureId = msalInstance.getAllAccounts()[0]?.idTokenClaims?.sub;

  try {
    const { data } = await axiosInstance.post(
      process.env.REACT_APP_BACKEND_URL + "/sftoken"
    );
    let contactDataResponse = data.response;
    const { data: userData } = await axios.get(
      `${contactDataResponse?.instance_url}/services/apexrest/ContactInfo?azureId=${azureId}`,
      {
        headers: {
          Authorization: `Bearer ${contactDataResponse?.access_token}`,
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    const companyInfo = userData.companyInfo;
    const contactInfoData = userData.contactInfo;
    let profileImage;
    if (contactInfoData?.recordId !== undefined) {
      let profileImageRes = await axios.get(
        `${contactDataResponse?.instance_url}/services/apexrest/ProfileImage?contactId=${contactInfoData?.recordId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${contactDataResponse?.access_token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      profileImage = profileImageRes.data.base64
        ? "data:image/png;base64," + profileImageRes.data.base64
        : "https://cdn.uvation.com/identity/image/User.png";
    }

    return {
      companyInfo,
      contactInfo: contactInfoData,
      contactData: contactDataResponse,
      profileImage,
      //   companyname,
    };
  } catch (err) {
    console.warn(err, "eeee");
    let error = getErrorMessageAndCode(err);
    throw error;
  }
};
