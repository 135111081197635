import styled from "styled-components";
const StatusWrapper = styled.div`
  /* position: relative; */
  /* background-color: #f4f4f4; */
  background: url("/requestbackgound.png"), #f4f4f4;
  background-position: right top;
  background-repeat: no-repeat;
  background-size: contain;

  @media screen and (max-width: 768px) {
    background: url("/mobilebackgroundgraphic.png"), #f4f4f4;
    background-position: top right 0;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .cds--toolbar-content .cds--search .cds--search-input {
    background-color: ${(props) =>
      props.theme.colorScheme === "white" ? "#fff" : "#262626"};
  }

  .cds--pagination {
    background-color: ${(props) =>
      props.theme.colorScheme === "white" ? "#fff" : "#262626"};
  }

  .cds--table-toolbar {
    background-color: ${(props) =>
      props.theme.colorScheme === "white" ? "#fff" : "#262626"};
  }

  .cds--dropdown {
    background-color: ${(props) =>
      props.theme.colorScheme === "white" ? "#fff" : "#262626"};
  }

  .cds--data-table-content:focus {
    outline: none !important;
  }

  .header {
    padding: 32px 0;
    @media screen and (max-width: 768px) {
      padding: 32px 0 25px 0;
    }

    h4 {
      font-family: IBM Plex Sans;
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0.32px;
      text-align: left;
      font-style: normal;
      text-transform: uppercase;
      z-index: 1;
      color: ${(props) =>
        props.theme.colorScheme === "white" ? "#525252" : "#ffff"};
    }
    h1 {
      font-family: IBM Plex Sans;
      font-size: 28px;
      font-weight: 500;
      line-height: 36px;
      font-style: normal;
      letter-spacing: 0px;
      z-index: 1;
      text-align: left;
      color: ${(props) =>
        props.theme.colorScheme === "white" ? "#000" : "#fff"};

      @media screen and (max-width: 768px) {
        line-height: 44px;
      }
    }
    &::after {
      /* content: url("/requestbackgound.png");
      position: absolute;
      right: 0;
      z-index: 0;
      top: 0; */

      @media screen and (max-width: 768px) {
        /* content: url("/headerimagemobile.png"); */
      }
    }
  }
  .plans-content {
    padding: 32px 0;

    .indicate {
      margin-bottom: 32px;
    }
    p {
      font-family: Arial;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      letter-spacing: 0.16px;
    }
  }

  .data_table {
    margin: 2rem 0;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05);

    .datatable_switcher {
      .cds--content-switcher-btn::after,
      .cds--content-switcher-btn.cds--content-switcher--selected {
        background-color: ${(props) =>
          props.theme.colorScheme == "white"
            ? "#393939"
            : "#161616"} !important;
      }
    }
  }

  .city_datatable {
    tbody {
      background-color: var(--cds-background);

      tr {
        &:nth-child(even) td {
          background-color: ${(props) =>
            props.theme.colorScheme === "white"
              ? "#f4f4f4"
              : "#161616"} !important;
        }

        &:nth-child(odd) td {
          background-color: ${(props) =>
            props.theme.colorScheme === "white"
              ? "#fff"
              : "#262626"} !important;
        }
      }
    }
  }

  .status_card_data {
    display: flex;
    align-items: center;
    /* background-color: #fff; */
    background: var(--field, #f4f4f4);
    gap: 10px;
    flex: 1;
    padding: 16px;

    color: ${(props) =>
      props.theme.colorScheme === "white" ? "#000" : "#fff"};
    border: 2px solid transparent;

    @media screen and (max-width: 768px) {
      width: 100%;
      height: 51px;
      /* margin-left: 2.3rem; */
    }

    @media screen and (min-width: 769px) and (max-width: 1028px) {
      width: 83%;
      height: 55px;
      margin-left: 3rem;
    }

    &:hover,
    &:hover .status_card_Page {
      cursor: pointer;
      background-color: ${(props) =>
        props.theme.colorScheme === "white"
          ? "#e0e0e0 !important"
          : "rgb(61, 61, 61) !important"};
    }

    .status_card_Page {
      width: 100%;
      display: flex;
      background: var(--field, #f4f4f4);
      justify-content: space-between;
      .status_card_heading {
        font-family: IBM Plex Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.16px;
      }
    }
  }
  .icon_maintence {
    display: flex;
    border-radius: 24px;
    padding: 4px;
    flex-direction: column;
    align-items: flex-start;
    svg {
      fill: ${(props) =>
        props.theme.colorScheme == "white" ? " #fff" : " #000"};
    }
  }
  .sub_heading {
    display: flex;
    align-items: center;
    gap: 20px;
    .indicate_icon {
      /* width: 40px; */
      /* display: flex;
      justify-content: center;
      align-items: center; */

      /* height: 40px; */
      /* border-radius: 100%; */
      /* flex-shrink: 0; */
      /* background-color: #f4f4f4; */
    }
  }
  .service_heading {
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05);
    padding: 16px 16px 32px 16px;
    /* min-height: 100%; */
    min-height: 515px;
    height: 515px;
    overflow-y: scroll;
    background-color: ${(props) =>
      props.theme.colorScheme == "white" ? " #fff" : "#262626"};
    &::-webkit-scrollbar {
      display: none;
    }
    .single_status_page {
      ul {
        display: flex;
        gap: 24px;
        flex-direction: column;
        li {
          span {
            color: var(
              --text-text-secondary,
              var(--text-text-secondary, #525252)
            );
            font-family: IBM Plex Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
      .status_data {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #fff;
        font-size: 12px;
        border-radius: 24px;

        @media screen and (max-width: 1024px) {
          align-items: flex-start;
        }
      }
    }
    .mainpara_graph {
      h6 {
        font-family: IBM Plex Sans;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        height: 50px;
      }
    }
  }
  /* Style.css or Style.scss */
  .last_updates {
    padding: 0 20px;
    position: relative;

    @media screen and (max-width: 768px) {
      padding: 10px 0px;
    }

    &::before {
      content: "";
      content: "";
      background: #c6c6c6;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 1px;

      @media screen and (max-width: 768px) {
        width: 0px;
        height: 0;
      }
    }
    .update_icon {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 10px;
    }
  }
  .cds--data-table-container {
    min-height: 100%;
  }
  ul {
    display: flex;
    gap: 24px;
    flex-direction: column;
    li {
      p {
        color: var(--text-text-secondary, var(--text-text-secondary, #525252));
        font-family: IBM Plex Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      span {
        color: var(--text-text-secondary, var(--Text-text-secondary, #525252));
        font-family: IBM Plex Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 128.571% */
        letter-spacing: 0.16px;
      }
    }
  }

  .status {
    /* .data_table .datatable_switcher .cds--content-switcher-btn::after {
      background-color: ${(props) =>
      props.theme.colorScheme == "white" ? "#393939" : "#161616"} !important;
    } */
    .status_card_data {
      &.active {
        border-color: var(--cds-link-primary);
        opacity: 1;
        filter: none;
      }
    }
  }
`;
export default StatusWrapper;
