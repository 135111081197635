import React, { useContext, useEffect, useState } from "react";
import TrainingSingleWrapper from "./style";
import {
  Column,
  Row,
  CodeSnippet,
  Breadcrumb,
  BreadcrumbItem,
  Dropdown,
  Layer,
} from "@carbon/react";
import { Link as ScrollLink } from "react-scroll";
import {
  List,
  Number_1,
  Number_2,
  Number_3,
  Number_4,
  Number_5,
} from "@carbon/icons-react";
import { GetSingleDocuments } from "redux/slices/TraningGuidens/TrainingGuidensSlice";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetSingleArticals } from "redux/slices/RecommendArticals/RecommendSlice";
import { useRef } from "react";
import { GlobalContext } from "ContextApi/GlobalContext";
import { useWindowSize } from "@uidotdev/usehooks";
import ReactDOM from "react-dom";
import { SelectElement } from "@twilio-paste/core";

const TrainingSingle = () => {
  const { singleData } = useSelector((state) => state.Document);
  const { getSingleRecommendedData } = useSelector((state) => state.Articals);
  const context = useContext(GlobalContext);
  const dropdownRef = useRef();
  const { width } = useWindowSize();

  const location = useLocation();
  const lastSegment = location.pathname.split("/").pop();
  const dispatch = useDispatch();
  const { state } = useLocation();

  const items = [
    {
      label: (
        <div className="icon_div">
          <Number_1 style={{ marginRight: "8px" }} size={16} />
          {"Go to home page"}
        </div>
      ),
    },

    {
      label: (
        <div className="icon_div">
          <Number_2 style={{ marginRight: "8px" }} />
          {"CTA"}
        </div>
      ),
    },
    {
      label: (
        <div className="icon_div">
          <Number_3 style={{ marginRight: "8px" }} />
          {"Chose main service/Product and add-on"}
        </div>
      ),
    },
    {
      label: (
        <div className="icon_div">
          <Number_4 style={{ marginRight: "8px" }} />
          {"Sign agreement"}
        </div>
      ),
    },
  ];

  const [selectedItem, setSelectedItem] = useState(items[0].label);

  useEffect(() => {
    const dropdown = dropdownRef.current;

    if (dropdown) {
      const icon = dropdown.querySelector(".cds--list-box__menu-icon");
      if (icon) {
        if (icon) {
          const svgIcon = <List size={16} />;
          icon.innerHTML = "";
          ReactDOM.render(svgIcon, icon);
        }
      }
    }
  }, [width <= 768]);

  useEffect(() => {
    // Set initial selected item of dropdown
    setSelectedItem(items[0]);
  }, []);

  const handleDropdownChange = (event) => {
    setSelectedItem(event.selectedItem.value);
    const selectedElement = document.getElementById(
      event.selectedItem.label.props.children[1]
    );

    // Scroll to the selected element
    if (selectedElement) {
      selectedElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };

  useEffect(() => {
    dispatch(GetSingleDocuments(lastSegment));
    dispatch(GetSingleArticals(lastSegment));
  }, [location]);

  return (
    <TrainingSingleWrapper className="dark:!bg-[#161616] dark:!text-[#fff]">
      {width <= 768 && state && (
        <div className="dropdown dropdownsticky  dark:!bg[#161616]">
          <Dropdown
            ref={dropdownRef}
            id="default"
            selectedItem={selectedItem}
            onChange={handleDropdownChange}
            items={items}
            itemToString={(item) => (item ? item.label : "")}
          />
        </div>
      )}

      <section className="header dark:!bg-[#161616]">
        <div>
          <Breadcrumb noTrailingSlash>
            <BreadcrumbItem>
              <Link
                to={
                  singleData?.data?.data?.data?.data &&
                  singleData?.data?.data?.data?.data != []
                    ? "/training/Training-For-admins"
                    : "/training/knowledge-base"
                }
              >
                Training & Guides
              </Link>
            </BreadcrumbItem>

            <BreadcrumbItem isCurrentPage className="dark:!text-[#fff]">
              {lastSegment.length > 3 && width <= 1024
                ? lastSegment.split("-").slice(0, 3).join(" ") + "..."
                : lastSegment.split("-").slice(0, lastSegment.length).join(" ")}
            </BreadcrumbItem>
          </Breadcrumb>

          <h1>
            {" "}
            {singleData?.data?.data?.data?.data &&
            singleData?.data?.data?.data?.data != []
              ? "Training For Admins"
              : "Knowledge Base"}
          </h1>
        </div>

        <ul>
          <li style={{ listStyle: "none" }}>
            {singleData?.data?.data?.data?.data &&
            singleData?.data?.data?.data?.data != []
              ? "2 min"
              : "6 min"}
          </li>
          <li> Module </li>
          <li> 4 Units </li>
        </ul>
      </section>

      <section className="content dark:!bg-[#262626]">
        <Row>
          {width > 768 && (
            <Column lg={3} className="scroll-spy-side-nav-col">
              <div className="scroll-spy-link-box sticky top-[70px] dark:!text-[#fff]">
                {singleData?.data?.data?.data?.data &&
                  singleData?.data?.data?.data?.data.map((item, index) => (
                    <div className="filter_data">
                      <ScrollLink
                        key={item.heading}
                        to={item.heading}
                        activeClass="active dark:!bg-[#262626]"
                        spy={true}
                        as={Link}
                        href="javascript:void(0)"
                        smooth={true}
                        duration={500}
                        offset={-100}
                      >
                        <div
                          className="spy-box-content"
                          style={{ padding: "16px" }}
                        >
                          {index === 0 && <Number_1 size={16} />}
                          {index === 1 && <Number_2 size={16} />}
                          {index === 2 && <Number_3 size={16} />}
                          {index === 3 && <Number_4 size={16} />}
                          {index === 4 && <Number_5 size={16} />}

                          {item.heading.length > 18
                            ? item.heading.slice(0, 18) + "..."
                            : item.heading}
                        </div>
                      </ScrollLink>
                    </div>
                  ))}
              </div>
            </Column>
          )}

          <Column lg={13}>
            <div className="code-snippet-container">
              <div className="box" id="title-1">
                <div className="description">
                  {singleData?.data?.data?.data?.data &&
                    singleData?.data?.data?.data?.data.map((item, index) => (
                      <div key={index}>
                        <h5 id={item.heading} style={{ paddingTop: "8px" }}>
                          {item.heading}
                        </h5>

                        <div key={item.desc} className="box">
                          <p>{item.desc}</p>
                          <img src={item.image} />
                        </div>

                        {index !=
                          singleData?.data?.data?.data?.data.length - 1 && (
                          <hr
                            style={{
                              color:
                                context.globalTheme == "white"
                                  ? "#E0E0E0"
                                  : "#474747",
                            }}
                          ></hr>
                        )}
                      </div>
                    ))}

                  <p
                    dangerouslySetInnerHTML={{
                      __html:
                        getSingleRecommendedData?.data?.data?.data
                          ?.product_desc,
                    }}
                  />
                </div>
                {/* -----------------------------------------codecnipet */}
              </div>
            </div>
          </Column>
        </Row>
      </section>
    </TrainingSingleWrapper>
  );
};

export default TrainingSingle;
