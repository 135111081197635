import {
  Close,
  Document,
  FlagFilled,
  Headphones,
  Send,
  SendFilled,
  Upload,
  User,
} from "@carbon/icons-react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Column,
  CopyButton,
  Dropdown,
  FileUploaderDropContainer,
  FileUploaderItem,
  FlexGrid,
  Form,
  ProgressIndicator,
  ProgressStep,
  Row,
  Tag,
  TextArea,
  TextInput,
} from "@carbon/react";
import { MessageSchema, tagSchema } from "Valdation/Validation";
import CustomButton from "components/button/customButton";
import { FieldArray, Formik } from "formik";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import {
  removeAttachment,
  setAtachment,
  setTicketData,
  setUpdatecomment,
} from "redux/slices/TicketInfo/TicketinfoSlice";
import { UpdateInfoEmailcc } from "redux/slices/TicketInfo/UpdateemailSlice";
import {
  handleSupportTab,
  handleSupportTabIndex,
} from "redux/slices/headerSlice";
import { getServiceRequestStatusText } from "utils";
import * as Yup from "yup";
import axiosInstance from "../../axiosintance";
import { TicketDetailsWrapper, TicketchatWrapper } from "./TicketStyle";
import { Contextprovider } from "App";
import LoadingOverlay from "components/LoadingOverlay/LoadingOverlay";
import { GlobalContext } from "ContextApi/GlobalContext";
import useWindowSize from "hooks/useWindowSize";

const TicketInfo = () => {
  var ticketId = window.location.pathname.split("ticketdetails/").pop();
  const dispatch = useDispatch();
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const { useralldata } = useContext(Contextprovider);
  const context = useContext(GlobalContext);
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const [inputField, setInputField] = useState("");
  const [operation, setOperation] = useState("cc_add");

  const { Ticketinfo, Ticketattachment, updatecomment } = useSelector(
    (state) => state.setticketinfo
  );

  const current_email_ccsdata = useSelector((state) =>
    state.CCemail.UpdateCC.data?.data?.audit?.events?.find(
      (data) => !!data.current_email_ccs
    )
  );

  const isTicketUpdating = useSelector(
    (state) => state.CCemail.UpdateCC.status === "loading"
  );

  const updateCommentAPIStatus = useSelector(
    (state) => state.setticketinfo.updatecomment.status
  );

  const ReduxDispatch = useDispatch();

  const location = useLocation();
  const [comment, setcomment] = useState([]);
  const [multiSelectError, setMultiSelectError] = useState(null);
  const [delEmail, setDelEmail] = useState([]);
  const [user, setuser] = useState([
    {
      user_id: "",
      user_name: "",
      user_img: "",
    },
    {
      user_id: "",
      user_name: "",
      user_img: "",
    },
    {
      user_id: "",
      user_name: "",
      user_img: "",
    },
    {
      user_id: "",
      user_name: "",
      user_img: "",
    },
    {
      user_id: "",
      user_name: "",
      user_img: "",
    },
  ]);

  const getComment = async () => {
    await axiosInstance
      .post(`${process.env.REACT_APP_BACKENDURL_SUPPORT}/ticket_data`, {
        ticketId,
      })
      .then((response) => {
        setcomment(
          response.data.comments.map((tic) => ({
            ...comment,
            id: tic.id,
            name: tic.author_id,
            service: tic.author_id,
            time: moment(tic.created_at).format("LLL"),
            message: tic.body,
            attachUrlId: tic.attachments.map((data) => {
              return data?.id;
            }),
            attachurlname: tic.attachments.map((data) => {
              return data?.file_name;
            }),
            attachUrl: tic.attachments.map((data) => {
              return data.content_url;
            }),
          }))
        );

        setuser(
          response.data.users.map((usr) => ({
            user_id: usr.id,
            user_name: usr.name,
            user_img: usr.photo,
          }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getComment();
  }, [updatecomment?.data, ticketId, location]);

  useEffect(() => {
    ReduxDispatch(
      UpdateInfoEmailcc({
        ticketId,
        operation,
        primaryEmail: useralldata?.userinfo?.email,
        del_Email: delEmail,
      })
    );
    ReduxDispatch(setTicketData(ticketId));
  }, [ticketId, location]);

  async function handleFileRemove(attachmentId, commentIndex) {
    const fd = new FormData();
    fd.append("attachment_id", attachmentId);

    await axiosInstance
      .post(process.env.REACT_APP_BACKENDURL_SUPPORT, fd)
      .catch((err) => {});

    const comm = structuredClone(comment);

    const attachmentIndex = comment[commentIndex].attachUrlId.findIndex(
      (idx) => idx === attachmentId
    );

    comm[commentIndex].attachUrlId.splice(attachmentIndex, 1);
    comm[commentIndex].attachurlname.splice(attachmentIndex, 1);
    comm[commentIndex].attachUrl.splice(attachmentIndex, 1);

    setcomment(comm);
  }

  function handleAddEmail(callback) {
    // let inputValue = event.target.value;

    // const hasCompleteEmail = inputValue.includes(",");
    // console.log("hasCompleted", hasCompleteEmail);
    // if (!hasCompleteEmail) return;

    const [email, other] = inputField?.split(",");

    try {
      Yup.string().email().validateSync(email.trim());
      setMultiSelectError(null);
      // event.target.value = other;
      callback(email);
      setInputField("");
    } catch (error) {
      setMultiSelectError("Invalid Email");
    }
  }

  return (
    <>
      <TicketDetailsWrapper className="dark:!bg-[#161616] dark:!text-[#fff]">
        <FlexGrid fullWidth className="ticket_Wrapper">
          <div className="main_headingdiv">
            <Row>
              <Column lg={8}>
                <div className="main_heading">
                  <Breadcrumb>
                    {/* <BreadcrumbItem>
                      <Link to="/" className="dark:!text-[#77A6FF]">
                        Support Dashboard
                      </Link>
                    </BreadcrumbItem> */}
                    {/* <BreadcrumbItem>
                      <Link to="/" className="dark:!text-[#77A6FF]">
                        Create A Service Request
                      </Link>
                    </BreadcrumbItem> */}
                    <BreadcrumbItem>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // dispatch(handleSupportTab(true));
                          // dispatch(handleSupportTabIndex(1));
                          navigate("/request");
                        }}
                        className="dark:!text-[#77A6FF]"
                      >
                        Support Request
                      </span>
                    </BreadcrumbItem>
                    <span
                      className="dark:!text-[#fff]"
                      style={{ color: "#161616" }}
                    >
                      Request {Ticketinfo.data.id}
                    </span>
                  </Breadcrumb>

                  <h1> Request {Ticketinfo.data.id}</h1>
                </div>
              </Column>
              <Column lg={8}>
                <div className="progress_indication">
                  <ProgressIndicator
                    spaceEqually
                    size="lg"
                    currentIndex={
                      Ticketinfo.data.status === "open"
                        ? 1
                        : 0 || Ticketinfo.data.status === "pending"
                        ? 2
                        : 0 || Ticketinfo.data.status === "closed"
                        ? 4
                        : 0 || Ticketinfo.data.status === "solved"
                        ? 6
                        : 0
                    }
                  >
                    <ProgressStep
                      label="Opened"
                      // className="dark:!text[#fff]"
                      secondaryLabel={moment(Ticketinfo.data.time).format(
                        " MMMM Do , YYYY"
                      )}
                    />
                    <ProgressStep
                      label="Assigned"
                      secondaryLabel="A support agent has been assigned."
                    />
                    <ProgressStep
                      label="In Progress"
                      secondaryLabel="The request has been received."
                    />
                    <ProgressStep
                      label="Resolved"
                      secondaryLabel="The request has been resolved."
                    />
                  </ProgressIndicator>
                </div>
              </Column>
            </Row>
          </div>

          <TicketchatWrapper className="relative">
            <LoadingOverlay visible={Ticketinfo.status === "loading"} />

            {/* <FlexGrid
            fullWidth
            className="dark:!bg-[#262626] "
            style={{
              // backgroundColor: "#f4f4f4",
              padding: width <= 768 ? "24px 16px" : "24px 40px",
            }}
          >
            <div className="chat_heading ">
              <h4>{Ticketinfo?.data?.subject}</h4>
              <div className="technical_problem flex items-center gap-3 py-2">
                <FlagFilled size={16} color="#525252" />
                <p>{Ticketinfo?.data?.casetype}</p>
              </div>
            </div>
          </FlexGrid> */}

            <section className="sub_div">
              {/* <FlexGrid fullWidth className=" dark:!bg-[#161616]"> */}
              <div className="top_container">
                <Row>
                  <Column lg={11} style={{ borderRight: "1px solid #c6c6c6" }}>
                    <div className="chat_heading ">
                      <h4>{Ticketinfo?.data?.subject}</h4>
                      <div className="technical_problem flex items-center gap-3 py-2">
                        <FlagFilled size={16} color="#525252" />
                        <p>{Ticketinfo?.data?.casetype}</p>
                      </div>
                    </div>
                    <div className="container">
                      <div className="support_chatdiv-main">
                        {comment.map((chat) => (
                          <div
                            className="support_div dark:!border-[#474747]"
                            style={{
                              flexDirection:
                                chat.service === user[0].user_id
                                  ? "row"
                                  : "column",
                              alignItems:
                                chat.service === user[0].user_id
                                  ? "flex-start"
                                  : "flex-end",
                            }}
                          >
                            <div style={{ width: "70%" }}>
                              <div style={{ display: "flex", gap: "8px" }}>
                                <div
                                  className={
                                    chat.service === user[0].user_id &&
                                    "headphon_icon"
                                  }
                                >
                                  {chat.service === user[0].user_id && (
                                    <Headphones size={16} />
                                  )}
                                </div>

                                <div style={{ width: "100%" }}>
                                  <div
                                    style={{
                                      textAlign:
                                        chat.name === user[0].user_id
                                          ? "left"
                                          : "right",
                                    }}
                                  >
                                    <span>
                                      {
                                        chat.name === user[0].user_id
                                          ? "Support Team"
                                          : "" || chat.name === user[1].user_id
                                        // ? user[1].user_name
                                        // : "" || chat.name === user[2].user_id
                                        // ? user[2].user_name
                                        // : ""
                                      }
                                      &nbsp;&nbsp;
                                      {moment(chat.time).format("dddd, h:mmA")}
                                    </span>
                                  </div>
                                  <div
                                    className="suport_temheading "
                                    style={{
                                      backgroundColor:
                                        chat.name === user[0].user_id &&
                                        context.globalTheme == "white"
                                          ? "white"
                                          : chat.name === user[0].user_id &&
                                            context.globalTheme != "white"
                                          ? "#161616"
                                          : context.globalTheme == "white"
                                          ? "#474747"
                                          : "#7E7E7E",
                                    }}
                                  >
                                    <div className="chat">
                                      <p
                                        style={{
                                          color:
                                            chat.name === user[0].user_id &&
                                            context.globalTheme == "white"
                                              ? "#161616"
                                              : "white",
                                        }}
                                      >
                                        {
                                          chat.name === user[0].user_id
                                            ? `Hi, ${user[1].user_name}`
                                            : "" ||
                                              chat.name === user[1].user_id
                                          // ? ""
                                          // : "" || chat.name === user[2].user_id
                                          // ? user[2].user_name
                                          // : ""
                                        }
                                        <div
                                          className="message"
                                          style={{
                                            padding:
                                              chat.name === user[0].user_id
                                                ? "24px 0px"
                                                : "0",
                                          }}
                                        >
                                          {chat.message}
                                        </div>
                                        {chat.attachUrl != "" ? (
                                          <a
                                            href={chat.attachUrl}
                                            target="_blank"
                                          >
                                            <img
                                              src={chat.attachUrl}
                                              style={{
                                                width: "150px",
                                                height: "150px",
                                                aspectRatio: "1 / 1",
                                                objectFit: "cover",
                                                marginTop: "1rem",
                                                borderRadius: "5px 5px",
                                              }}
                                              alt="attachUrl"
                                            />
                                          </a>
                                        ) : (
                                          ""
                                        )}
                                        {chat.name === user[0].user_id &&
                                          "Thank you,"}
                                        {chat.name === user[0].user_id && (
                                          <br />
                                        )}
                                        {
                                          chat.name === user[0].user_id
                                            ? "Support Team"
                                            : "" ||
                                              chat.name === user[1].user_id
                                          // ? user[1].user_name
                                          // : "" || chat.name === user[2].user_id
                                          // ? user[2].user_name
                                          // : ""
                                        }
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>

                      <Formik
                        initialValues={{ messages: "" }}
                        validationSchema={MessageSchema}
                        onSubmit={(values, { resetForm }) => {
                          setUploadError(null);
                          ReduxDispatch(
                            setUpdatecomment({
                              values,
                              Ticketinfo,
                              Ticketattachment,
                              ticketId,
                            })
                          );
                          resetForm();
                          setSelectedImage(null);
                        }}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          setFieldValue,
                        }) => (
                          <div className="chat_description dark:!border-[#474747]">
                            <Form onSubmit={handleSubmit}>
                              {" "}
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  position: "relative",
                                }}
                              >
                                <span className="message_length">
                                  {values.messages?.trim().length
                                    ? values.messages
                                        .split(" ")
                                        .filter((val) => val.length).length
                                    : 0}
                                  / 100
                                </span>
                              </div>
                              <TextArea
                                className="max-[1055px]:mb-3"
                                aria-label="response here"
                                invalid={touched.messages && !!errors.messages}
                                invalidText={errors.messages}
                                labelText="Add Comments Here"
                                placeholder="Send a message to our support team."
                                required
                                name="messages"
                                rows={4}
                                value={values.messages}
                                onBlur={handleBlur}
                                onChange={(event) => {
                                  const words =
                                    event.target.value
                                      .split(" ")
                                      .filter((val) => val.length).length > 100;

                                  if (words) return;

                                  handleChange(event);
                                }}
                              />
                              <div
                                className={`flex items-center pt-[40px] gap-8 ${
                                  selectedImage
                                    ? "justify-between"
                                    : "justify-end"
                                } `}
                              >
                                {selectedImage && width > 600 && (
                                  <div className="file-uploader-preview-container gap-2">
                                    <div
                                      className={`flex flex-col w-full gap-3`}
                                    >
                                      <div className="flex justify-between gap-3">
                                        <div className="flex gap-1">
                                          <Document size={16} />
                                          <span
                                            className="file_name "
                                            style={{ maxWidth: "unset" }}
                                          >
                                            {selectedImage}
                                          </span>
                                        </div>

                                        <span className="percentage">
                                          {Ticketattachment.progress ?? 0}%
                                        </span>
                                      </div>

                                      <div className="loader-container">
                                        <div
                                          className="loader"
                                          style={{
                                            width: `${
                                              Ticketattachment.progress ?? 0
                                            }%`,
                                          }}
                                        />
                                      </div>
                                    </div>

                                    <Button
                                      disabled={
                                        Ticketattachment.status === "loading"
                                      }
                                      renderIcon={Close}
                                      hasIconOnly
                                      size="sm"
                                      kind="ghost"
                                      iconDescription="Remove"
                                      tooltipPosition="bottom"
                                      onClick={() => {
                                        setSelectedImage(null);
                                        dispatch(removeAttachment());
                                      }}
                                    />
                                  </div>
                                )}

                                <div className="btn-data item-center center ml-auto">
                                  <div className="flex flex-col items-end">
                                    <div className="uploadefile w-max ">
                                      <FileUploaderDropContainer
                                        className="dark:!border-[#0f62fe]"
                                        style={{
                                          border: "1px dashed #161616",
                                          blockSize: "3rem",
                                          display: "flex",
                                          alignItems: "center",
                                          width:
                                            width <= 768 ? "164px" : "144px",
                                        }}
                                        labelText={
                                          <span
                                            style={{
                                              color: "#0f62fe",
                                              display: "flex",
                                              width: "100%",
                                              gap: "5px",
                                              alignItems: "center",
                                            }}
                                          >
                                            Upload Files
                                            <Upload size={16} />
                                          </span>
                                        }
                                        multiple={true}
                                        accept={["image/jpeg", "image/png"]}
                                        disabled={
                                          Ticketattachment.status === "loading"
                                        }
                                        onAddFiles={(e) => {
                                          const file = e.target.files[0];
                                          if (
                                            file.size >
                                            500 * 1024 // 500KB
                                          ) {
                                            return setUploadError(
                                              "Image size can not be greater than 500KB"
                                            );
                                          }

                                          setUploadError(null);
                                          setSelectedImage(file.name);
                                          ReduxDispatch(setAtachment(e));
                                        }}
                                        size="xs"
                                      />
                                    </div>

                                    {uploadError && (
                                      <p className="error">{uploadError}</p>
                                    )}
                                  </div>

                                  <div className="sendbtn">
                                    <CustomButton
                                      type="submit"
                                      style={{
                                        width: width <= 768 ? "164px" : "144px",
                                      }}
                                      icon={SendFilled}
                                      isLoading={
                                        updateCommentAPIStatus === "loading"
                                      }
                                      text={"Send"}
                                    />
                                  </div>
                                </div>
                              </div>
                            </Form>

                            {selectedImage && width <= 600 && (
                              <div className="file-uploader-preview-container gap-2 pt-[32px]">
                                <div className={`flex flex-col w-full gap-3`}>
                                  <div className="flex justify-between gap-3">
                                    <div className="flex gap-1">
                                      <Document size={20} />
                                      <span className="file_name">
                                        {selectedImage}
                                      </span>
                                    </div>

                                    <span className="percentage">
                                      {Ticketattachment.progress ?? 0}%
                                    </span>
                                  </div>

                                  <div className="loader-container">
                                    <div
                                      className="loader"
                                      style={{
                                        width: `${
                                          Ticketattachment.progress ?? 0
                                        }%`,
                                      }}
                                    />
                                  </div>
                                </div>

                                <Button
                                  disabled={
                                    Ticketattachment.status === "loading"
                                  }
                                  renderIcon={Close}
                                  hasIconOnly
                                  size="sm"
                                  kind="ghost"
                                  iconDescription="Remove"
                                  tooltipPosition="bottom"
                                  onClick={() => {
                                    setSelectedImage(null);
                                    dispatch(removeAttachment());
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </Formik>
                    </div>
                  </Column>

                  <Column lg={5}>
                    <div className="container container_small">
                      <div className="dark:!border-[#474747] case_type">
                        <div
                          style={{
                            borderBottom: "none",
                            borderRight: "none",
                          }}
                        >
                          <Formik
                            initialValues={{
                              tags:
                                current_email_ccsdata?.current_email_ccs || [],
                            }}
                            enableReinitialize
                            validationSchema={tagSchema}
                            onSubmit={(
                              values,
                              { resetForm, setSubmitting }
                            ) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                              }

                              // resetForm();
                            }}
                          >
                            {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              setFieldValue,
                              setSubmitting,
                              isSubmitting,
                              isValid,
                            }) => (
                              <>
                                {" "}
                                <div
                                  className="case_div"
                                  // style={{
                                  //   minHeight: "700px",
                                  //   maxHeight: "700px",
                                  //   overflowY: "scroll",
                                  // }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection:
                                        width <= 768 ? "column" : "row",
                                      padding: width <= 768 ? "8px" : "24px",
                                    }}
                                  >
                                    <div className="status_page_first">
                                      <div>
                                        <h5>Subject</h5>
                                        <h6>{Ticketinfo?.data?.subject}</h6>
                                      </div>
                                      <div>
                                        <h5>Description</h5>
                                        <p>{Ticketinfo?.data?.description}</p>
                                      </div>
                                      <div>
                                        <h5>Service</h5>
                                        <h6>{Ticketinfo?.data?.service}</h6>
                                      </div>
                                      <div>
                                        <h5>Severity</h5>
                                        <h6>{Ticketinfo?.data?.serverity}</h6>
                                      </div>
                                    </div>

                                    <div className="status_page">
                                      <div className="casetype">
                                        <h5>Case ID</h5>
                                        <span
                                          style={{
                                            display: "flex",
                                            gap: "10px",
                                            alignItems: "center",
                                          }}
                                        >
                                          {Ticketinfo.data.id}
                                          <CopyButton size={"lg"}></CopyButton>
                                        </span>
                                      </div>

                                      <div className="status_open flex flex-col gap-1">
                                        <h5>Status</h5>

                                        <div>
                                          <span
                                            className="!bg-[#D0E2FF] dark:!bg-[#D0E2FF] !text-[#0043CE]  px-3 py-1 block w-max"
                                            style={{
                                              borderRadius: "100px",
                                              fontFamily: "IBM Plex Sans",
                                              fontSize: "12px",
                                              fontStyle: "normal",
                                              fontWeight: "400",
                                              lineHeight: "16px",
                                              letterSpacing: "0.32px",
                                            }}
                                          >
                                            {getServiceRequestStatusText(
                                              Ticketinfo.data.status
                                            )}
                                          </span>
                                        </div>
                                      </div>

                                      <div className="status_open">
                                        <h5>Date</h5>
                                        <p>
                                          {moment(Ticketinfo.data.time).format(
                                            "MM/DD/YY"
                                          )}
                                        </p>
                                      </div>

                                      <div className="status_open">
                                        <h5>Case Type</h5>
                                        <p>{Ticketinfo?.data?.casetype}</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="opened_attachment">
                                    <div className="tag_container">
                                      <h6>Opened By</h6>

                                      <Tag
                                        className="some-class"
                                        type="outline"
                                        title="Clear Filter"
                                      >
                                        {Ticketinfo.data?.email}
                                      </Tag>

                                      {/* <TextInput
                                        invalidText="A valid email is required"
                                        placeholder="johndoe@gmail.com"
                                        value={Ticketinfo.data?.email}
                                        size="lg"
                                      /> */}
                                    </div>

                                    <Form onSubmit={handleSubmit}>
                                      <FieldArray
                                        name="tags"
                                        render={(arrayHelpers) => (
                                          <div
                                            className={`flex flex-col gap-3 w-full`}
                                          >
                                            <TextInput
                                              id="tags"
                                              value={inputField}
                                              size="lg"
                                              invalidText="A valid email is required"
                                              placeholder="johndoe@gmail.com"
                                              onChange={(event) =>
                                                // handleAddEmail(
                                                //   event,
                                                //   arrayHelpers.push
                                                // )

                                                setInputField(
                                                  event.target.value
                                                )
                                              }
                                              onKeyDown={(event) =>
                                                event.key == "Enter"
                                                  ? handleAddEmail(
                                                      arrayHelpers.push
                                                    )
                                                  : null
                                              }
                                              invalid={multiSelectError}
                                            />

                                            {/* <Dropdown
                                              size="lg"
                                              id="default"
                                              initialSelectedItem={items[1]}
                                              label="Option 1"
                                              items={items}
                                              itemToString={(item) =>
                                                item ? item.text : ""
                                              }
                                              multiple
                                            /> */}

                                            <div className="flex gap-x-1 justify-start flex-wrap tag_container">
                                              {values.tags.map(
                                                (email, index) => (
                                                  <Tag
                                                    title={email}
                                                    key={email}
                                                    filter={
                                                      email !==
                                                      useralldata?.userinfo
                                                        ?.email
                                                    }
                                                    onClose={() => {
                                                      arrayHelpers.remove(
                                                        index
                                                      );
                                                      setOperation("cc_delete");
                                                      setDelEmail((prev) => [
                                                        ...prev,
                                                        email,
                                                      ]);
                                                    }}
                                                  >
                                                    {email}
                                                  </Tag>
                                                )
                                              )}
                                            </div>
                                          </div>
                                        )}
                                      />

                                      {touched.tags && errors.tags && (
                                        <div className="error">
                                          {errors.tags}
                                        </div>
                                      )}
                                    </Form>

                                    <div className="attachment">
                                      <h5>Attachments</h5>
                                      <h6>
                                        Max file size is 500kb. Supported file
                                        types are .jpg and .png.
                                      </h6>
                                      <div className="fileuploder_item">
                                        {comment.map((chat, chatIndex) =>
                                          chat.attachurlname.map(
                                            (value, index) => {
                                              return (
                                                <div
                                                  key={index}
                                                  style={{
                                                    marginBottom: "1rem",
                                                  }}
                                                >
                                                  {value && (
                                                    <a
                                                      href={value}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                    ></a>
                                                  )}
                                                  <FileUploaderItem
                                                    size="lg"
                                                    name={
                                                      value
                                                        ? value.split("/").pop()
                                                        : "Unnamed File"
                                                    }
                                                    status="edit"
                                                    // onDelete={() =>
                                                    //   handleFileRemove(
                                                    //     chat.attachUrlId[index],
                                                    //     chatIndex
                                                    //   )
                                                    // }
                                                  />
                                                </div>
                                              );
                                            }
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="close_btn"
                                  style={{
                                    padding:
                                      width <= 768 ? "0 0px 0 0px" : "0 24px",
                                    marginTop: "32px",
                                  }}
                                >
                                  <CustomButton
                                    text={"Update Request"}
                                    style={{
                                      width: "100%",
                                    }}
                                    size="lg"
                                    type="submit"
                                    handleClick={() => {
                                      ReduxDispatch(
                                        UpdateInfoEmailcc({
                                          values,
                                          ticketId,
                                          operation,
                                          primaryEmail:
                                            useralldata?.userinfo?.email,
                                          del_Email: delEmail,
                                        })
                                      );
                                      setSubmitting(false);
                                      setOperation("cc_add");
                                    }}
                                    disabled={isTicketUpdating}
                                    isLoading={isTicketUpdating}
                                    fullWidth
                                  />
                                </div>
                              </>
                            )}
                          </Formik>
                        </div>
                      </div>
                    </div>
                  </Column>
                </Row>
              </div>
              {/* </FlexGrid> */}
            </section>
          </TicketchatWrapper>
        </FlexGrid>
      </TicketDetailsWrapper>
    </>
  );
};

export default TicketInfo;
