import {
  ChevronSortDown,
  ChevronSortUp,
  SendFilled,
} from "@carbon/icons-react";
import { IconButton, TextInput } from "@carbon/react";
import { Productdata, Servicedata } from "Component/Data/DataJson";
import { GlobalContext } from "ContextApi/GlobalContext";
import useWindowSize from "hooks/useWindowSize";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addMessage,
  getChat,
  getFaq,
  getServiceIssue,
  getServiceList,
  getFaqCombineData,
  setSelectedService,
  showServiceOptions,
  setSidebarcontent,
  setFullViewDispatcher,
} from "redux/slices/Chatbot/ChatbotSlice";
import {
  handleCreateTicketTab,
  handleSupportTab,
  handleSupportTabIndex,
} from "redux/slices/headerSlice";
import { CopilotChat, CopilotMobile } from "./style";

function ChatComponent({ fullView, setFullView, setToggleIcon, toggleIcon }) {
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const state = useSelector((state) => state.chatbot);
  const [inputValue, setInputValue] = useState("");
  const globalContext = useContext(GlobalContext);
  const sidebarContent = useSelector((state) => state.chatbot.sidebarContent);

  const { messages } = state;

  const isLoading = state.isLoading || state.services.status === "loading";

  const isDarkTheme = useMemo(
    () => globalContext.globalTheme === "g100",
    [globalContext.globalTheme]
  );

  const messagesContainerRef = useRef(null);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    // scroll to top of last message
    if (messagesContainerRef.current && messagesEndRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesEndRef.current.offsetTop -
        messagesContainerRef.current.offsetTop;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  async function handleClick(option) {
    if (!fullView || !toggleIcon) {
      setToggleIcon(true);
      setFullView(true);
    }

    switch (option.action) {
      case "get_service_list":
        dispatch(getServiceList());
        break;

      case "get_service_opts":
        dispatch(showServiceOptions(option));
        break;

      case "get_faq_list":
        dispatch(getServiceIssue(option.parent));
        break;

      case "chat_with_ai":
        dispatch(addMessage(option));
        dispatch(getChat(option));
        break;

      case "get_faq":
        dispatch(getFaq(option));
        break;

      case "open_ticket":
        dispatch(handleSupportTab(true));
        dispatch(handleSupportTabIndex(1));
        dispatch(handleCreateTicketTab(true));
        break;

      case "call":
        dispatch(handleSupportTab(true));
        dispatch(handleSupportTabIndex(2));
        break;

      case "chat":
        dispatch(handleSupportTab(true));
        dispatch(handleSupportTabIndex(0));
        break;

      default:
        break;
    }

    setInputValue("");
  }

  return !fullView || width >= 768 ? (
    <CopilotChat
      isDarkTheme={isDarkTheme}
      className={` ${fullView ? "full-view" : "small-view"}`}
    >
      {width <= 768 && (
        <div
          className="icon_2"
          onClick={() => {
            setToggleIcon(!toggleIcon);
            setFullView(!fullView);
          }}
        >
          <ChevronSortUp size={24} className="chevron-up" />
        </div>
      )}

      {width > 768 && (
        <div className="message-list" ref={messagesContainerRef}>
          {messages.map((message, idx) => (
            <div
              key={idx}
              className={`message ${message.from === "bot" ? "left" : "right"}`}
              ref={
                isLoading
                  ? undefined
                  : idx + 1 === messages.length
                  ? messagesEndRef
                  : undefined
              }
            >
              {message.from === "bot" && <CopilotAvatar />}

              <div className="content-wrapper">
                <RenderMessageText
                  message={message}
                  className="dark:!bg-[#fff]"
                />

                {message?.options?.map((option) => {
                  return (
                    <div
                      className="content dark:!text-white dark:!bg-[#262626] dark:!border-[#474747] cursor-pointer"
                      role="button"
                      onClick={() => {
                        handleClick(option);
                      }}
                    >
                      {option.text}
                    </div>
                  );
                })}
              </div>
            </div>
          ))}

          {isLoading && (
            <div ref={messagesEndRef} className={`message left`}>
              <CopilotAvatar />

              <div className="content-wrapper">
                <div className="content">
                  <div className="loader"></div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      <div className="form-container">
        <TextInput
          type="text"
          value={inputValue}
          onChange={(evt) => {
            setInputValue(evt.target.value);
            setInputValue(evt.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key.toLowerCase() === "enter") {
              handleClick({
                action: "chat_with_ai",
                text: inputValue,
              });
              dispatch(setSelectedService({ heading: inputValue }));
            }
          }}
          placeholder="Ask me anything"
          disabled={isLoading}
        />

        <IconButton
          label="Add"
          size="md"
          disabled={isLoading}
          onClick={() => {
            handleClick({
              action: "chat_with_ai",
              text: inputValue,
            });
          }}
        >
          <SendFilled />
        </IconButton>
      </div>
    </CopilotChat>
  ) : (
    <CopilotMobile>
      <div style={{ height: "457px" }}>
        <div
          className="icon"
          onClick={() => {
            setToggleIcon(!toggleIcon);
            setFullView(!fullView);
          }}
        >
          <ChevronSortDown size={24} className="chevron-down" />
        </div>

        <div className="message-list" ref={messagesContainerRef}>
          {messages.map((message, idx) => (
            <div
              key={idx}
              className={`message ${message.from === "bot" ? "left" : "right"}`}
              ref={
                isLoading
                  ? undefined
                  : idx + 1 === messages.length
                  ? messagesEndRef
                  : undefined
              }
            >
              {message.from === "bot" && <CopilotAvatar />}

              <div className="content-wrapper">
                <RenderMessageText
                  message={message}
                  className="dark:!bg-[#fff]"
                />

                {message.options?.map((option) => {
                  return (
                    <div
                      className="content dark:!text-white dark:!bg-[#262626] dark:!border-[#474747] cursor-pointer"
                      role="button"
                      onClick={() => {
                        handleClick(option);
                      }}
                    >
                      {option.text}
                    </div>
                  );
                })}
              </div>
            </div>
          ))}

          {isLoading && (
            <div ref={messagesEndRef} className={`message left`}>
              <CopilotAvatar />
              <div className="content-wrapper">
                <div className="content">
                  <div className="loader"></div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="form-container-mobile">
          <TextInput
            type="text"
            value={inputValue}
            onChange={(evt) => {
              setInputValue(evt.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key.toLowerCase() === "enter") {
                handleClick({
                  action: "chat_with_ai",
                  text: inputValue,
                });
                dispatch(setSelectedService({ heading: inputValue }));
              }
            }}
            placeholder="Ask me anything"
            disabled={isLoading}
          />

          <IconButton
            label="Add"
            size="md"
            disabled={isLoading}
            onClick={() => {
              handleClick({
                action: "chat_with_ai",
                text: inputValue,
              });
            }}
          >
            <SendFilled />
          </IconButton>
        </div>
      </div>
    </CopilotMobile>
  );
}

function RenderMessageText({ message }) {
  const contentRef = useRef(null);
  const dispatch = useDispatch();
  const sidebarContent = useSelector((state) => state.chatbot.sidebarContent);
  const { productData, serviceData, TotalProductAndServiceData } = useSelector(
    (state) => state.Document
  );

  useEffect(() => {
    if (!message.rawHTML || !contentRef.current) return;

    /**
     * @type {NodeListOf<HTMLAnchorElement>}
     */
    const anchorTags = contentRef.current.querySelectorAll("a");

    console.log("anchoorTags", anchorTags);

    /**
     * @param {MouseEvent & { target:HTMLInputElement }} event
     */
    function clickEventListener(event) {
      if (event.target.getAttribute("href") !== "#") return;
      dispatch(setFullViewDispatcher(false));

      event.preventDefault();
      event.stopImmediatePropagation();
      event.stopPropagation();

      let Action = event.target.dataset.action;
      let ServiceName = event.target.dataset.serviceName;
      let Prompt = event.target.dataset.prompt;
      let InnerText = event.target.innerText;

      console.log("ACTION", event.target);
      console.log("Serveiceanem", ServiceName);

      if (Action === "chat") {
        dispatch(
          getChat({
            text: Prompt.trim() ?? InnerText.trim(),
          })
        );
        dispatch(setSelectedService({ heading: Prompt }));
        return;
      }

      if (Action === "other") {
        dispatch(
          getChat({
            text: Prompt.trim() ?? InnerText.trim(),
            original_text:
              InnerText.trim().replace("?", "") + " " + sidebarContent?.service,
          })
        );
        dispatch(setSelectedService({ heading: Prompt }));
        return;
      }

      if (ServiceName) dispatch(setSelectedService({ heading: ServiceName }));

      const prompt = InnerText.replace("FAQ ?", "").trim();
      // const prompt = InnerText.trim();
      let arrayCheckPrompt = InnerText.replace("FAQ ?", "").toLowerCase();

      // # temporary soln --start
      let value_product =
        TotalProductAndServiceData?.data?.data?.products?.find(
          (item) =>
            item.article_name.toLowerCase().trim() == arrayCheckPrompt.trim()
        );

      let value_service =
        TotalProductAndServiceData?.data?.data?.services?.find(
          (item) =>
            item.article_name.toLowerCase().trim() == arrayCheckPrompt.trim()
        );
      // # temporary soln -- end

      Action == "chat" && dispatch(setSidebarcontent({ sidebarContent: null }));

      value_product
        ? dispatch(getFaqCombineData({ text: prompt, ...value_product }))
        : dispatch(getFaqCombineData({ text: prompt, ...value_service }));
    }

    anchorTags.forEach((anchorTag) => {
      anchorTag.addEventListener("click", clickEventListener);
    });

    return () => {
      anchorTags.forEach((tag) =>
        tag.removeEventListener("click", clickEventListener)
      );
    };
  }, [message]);

  if (!message.text) return null;

  if (message.rawHTML) {
    return (
      <div
        ref={contentRef}
        className={`content ${message.style ?? ""}`}
        dangerouslySetInnerHTML={{ __html: message.text }}
      />
    );
  }

  return <div className={`content ${message.style ?? ""}`}>{message.text}</div>;
}

function CopilotAvatar() {
  return (
    <div className="avatar">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
      >
        <g clip-path="url(#clip0_3243_38440)">
          <path
            d="M9.59405 3.52744L10.3314 5.55405C10.4359 5.84143 10.5999 6.1024 10.8122 6.31917C11.0245 6.53595 11.2801 6.70342 11.5616 6.81016L13.5464 7.563C13.5819 7.57659 13.6124 7.6009 13.634 7.63269C13.6556 7.66449 13.6671 7.70224 13.6671 7.74092C13.6671 7.77959 13.6556 7.81734 13.634 7.84914C13.6124 7.88093 13.5819 7.90524 13.5464 7.91883L11.5616 8.67201C11.2801 8.77868 11.0245 8.94608 10.8122 9.1628C10.5999 9.37952 10.4359 9.64045 10.3314 9.92778L9.59405 11.9544C9.58089 11.9908 9.55713 12.0221 9.52598 12.0443C9.49482 12.0664 9.45777 12.0783 9.41981 12.0783C9.38184 12.0783 9.34479 12.0664 9.31364 12.0443C9.28249 12.0221 9.25873 11.9908 9.24556 11.9544L8.50791 9.92778C8.40342 9.64047 8.23946 9.37955 8.02721 9.16284C7.81497 8.94612 7.55943 8.7787 7.27804 8.67201L5.29323 7.91883C5.25762 7.90539 5.2269 7.88113 5.2052 7.84932C5.18351 7.81751 5.17188 7.77968 5.17188 7.74092C5.17188 7.70215 5.18351 7.66432 5.2052 7.63251C5.2269 7.6007 5.25762 7.57644 5.29323 7.563L7.27804 6.81016C7.55945 6.70342 7.815 6.53594 8.02725 6.31916C8.23949 6.10239 8.40344 5.84141 8.50791 5.55405L9.24556 3.52744C9.25887 3.49123 9.28268 3.46003 9.31382 3.438C9.34495 3.41598 9.38193 3.40417 9.41981 3.40417C9.45768 3.40417 9.49466 3.41598 9.5258 3.438C9.55693 3.46003 9.58074 3.49123 9.59405 3.52744Z"
            fill="white"
          />
          <path
            d="M3.75294 0.754932L4.2794 2.20108C4.35406 2.40609 4.4711 2.59228 4.62254 2.74698C4.77399 2.90168 4.9563 3.02125 5.15705 3.09756L6.57236 3.63476C6.59771 3.64438 6.61957 3.66169 6.63501 3.68437C6.65045 3.70704 6.65872 3.734 6.65872 3.76162C6.65872 3.78923 6.65045 3.81619 6.63501 3.83887C6.61957 3.86154 6.59771 3.87885 6.57236 3.88847L5.1557 4.42602C4.95497 4.5023 4.77269 4.62181 4.62124 4.77645C4.4698 4.93108 4.35274 5.11721 4.27804 5.32216L3.75158 6.7683C3.74216 6.79419 3.72521 6.81651 3.703 6.83227C3.68079 6.84804 3.65439 6.85649 3.62734 6.85649C3.60029 6.85649 3.57389 6.84804 3.55169 6.83227C3.52948 6.81651 3.51252 6.79419 3.5031 6.7683L2.97901 5.32216C2.90428 5.11717 2.78717 4.93103 2.63566 4.77639C2.48416 4.62175 2.30181 4.50225 2.10102 4.42602L0.684022 3.88847C0.658669 3.87885 0.636807 3.86154 0.621369 3.83887C0.605931 3.81619 0.597656 3.78923 0.597656 3.76162C0.597656 3.734 0.605931 3.70704 0.621369 3.68437C0.636807 3.66169 0.658669 3.64438 0.684022 3.63476L2.10034 3.09756C2.30129 3.02137 2.48379 2.90186 2.63542 2.74715C2.78705 2.59245 2.90424 2.4062 2.97901 2.20108L3.50446 0.754932C3.51388 0.729046 3.53083 0.706723 3.55304 0.69096C3.57525 0.675197 3.60165 0.666748 3.6287 0.666748C3.65574 0.666748 3.68214 0.675197 3.70435 0.69096C3.72656 0.706723 3.74352 0.729046 3.75294 0.754932Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_3243_38440">
            <rect
              width="13.3333"
              height="12.8"
              fill="white"
              transform="translate(0.333984 0.666687)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}

export default ChatComponent;
