import { SendFilled } from "@carbon/icons-react";
import { GlobalContext } from "ContextApi/GlobalContext";
import CustomButton from "components/button/customButton";
import useWindowSize from "hooks/useWindowSize";
import { useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setFullViewDispatcher,
  setIsChatWindowVisibleDispatcher,
  setMessage,
  setToggleIconDispatcher,
} from "redux/slices/Chatbot/ChatbotSlice";
import ChatComponent from "./ChatComponent";
import { CopilotContent, CopilotWrapper } from "./style";

function Copilot() {
  const { isChatWindowVisible, fullView, toggleIcon } = useSelector(
    (state) => state.chatbot
  );
  const state = useSelector((state) => state.chatbot);
  const isLoading = state.isLoading || state.services.status === "loading";
  const dispatch = useDispatch();

  function setIsChatWindowVisible(payload) {
    dispatch(setIsChatWindowVisibleDispatcher(payload));

    if (payload) {
      dispatch(
        setMessage({
          text: "Hello! I'm your virtual Help Center assistant. I'm here to help you find the answers and resources you need. How can I help?",
          from: "bot",
        })
      );
    }
  }

  function setFullView(payload) {
    dispatch(setFullViewDispatcher(payload));
  }

  function setToggleIcon(payload) {
    dispatch(setToggleIconDispatcher(payload));
  }

  const { width } = useWindowSize();

  const globalContext = useContext(GlobalContext);

  const isDarkTheme = useMemo(
    () => globalContext.globalTheme === "g100",
    [globalContext.globalTheme]
  );

  if (!isChatWindowVisible) {
    return (
      <CopilotWrapper
        isLoading={isLoading}
        isChatWindowVisible={isChatWindowVisible}
        className="dark:!border-[#474747] chatbottheem"
        isDarkTheme={isDarkTheme}
        style={{
          maxHeight: fullView && width <= 768 ? "104px" : "700px",
          minHeight: width > 768 && "700px",
          display: width > 768 && "flex",
          justifyContent: "center",
        }}
      >
        <CopilotContent className="dark:!border-[#474747] chatbottheem">
          <div className="main_div">
            {/* <div className="ellipse">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <g clip-path="url(#clip0_3243_36589)">
                  <path
                    d="M13.8891 4.69115L14.9951 7.73106C15.1519 8.16213 15.3979 8.5536 15.7164 8.87876C16.0348 9.20391 16.4182 9.45512 16.8404 9.61524L19.8176 10.7445C19.8708 10.7649 19.9167 10.8014 19.949 10.849C19.9814 10.8967 19.9987 10.9534 19.9987 11.0114C19.9987 11.0694 19.9814 11.126 19.949 11.1737C19.9167 11.2214 19.8708 11.2579 19.8176 11.2782L16.8404 12.408C16.4183 12.568 16.0349 12.8191 15.7164 13.1442C15.3979 13.4693 15.1519 13.8607 14.9951 14.2917L13.8891 17.3316C13.8694 17.3861 13.8337 17.4332 13.787 17.4664C13.7403 17.4996 13.6847 17.5174 13.6278 17.5174C13.5708 17.5174 13.5152 17.4996 13.4685 17.4664C13.4218 17.4332 13.3861 17.3861 13.3664 17.3316L12.2599 14.2917C12.1032 13.8607 11.8572 13.4693 11.5389 13.1442C11.2205 12.8192 10.8372 12.568 10.4151 12.408L7.43789 11.2782C7.38448 11.2581 7.3384 11.2217 7.30585 11.174C7.27331 11.1263 7.25586 11.0695 7.25586 11.0114C7.25586 10.9532 7.27331 10.8965 7.30585 10.8488C7.3384 10.801 7.38448 10.7647 7.43789 10.7445L10.4151 9.61524C10.8372 9.45512 11.2205 9.20391 11.5389 8.87874C11.8573 8.55358 12.1032 8.16211 12.2599 7.73106L13.3664 4.69115C13.3864 4.63684 13.4221 4.59003 13.4688 4.557C13.5155 4.52396 13.5709 4.50626 13.6278 4.50626C13.6846 4.50626 13.74 4.52396 13.7867 4.557C13.8334 4.59003 13.8692 4.63684 13.8891 4.69115Z"
                    fill="white"
                  />
                  <path
                    opacity="0.8"
                    d="M5.12746 0.532331L5.91714 2.70155C6.02914 3.00907 6.20469 3.28836 6.43186 3.5204C6.65903 3.75245 6.93249 3.9318 7.23363 4.04627L9.35658 4.85207C9.39461 4.86651 9.42741 4.89247 9.45056 4.92649C9.47372 4.9605 9.48613 5.00093 9.48613 5.04236C9.48613 5.08378 9.47372 5.12422 9.45056 5.15823C9.42741 5.19225 9.39461 5.21821 9.35658 5.23265L7.2316 6.03896C6.93051 6.15338 6.65708 6.33265 6.42991 6.56461C6.20275 6.79656 6.02716 7.07574 5.91511 7.38317L5.12542 9.55239C5.11129 9.59122 5.08586 9.6247 5.05254 9.64834C5.01923 9.67199 4.97963 9.68466 4.93906 9.68466C4.89849 9.68466 4.85889 9.67199 4.82558 9.64834C4.79226 9.6247 4.76683 9.59122 4.7527 9.55239L3.96657 7.38317C3.85446 7.07569 3.6788 6.79647 3.45154 6.56452C3.22428 6.33256 2.95076 6.15331 2.64958 6.03896L0.524079 5.23265C0.486051 5.21821 0.453257 5.19225 0.4301 5.15823C0.406943 5.12422 0.394531 5.08378 0.394531 5.04236C0.394531 5.00093 0.406943 4.9605 0.4301 4.92649C0.453257 4.89247 0.486051 4.86651 0.524079 4.85207L2.64856 4.04627C2.94998 3.93199 3.22374 3.75272 3.45118 3.52066C3.67862 3.28861 3.85441 3.00923 3.96657 2.70155L4.75473 0.532331C4.76887 0.493502 4.7943 0.460017 4.82761 0.436373C4.86092 0.412728 4.90052 0.400055 4.94109 0.400055C4.98166 0.400055 5.02126 0.412728 5.05458 0.436373C5.08789 0.460017 5.11332 0.493502 5.12746 0.532331Z"
                    fill="white"
                  />
                  <path
                    opacity="0.6"
                    d="M3.51769 12.7979L4.10449 14.4074C4.18753 14.6367 4.31814 14.8449 4.48736 15.0177C4.65657 15.1906 4.8604 15.3241 5.08487 15.409L6.66119 16.0081C6.6894 16.0189 6.71372 16.0383 6.73088 16.0635C6.74805 16.0888 6.75724 16.1189 6.75724 16.1496C6.75724 16.1804 6.74805 16.2104 6.73088 16.2357C6.71372 16.261 6.6894 16.2803 6.66119 16.2911L5.08487 16.8903C4.86107 16.9752 4.65783 17.1084 4.489 17.2808C4.32017 17.4532 4.18972 17.6607 4.10653 17.8892L3.51769 19.5018C3.50712 19.5306 3.48819 19.5555 3.46343 19.573C3.43867 19.5905 3.40926 19.5999 3.37913 19.5999C3.349 19.5999 3.31959 19.5905 3.29483 19.573C3.27007 19.5555 3.25114 19.5306 3.24057 19.5018L2.65377 17.8892C2.57076 17.661 2.44067 17.4537 2.27231 17.2813C2.10394 17.109 1.90125 16.9756 1.67797 16.8903L0.0960533 16.2911C0.0678401 16.2803 0.043526 16.261 0.0263615 16.2357C0.009197 16.2104 0 16.1804 0 16.1496C0 16.1189 0.009197 16.0888 0.0263615 16.0635C0.043526 16.0383 0.0678401 16.0189 0.0960533 16.0081L1.67797 15.409C1.90177 15.324 2.10501 15.1908 2.27384 15.0185C2.44267 14.8461 2.57312 14.6385 2.65631 14.41L3.24311 12.8005C3.25387 12.7724 3.27261 12.7483 3.29692 12.7312C3.32124 12.714 3.35001 12.7048 3.37954 12.7045C3.40907 12.7042 3.43801 12.7129 3.46263 12.7296C3.48725 12.7463 3.50643 12.77 3.51769 12.7979Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3243_36589">
                    <rect
                      width="20"
                      height="19.2"
                      fill="white"
                      transform="translate(0 0.399994)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div> */}

            {/* <h2>AI Copilot</h2> */}

            <img
              src={width <= 768 ? "/Vertical.png" : "/Vertical.png"}
              width={width <= 768 ? "88px" : "254px"}
              height={width <= 768 ? "50px" : "84px"}
            />

            {width > 768 && (
              <p className="!text-[white]  ">
                AI Copilot is your essential companion <br />
                for finding assistance and resources <br />
                related to products and services.
              </p>
            )}

            <div className="button_wrapper">
              <CustomButton
                style={{
                  minWidth: width <= 768 ? "226px" : "263px",
                  width: "100%",
                }}
                text="Chat Now"
                icon={SendFilled}
                handleClick={() => {
                  setIsChatWindowVisible(true);
                  width <= 768 && setFullView(true);
                }}
              />
            </div>
          </div>
        </CopilotContent>
      </CopilotWrapper>
    );
  }

  return (
    <CopilotWrapper
      isLoading={isLoading}
      style={{
        // borderImage: "linear-gradient(to right, #2570FE, #F584FFA3) 30",
        // borderWidth: "1px",
        // borderStyle: "solid",
        // boxShadow: "1px 1px 20px 0px rgba(33, 36, 44, 0.20)",
        // borderBottomWidth: "3px",
        // border: "2px solid transparent",
        // boxShadow: "0px 2px 2px 0px rgba(33, 36, 44, 0.20)",
        border:
          !isLoading && !isDarkTheme
            ? "2px solid var(--cta-cta-background-01, #E9F1FF)"
            : !isLoading && isDarkTheme
            ? "2px solid var(--cta-cta-background-01, #474747)"
            : "none",
        maxHeight:
          fullView && width <= 768 ? "457px" : width > 768 ? "700px" : "104px",
      }}
    >
      <ChatComponent
        fullView={fullView}
        setFullView={setFullView}
        setToggleIcon={setToggleIcon}
        toggleIcon={toggleIcon}
      />
    </CopilotWrapper>
  );
}

export default Copilot;
