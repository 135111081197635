import { useAccount, useMsal } from "@azure/msal-react";
import { createContext, useEffect, useState } from "react";
import { io } from "socket.io-client";

export const GlobalContext = createContext();

export const GlobalProvider = (props) => {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [socketdata, setsocketdata] = useState(null);
  const defaultTheme = localStorage.getItem("theme") || "white";
  const [globalTheme, setGlobalTheme] = useState(defaultTheme);

  const [toggle, settoggle] = useState({
    Quick: false,
    Support: false,
    Notification: false,
    Account: false,
    openShow: "",
    closedShow: "",
    pendingShow: "",
    newShow: "",
    tabselect: "",
    FillterToggle: false,
    ListActive: false,
    viewSummary: false,
  });

  const [ticket_id, setticket_id] = useState(null);

  useEffect(() => {
    if (account) {
      localStorage.setItem("supportusermail", account.idTokenClaims?.email);
      localStorage.setItem(
        "supportusername",
        account.idTokenClaims?.given_name
      );
      localStorage.setItem("newuser_d", account.idTokenClaims?.newUser);
    }
  }, [account]);

  useEffect(() => {
    const socket = io.connect(process.env.REACT_APP_SOCKETURL, {
      path: "/notify/socket.io",
    });
    socket.on("connected", () => {});
    socket.emit("join", { userid: account?.idTokenClaims?.sub });
    setsocketdata(socket);
    sessionStorage.setItem("ws-initialized", "true");

    // Clean up the socket connection when the component unmounts
    return () => socket.disconnect();
  }, [account]);

  // useEffect(() => {
  //   try {
  //     if (window.clearbit && account?.idTokenClaims) {
  //       window.clearbit.identify(account?.idTokenClaims?.email, {
  //         email: account?.idTokenClaims?.email,
  //         company_domain: "uvation.com",
  //       });
  //     }
  //     if (window?.analytics && account?.idTokenClaims) {
  //       window?.analytics.identify(account?.idTokenClaims?.sub, {
  //         name: `${account?.idTokenClaims?.given_name}${" "}${
  //           account?.idTokenClaims?.family_name
  //         }`,
  //         email: account?.idTokenClaims?.email,
  //       });
  //       window?.analytics.page("Retail Page", window.location);
  //     }
  //   } catch (error) {}
  // }, [account]);

  return (
    <GlobalContext.Provider
      value={{
        globalTheme,
        setGlobalTheme,
        toggle,
        settoggle,
        ticket_id,
        setticket_id,
        socketdata,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};
