import {
  Add,
  ArrowRight,
  Catalog,
  Chat,
  DocumentTasks,
  Education,
  Phone,
  RequestQuote,
  ArrowLeft,
  SearchLocate,
  SoftwareResourceCluster,
} from "@carbon/icons-react";
import { Accordion, AccordionItem, Column, FlexGrid, Row } from "@carbon/react";
import Copilot from "components/Copilot";
import ServiceRequestStatus from "components/ServiceRequestStatus";
import CustomButton from "components/button/customButton";
// import { Requesheader } from "pages/request";
import {
  OtherresourceData,
  Productdata,
  Servicedata,
} from "Component/Data/DataJson";
import { GlobalContext } from "ContextApi/GlobalContext";
import Serviceindex from "pages/status/ServiceStatus";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  GetArticals,
  GetSingleArticals,
} from "redux/slices/RecommendArticals/RecommendSlice";
import {
  handleSupportTab,
  handleSupportTabIndex,
  headerState,
} from "redux/slices/headerSlice";
import { dayjs, getServiceRequestStatusText } from "utils";
import useWindowSize from "../../hooks/useWindowSize";
import HomeWrapper from "./style";
import CopilotSidebarContent from "components/Copilot/Content/CopilotSidebarContent";

const Home = () => {
  const { width } = useWindowSize();
  const { getRecommendedData, getSingleRecommendedData } = useSelector(
    (state) => state.Articals
  );
  const [Singlefaqs, setSinglefaqs] = useState([]);
  const dispatch = useDispatch();
  const context = useContext(GlobalContext);
  const { TicketFilter } = useSelector(headerState);

  const {
    tickets: { data: tickets },
    tickets: { count },
  } = useSelector((state) => state.support);

  const navigate = useNavigate();
  const handelsinglepageartical = (item) => {
    dispatch(GetSingleArticals(item?.article_slug));
  };

  const [recommendedArticles, setRecommendedArticles] = useState({
    isOpen: false,
    data: null,
  });

  const RequesheaderWithoutSeverity = [
    {
      header: "Request #",
      key: "id",
    },
    {
      header: "Date",
      key: "created_at",
    },
    {
      header: "Case Type",
      key: "casetype",
    },
    {
      header: "Subject",
      key: "subject",
    },

    {
      header: "Status",
      key: "status",
    },
  ];

  const recentTickets = useMemo(() => tickets.slice(0, 4), [tickets]);

  return (
    <>
      <HomeWrapper className="dark:!bg-[#161616]">
        {/* <MobileHeaderNav /> */}
        <FlexGrid fullWidth>
          <div className="header dark:!text[#fff]">
            <h4>support dashboard</h4>
            <h1>Support Center</h1>
          </div>
          {/* </FlexGrid> */}

          {/* <FlexGrid
          style={{
            backgroundImage: 'url("/graphic_big.png")',
            backgroundPosition: "top right",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
          fullWidth
          className="dark:!bg-[#161616]"
        > */}
          <div className="top ">
            <Row>
              <Column xlg={12}>
                <div className="ticket_request dark:!bg-[#262626]">
                  <div className="tableHeader dark:!bg-[#262626] ">
                    <div className="heading">
                      <div className="icon_container">
                        <RequestQuote size={24} />
                      </div>
                      <h4>Most Recent Service Requests</h4>
                    </div>

                    <div className="view_all_request">
                      <Link
                        to="/request"
                        style={{
                          textDecoration: "none",
                          fontFamily: "IBM Plex Sans",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "22px",
                        }}
                      >
                        View All
                        <ArrowRight />
                      </Link>
                    </div>
                  </div>

                  <div className="support-tab-content">
                    <div role="listbox" className="tickets-list-container">
                      {recentTickets.length > 0 ? (
                        recentTickets.map((ticket) => (
                          <div className="tickets-list-item">
                            <div>
                              <Link
                                to={`/ticketdetails/${ticket.id}`}
                                className="title"
                              >
                                {ticket.servity}: {ticket.subject}
                              </Link>

                              <span className="desc">
                                {ticket.id} |{" "}
                                {dayjs
                                  .duration(
                                    dayjs(ticket.created_at).diff(
                                      dayjs(),
                                      "days"
                                    ),
                                    "days"
                                  )
                                  .humanize(true)}
                              </span>
                            </div>

                            <div>
                              <span
                                className="!bg-[#D0E2FF] dark:!bg-[#D0E2FF] !text-[#0043CE] px-3 py-1 block"
                                style={{
                                  borderRadius: "1000px",
                                  fontFamily: "IBM Plex Sans",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  lineHeight: "16px",
                                  letterSpacing: "0.32px",
                                }}
                              >
                                {getServiceRequestStatusText(ticket.status)}
                              </span>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="no_data">
                          {/* <div className="request_quote_div">
                            <RequestQuote size={24} />
                          </div> */}
                          <h6>No Requests</h6>
                          <h2>Create a new request to view the your status.</h2>
                        </div>
                      )}
                    </div>

                    {/* <DataTable
                      rows={tickets.slice(0, 4)}
                      headers={RequesheaderWithoutSeverity}
                      experimentalAutoAlign
                    >
                      {({
                        rows,
                        headers,
                        getHeaderProps,
                        getRowProps,
                        getTableProps,
                        onInputChange,
                      }) => (
                        <TableContainer>
                          {tickets.length ? (
                            <Table
                              experimentalAutoAlign
                              {...getTableProps()}
                              isSortable
                            >
                              <TableHead className="dark:!bg-[#474747] ">
                                <TableRow>
                                  {headers.map((header) => (
                                    <TableHeader
                                      className="dark:!bg-[#474747] dark:!text-white"
                                      key={header.key}
                                      {...getHeaderProps({ header })}
                                    >
                                      {header.header}
                                    </TableHeader>
                                  ))}
                                  <TableHeader />
                                </TableRow>
                              </TableHead>

                              <TableBody experimentalAutoAlign>
                                {rows.map((row, row_inx) => (
                                  <TableRow
                                    key={row.id}
                                    {...getRowProps({ row })}
                                  >
                                    {row.cells.map((cell, inx) => {
                                      return (
                                        <React.Fragment key={cell.id}>
                                          <TableCell
                                            style={{
                                              minWidth:
                                                width <= 1055
                                                  ? "130px"
                                                  : "unset",
                                            }}
                                            experimentalAutoAlign
                                            className={`dark:!text-[#fff] !bg-[#fff] dark:!bg-[#262626]${
                                              inx === "statusColumnIndex"
                                                ? "status-cell"
                                                : ""
                                            }`}
                                          >
                                            {cell.id.includes("status") &&
                                            tickets[row_inx]?.status ? (
                                              <span
                                                className="!bg-[#D0E2FF] dark:!bg-[#D0E2FF] !text-[#0043CE]  px-3 py-1"
                                                style={{
                                                  borderRadius: "1000px",
                                                }}
                                              >
                                                {getServiceRequestStatusText(
                                                  tickets[row_inx].status
                                                )}
                                              </span>
                                            ) : (
                                              cell.value
                                            )}
                                          </TableCell>
                                        </React.Fragment>
                                      );
                                    })}
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          ) : (
                            <div className="no_data">
                              <div className="request_quote_div">
                                <RequestQuote size={24} />
                              </div>
                              <h6>No Requests</h6>
                              <span>
                                Create a new request to view the <br /> your
                                status.
                              </span>
                            </div>
                          )}
                        </TableContainer>
                      )}
                    </DataTable> */}
                  </div>

                  {/* {width <= 768 && (
                    <div className="view_all_request">
                      <Link
                        to="/request"
                        style={{
                          textDecoration: "none",
                          fontFamily: "IBM Plex Sans",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "22px",
                        }}
                      >
                        View All
                        <ArrowRight />
                      </Link>
                    </div>
                  )} */}
                </div>
              </Column>
              <Column lg={16} xlg={4}>
                <div className="start">
                  <div className="chat">
                    <div className="heading">
                      <div className="icon_box">
                        <Phone size={24} />
                      </div>
                      <h4 className="!text-white">Talk with an Agent</h4>
                    </div>
                    <p className="!text-white">
                      Need assistance? Our dedicated support team is just a
                      phone call away.
                    </p>
                    <div className="button_row">
                      <CustomButton
                        className="contact-buttons"
                        center
                        fullWidth
                        style={{
                          justifyContent: "space-between",
                        }}
                        icon={Chat}
                        text={"Chat"}
                        handleClick={() => {
                          dispatch(handleSupportTab(true));
                          dispatch(handleSupportTabIndex(0));
                        }}
                      />

                      <CustomButton
                        className="contact-buttons"
                        center
                        fullWidth
                        style={{
                          justifyContent: "space-between",
                        }}
                        icon={Phone}
                        // iconFirst
                        // iconSize={20}
                        text={"Call"}
                        handleClick={() => {
                          dispatch(handleSupportTab(true));
                          dispatch(handleSupportTabIndex(2));
                        }}
                      />
                    </div>
                  </div>

                  <div className="request">
                    <div className="heading">
                      <div className="icon_box">
                        <RequestQuote size={24} />
                      </div>

                      <h4 className="create_service_request text-themeGrey !text-white">
                        Create A Service Request
                      </h4>
                    </div>
                    <CustomButton
                      fullWidth
                      icon={Add}
                      text={"Create A Service Request"}
                      handleClick={() => {
                        navigate("/TicketCreate");
                      }}
                    />
                  </div>
                </div>
              </Column>

              {/* <div
                style={{
                  height: "300px",
                  width: "800px",
                  border: "1px solid red",
                  background:
                    "linear-gradient(109deg, #363636 19.37%, rgba(50, 69, 105, 0.75) 37.61%, rgba(37, 112, 254, 0.00) 100.42%)",
                }}
              ></div> */}

              {/* <Column xlg={4}>
                <div className="training_and_guides dark:!bg-[#262626]">
                  <div>
                    <div className="heading dark:!bg-[#161616]">
                      <DocumentTasks size={24} />
                      <h4>Training & Guides </h4>
                    </div>

                    <div className="box dark:!bg-[#262626] dark:!border-[#474747]">
                      <div className="content dark:!text-white">
                        <div className="icon dark:!bg-[#161616]">
                          <Education size={20} />
                        </div>
                        <div>
                          <h5>Training For Admins</h5>
                          <p className="training_para">
                            {width > 768
                              ? "Empower admins through specialized training."
                                  .length > 50
                                ? "Empower admins through specialized training.".slice(
                                    0,
                                    50
                                  ) + "..."
                                : "Empower admins through specialized training."
                              : "Empower admins through specialized training."
                                  .length > 33
                              ? "Empower admins through specialized training.".slice(
                                  0,
                                  34
                                ) + "..."
                              : "Empower admins through specialized training."}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="box dark:!bg-[#262626] dark:!border-[#474747]">
                      <div className="content dark:!text-white">
                        <div className="icon dark:!bg-[#161616] ">
                          <Catalog size={20} />
                        </div>
                        <div>
                          <h5>Knowledge base</h5>
                          <p>
                            {width > 768
                              ? "Browse answers quickly to access information."
                                  .length > 50
                                ? "Browse answers quickly to access information.".slice(
                                    0,
                                    50
                                  ) + "..."
                                : "Browse answers quickly to access information."
                              : "Browse answers quickly to access information."
                                  .length > 35
                              ? "Browse answers quickly to access information.".slice(
                                  0,
                                  36
                                ) + "..."
                              : "Browse answers quickly to access information."}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="box dark:!bg-[#262626] dark:!border-[#474747]">
                      <div className="content dark:!text-white">
                        <div className="icon dark:!bg-[#161616]">
                          <SearchLocate size={20} />
                        </div>
                        <div>
                          <h5>FAQ & Guides</h5>
                          <p>
                            {width > 768
                              ? "Find answers and guidance in our FAQs."
                                  .length > 50
                                ? "Find answers and guidance in our FAQs.".slice(
                                    0,
                                    50
                                  ) + "..."
                                : "Find answers and guidance in our FAQs."
                              : "Find answers and guidance in our FAQs."
                                  .length > 35
                              ? "Find answers and guidance in our FAQs.".slice(
                                  0,
                                  35
                                ) + "..."
                              : "Find answers and guidance in our FAQs."}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                      padding: "24px 10px",
                    }}
                  >
                    <Link
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        color:
                          context.globalTheme == "white"
                            ? "#2570FE"
                            : "#77A6FF",
                      }}
                      to="/training"
                      className="pseudo-element"
                    >
                      View All
                      <ArrowRight size={16} />
                    </Link>
                  </div>
                </div>
              </Column> */}
            </Row>
          </div>

          {/* <div className="middle ">
            <Row>
              <Column lg={4}>
                <Copilot />
              </Column>

              <Column lg={12}>
                {!recommendedArticles?.isOpen ? (
                  <article className="recommended_articles dark:!border-[#474747]">
                    <div className="heading">
                      <div className="icon_box dark:!bg-[#161616]">
                        <Document size={24} />
                      </div>
                      <h4>Recommended Articles </h4>
                    </div>
                    <div className="content-body">
                      {getRecommendedData?.data?.data?.data.map(
                        (item, index) => {
                          return (
                            <div
                              className="article-row dark:!border-[#474747]"
                              key={index}
                            >
                              <div>
                                <h5>{item?.article_name}</h5>
                                <p>
                                  {width > 768
                                    ? item?.article_sdesc
                                    : item?.article_sdesc.length > 47
                                    ? item?.article_sdesc.slice(0, 47) + "..."
                                    : item?.article_sdesc}
                                </p>
                              </div>
                              <Link
                                onClick={() => {
                                  handelsinglepageartical(item);
                                  setRecommendedArticles((state) => ({
                                    ...state,
                                    isOpen: true,
                                  }));
                                }}
                                to="/"
                                className="dark:!text-[#77A6FF]"
                              >
                                <ArrowRight size={24} />
                              </Link>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </article>
                ) : (
                  <article className="article-insider dark:!border-[#474747] ">
                    <button
                      style={{ marginBottom: "1rem" }}
                      className="back-button"
                      onClick={() =>
                        setRecommendedArticles((state) => ({
                          data: null,
                          isOpen: false,
                        }))
                      }
                    >
                      <ArrowLeft
                        size={16}
                        style={{
                          color:
                            context.globalTheme == "white"
                              ? "#2570FE"
                              : "#77A6FF",
                          marginRight: "16px",
                        }}
                      />
                      <span
                        style={{
                          color:
                            context.globalTheme == "white"
                              ? "#2570FE"
                              : "#77A6FF",
                        }}
                      >
                        Back To Recommended Articles
                      </span>
                    </button>
                    <div className="title dark:!text-white">
                      <h3 className="dark:!text-white">
                        {
                          getSingleRecommendedData?.data?.data?.data
                            ?.product_name
                        }
                      </h3>
                      <p
                        className="dark:!text-white"
                        dangerouslySetInnerHTML={{
                          __html:
                            getSingleRecommendedData?.data?.data?.data
                              ?.product_sdesc,
                        }}
                      />
                    </div>

                    <SideNavDivider />
                    <div className="steps-container">
                      <div className="steps-content">
                        <p
                          className="dark:!text-white"
                          dangerouslySetInnerHTML={{
                            __html:
                              getSingleRecommendedData?.data?.data?.data
                                ?.product_desc,
                          }}
                        />
                      </div>
                      <div
                        className="steps-content"
                        style={{ marginTop: "1rem" }}
                      >
                        <div
                          className="icon-content-box dark:!bg-[#161616] dark:!text-white"
                          style={{ padding: "1rem" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <Collaborate
                              size={16}
                              style={{ marginRight: "10px" }}
                            />
                            <h5 className="dark:!text-white">Call Out Box</h5>
                          </div>
                          <div className="dark:!text-white">
                            <p className="dark:!text-white">
                              Ullamco laboris nisi ut aliquip ex ea commodo
                              consequat ullamco laboris nisi ut aliquip ex ea
                              commodo consequat.{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                )}
              </Column>
            </Row>
          </div> */}

          <div className="service_request_status">
            <div>
              <Serviceindex />
            </div>
            <div>
              <ServiceRequestStatus />
            </div>
          </div>

          <div className="middle ">
            <Row>
              <Column lg={4}>
                <Copilot />
              </Column>

              <Column lg={12}>
                <CopilotSidebarContent />
              </Column>
            </Row>
          </div>
        </FlexGrid>
      </HomeWrapper>
    </>
  );
};
{
  /* <Catalog />; */
  /* <SearchLocate />; */
}
export default Home;
