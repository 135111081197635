import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSalesForceDataService } from "./SalesForceServices";
import proimg from "img/profile.png";
import { User } from "@carbon/icons-react";

export const getSalesForceData = createAsyncThunk(
  "salesforce/getSalesForceData",
  async (userId, thunkAPI) => {
    try {
      return await getSalesForceDataService();
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

const initialState = {
  userInfoData: {
    error: null,
    status: "idle",
    data: {
      companyInfo: {},
      contactInfo: {},
      contactData: {},
      profileImage: "https://cdn.uvation.com/identity/image/User.png",
    },
    message: "",
  },
};

export const salesForceSlice = createSlice({
  name: "salesforce",
  initialState,
  reducers: {
    uploadImage: (state, { payload }) => {
      state.userInfoData.data.profileImage = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSalesForceData.fulfilled, (state, { payload }) => {
      state.userInfoData.data = payload;
      state.userInfoData.error = null;
      state.userInfoData.status = "succeeded";
    });
    builder.addCase(getSalesForceData.pending, (state, payload) => {
      state.userInfoData.status = "loading";
      state.userInfoData.data = initialState.userInfoData.data;
      state.userInfoData.error = null;
    });
    builder.addCase(getSalesForceData.rejected, (state, { payload }) => {
      state.userInfoData.status = "failed";
      state.userInfoData.data = initialState.userInfoData.data;
      state.userInfoData.error = payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const { uploadImage } = salesForceSlice.actions;

export default salesForceSlice.reducer;
