import axios from "axios";

const axiosInstance = axios.create({
  // baseURL: Backed_url,
});

axiosInstance.interceptors.request.use((config) => {
  config.headers.Authorization = localStorage.getItem("authtoken");

  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.status === 401) {
      //   sessionStorage.clear();
    }
    return response;
  },
  (error) => {
    const { response } = error;
    if (response?.status === 401) {
      //   sessionStorage.clear();
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
