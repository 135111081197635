import { useContext, useRef, useEffect, useState } from "react";
import { Faqguidewrapper } from "./style";
import {
  Accordion,
  AccordionItem,
  Checkbox,
  Column,
  FlexGrid,
  Layer,
  Row,
  Search,
  TextInput,
  Theme,
  Dropdown,
  Loading,
} from "@carbon/react";
import { GlobalContext } from "ContextApi/GlobalContext";
import { data, map } from "jquery";
import { List } from "@carbon/icons-react";
import { Trainingdata } from "Component/Data/DataJson";
import { ArrowRight, Time } from "@carbon/icons-react";
import CustomButton from "components/button/customButton";
import ReactDOM from "react-dom";
import {
  Link,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
} from "react-scroll";
import useWindowSize from "hooks/useWindowSize";
import { GetFaqAndGuidesData } from "redux/slices/TraningGuidens/TrainingGuidensSlice";
import { useDispatch, useSelector } from "react-redux";

const Faqguide = () => {
  const context = useContext(GlobalContext);
  const { width } = useWindowSize();
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();

  const { knowledgeFaqData } = useSelector((state) => state.Document);
  const [linkArray, setLinkArray] = useState([]);
  const [totalDataArray, setTotalDataArray] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [knowledgeFaqDataArray, setKnowledgeFaqDataArray] = useState([]);
  const [selectedItem, setSelectedItem] = useState("");

  const handleLoadMore = (itemIndex) => {
    let temp = totalDataArray.map((item, index) => {
      if (itemIndex == index) {
        return {
          limit: item.limit + 10,
        };
      } else {
        return item;
      }
    });

    setTotalDataArray(temp);
  };

  const handleLess = (itemIndex) => {
    let temp = totalDataArray.map((item, index) => {
      return itemIndex == index ? { limit: 10 } : item;
    });
    setTotalDataArray(temp);
  };

  useEffect(() => {
    const temp = knowledgeFaqData?.data?.data?.faqs_data?.map((item, index) => {
      return item.heading;
    });
    setLinkArray(temp);
    setKnowledgeFaqDataArray(knowledgeFaqData?.data?.data?.faqs_data);
  }, [knowledgeFaqData?.data?.data?.faqs_data?.length > 0]);

  useEffect(() => {
    dispatch(GetFaqAndGuidesData());
  }, []);

  useEffect(() => {
    const newArray = knowledgeFaqData?.data?.data?.faqs_data.map((data) => {
      if (data.question_and_answer.length < 10) {
        return { limit: null };
      } else {
        return { limit: 10 };
      }
    });
    setTotalDataArray(newArray);
  }, [knowledgeFaqData]);

  useEffect(() => {
    setSelectedItem("General Inquiry");
  }, []);

  useEffect(() => {
    const dropdown = dropdownRef.current;
    if (dropdown) {
      const icon = dropdown.querySelector(".cds--list-box__menu-icon");

      if (icon) {
        if (icon) {
          const svgIcon = <List size={16} />;
          icon.innerHTML = "";
          ReactDOM.render(svgIcon, icon);
        }
      }
    }
  }, [width <= 768]);

  const handleFilter = (event) => {
    if (event.key === "Enter") {
      const temp = knowledgeFaqData?.data?.data?.faqs_data;

      const word = searchInput.toLowerCase();

      const result = [];
      temp?.forEach((item) => {
        const heading = item.heading.toLowerCase();
        if (heading.includes(word)) {
          result.push(item);
        }

        const matchedQuestionsAnswers = item?.question_and_answer?.filter(
          (qa) => {
            let questionLowerCase = qa.question
              ? qa.question.toLowerCase()
              : "";
            let answerLowerCase = qa.answer ? qa.answer.toLowerCase() : "";

            return questionLowerCase.includes(word) ||
              answerLowerCase.includes(word)
              ? questionLowerCase.includes(word) ||
                  answerLowerCase.includes(word)
              : "";
          }
        );

        if (matchedQuestionsAnswers.length > 0) {
          result.push({
            heading: item.heading,
            question_and_answer: matchedQuestionsAnswers,
          });
        }
      });

      const uniqueArr = [...new Set(result)];
      setKnowledgeFaqDataArray(uniqueArr);

      const newArray = uniqueArr?.map((data) => {
        if (data.question_and_answer.length < 10) {
          return { ...data, limit: null };
        } else {
          return { ...data, limit: 10 };
        }
      });
      setTotalDataArray(newArray);

      document.activeElement.blur();
    }
  };

  const handleDropdownChange = (event) => {
    setSelectedItem(event.selectedItem);
    const selectedElement = document.getElementById(event.selectedItem);

    // Scroll to the selected element
    if (selectedElement) {
      selectedElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };

  return (
    <Theme theme={context?.globalTheme == "white" ? "white" : "g90"}>
      <Faqguidewrapper
        style={{
          backgroundImage: width > 768 && `url("/headerbackground.png")`,
        }}
      >
        {width <= 768 && (
          <div className="dropdownsticky">
            <Dropdown
              ref={dropdownRef}
              className="bg-[#f4f4f4] z-50 dropdown_faq"
              id="default"
              selectedItem={selectedItem}
              onChange={handleDropdownChange}
              items={linkArray ? linkArray : []}
              itemToString={(item) =>
                item ? <div className="icon_div">{item}</div> : ""
              }
            />
          </div>
        )}

        <section className="main_heading_div">
          <FlexGrid fullWidth>
            <div className="training_adminsmainheading">
              <Row>
                <Column lg={12}>
                  <h6 className="!text-[#6f6f6f] dark:!text-[#fff]">
                    SUPPORT DASHBOARD
                  </h6>
                  <h4 className="dark:!text-[#fff]">FAQ & Guides</h4>
                </Column>
                <Column lg={4}>
                  <Layer>
                    <div>
                      <Search
                        labelText=""
                        size="lg"
                        placeholder="Search input text"
                        closeButtonLabelText="Clear search input"
                        id="search-1"
                        onChange={(e) => {
                          setSearchInput(e.target.value);
                        }}
                        onClear={() => {
                          setSearchInput("");
                          setKnowledgeFaqDataArray(
                            knowledgeFaqData?.data?.data?.faqs_data
                          );
                          setTotalDataArray([
                            ...knowledgeFaqData?.data?.data?.faqs_data.map(
                              (data) => {
                                if (data.question_and_answer.length < 10) {
                                  return { limit: null };
                                } else {
                                  return { limit: 10 };
                                }
                              }
                            ),
                          ]);
                        }}
                        onKeyDown={(e) => handleFilter(e)}
                      />
                    </div>
                  </Layer>
                </Column>
              </Row>
            </div>
          </FlexGrid>
        </section>

        <FlexGrid
          fullWidth
          style={{ backgroundColor: "#fff" }}
          className="dark:!bg-[#262626]"
        >
          <Row style={{ padding: "32px 0" }}>
            {width > 768 && (
              <Column lg={4}>
                <div className="filter_data">
                  {linkArray &&
                    linkArray.map((links, index) => {
                      return (
                        <Link
                          activeClass="active"
                          spy={true}
                          smooth={true}
                          duration={500}
                          to={links}
                          offset={-100}
                        >
                          <div
                            className="spy-box-content "
                            style={{ padding: "16px" }}
                          >
                            {links}
                          </div>
                        </Link>
                      );
                    })}
                </div>
              </Column>
            )}
            <Column lg={12}>
              <Row>
                {knowledgeFaqData?.status != "loading" ? (
                  knowledgeFaqDataArray?.length > 0 &&
                  knowledgeFaqDataArray?.map((item, index) => {
                    return (
                      <Column lg={16}>
                        <div key={index} className="faq_heading">
                          <h5 id={item.heading} className="dark:!text-[white]">
                            {item.heading}
                          </h5>
                          <Accordion id={item.heading}>
                            {totalDataArray?.length &&
                              totalDataArray[index]?.limit != null &&
                              item?.question_and_answer
                                ?.slice(0, totalDataArray[index]?.limit)
                                .map((data, qIndex) => {
                                  return (
                                    <AccordionItem
                                      key={qIndex}
                                      title={data?.question}
                                    >
                                      <p
                                        className="dangerous_para"
                                        dangerouslySetInnerHTML={{
                                          __html: data?.answer,
                                        }}
                                      />
                                    </AccordionItem>
                                  );
                                })}

                            {totalDataArray?.length &&
                              totalDataArray[index]?.limit == null &&
                              item?.question_and_answer.map((data, qIndex) => {
                                return (
                                  <AccordionItem
                                    key={qIndex}
                                    title={data?.question}
                                  >
                                    <p
                                      className="dangerous_para"
                                      dangerouslySetInnerHTML={{
                                        __html: data?.answer,
                                      }}
                                    />
                                  </AccordionItem>
                                );
                              })}
                          </Accordion>

                          {totalDataArray?.length &&
                            totalDataArray[index]?.limit != null && (
                              <div
                                key={index}
                                onClick={() =>
                                  totalDataArray?.length &&
                                  totalDataArray[index]?.limit >=
                                    item?.question_and_answer.length
                                    ? handleLess(index)
                                    : handleLoadMore(index)
                                }
                              >
                                <span className="load_more" key={index}>
                                  {totalDataArray?.length &&
                                  totalDataArray[index]?.limit >=
                                    item?.question_and_answer.length
                                    ? "Show Less..."
                                    : "Load More..."}
                                </span>
                              </div>
                            )}
                        </div>
                      </Column>
                    );
                  })
                ) : (
                  <Loading loading={knowledgeFaqData?.status == "loading"} />
                )}
              </Row>
            </Column>
          </Row>
        </FlexGrid>
      </Faqguidewrapper>
    </Theme>
  );
};

export default Faqguide;
