import {
  CheckmarkOutline,
  MisuseOutline,
  Settings,
  WarningAlt,
  WarningHex,
} from "@carbon/icons-react";
import { Link } from "react-router-dom";

export const FillterData = [
  {
    catagory: "Product",
    id: "Prod",
    subcat: [
      {
        id: "Azure",
        label: "Microsoft Azure",
      },
      {
        id: "Aws",
        label: "Amazon AWS",
      },
      {
        id: "IBM",
        label: "IBM Cloud",
      },
      {
        id: "Oracle",
        label: "Oracle Cloud",
      },
      {
        id: "Datacenter",
        label: "Datacenter",
      },
      {
        id: "Baremetal",
        label: "Baremetal",
      },
      {
        id: "Redhat",
        label: "Redhat Openshift",
      },
      {
        id: "XKS",
        label: "XKS",
      },
      {
        id: "Azure",
        label: "Microsoft Azure",
      },
      {
        id: "Storage Service",
        label: "Storage Service",
      },
      {
        id: "Disaster Recovery",
        label: "Disaster Recovery",
      },
    ],
  },
  {
    catagory: "Roles",
    id: "Roles",
    subcat: [
      {
        id: "Administrator",
        label: "Administrator",
      },
      {
        id: "AI Engineer",
        label: "AI Engineer",
      },
      {
        id: "Business Owner",
        label: "Business Owner",
      },
      {
        id: "Data Analyst",
        label: "Data Analyst",
      },
      {
        id: "Developer",
        label: "Developer",
      },
      {
        id: "Datacenter",
        label: "Datacenter",
      },
      {
        id: "Security Engineer",
        label: "Security Engineer",
      },
      {
        id: "Security Operations Analyst",
        label: "Security Operations Analyst",
      },
      {
        id: "Technology Manager",
        label: "Technology Manager",
      },
    ],
  },
  {
    catagory: "Levels",
    id: "Levels",
    subcat: [
      {
        id: "Beginner",
        label: "Beginner",
      },
      {
        id: "Intermediate",
        label: "Intermediate",
      },
      {
        id: "Advanced",
        label: "Advanced",
      },
    ],
  },
];
export const Productdata = [
  {
    id: 1,
    heading: "Google Cloud",
    desc: "Explore debugging techniques for your Google Cloud environment.",
    image: "google.png",
  },
  {
    id: 2,
    heading: "Backup Services",
    desc: "Discover ways to receive assistance in resolving software update issues on IBM Cloud.",
    image: "Data-backup.png",
  },

  {
    id: 3,
    heading: "IBM Cloud",
    desc: "Discover ways to receive assistance in resolving software update issues on IBM Cloud.",
    image: "ibm-cloud-logo.png",
  },
  {
    id: 4,
    heading: "Disaster Recovery",
    desc: "Learn how to troubleshoot software update issues on Disaster Recovery for effective solutions and support.",
    image: "Update-now.png",
  },

  {
    id: 5,
    heading: "Amazon AWS",
    desc: "Discover ways to receive assistance in resolving software update issues on IBM Cloud.",
    image: "Aws.png",
  },
  {
    id: 6,
    heading: "Baremetal",
    desc: "Find guidance on resolving software update issues with Baremetal through helpful resources and support.",
    image: "Bare-metal-server.png",
  },
  {
    id: 7,
    heading: "Microsoft Azure",
    desc: "Explore solutions for seeking help with software update concerns on Microsoft Azure.",
    image: "microsoft-azure-logo.png",
  },
  {
    id: 8,
    heading: "Disaster Recovery",
    desc: "Learn how to troubleshoot software update issues on Disaster Recovery for effective solutions and support.",
    image: "Update-now.png",
  },
  {
    id: 9,
    heading: "VMWare",
    desc: "Explore solutions for seeking help with software update concerns on VMWare.",
    image: "vmware.png",
  },
];

export const Servicedata = [
  {
    id: 1,
    heading: "Redhat Openshift",
    desc: "Optimize software updates on Redhat Openshift with expert guidance for enhanced performance.",
    image: "Redhat.png",
  },
  {
    id: 2,
    heading: "Datacenter",
    desc: "Explore solutions for fixing software update issues on Datacenter with step-by-step guidance and support.",
    image: "Datatcenter.png",
  },
  {
    id: 3,
    heading: "XKS",
    desc: "Maximize XKS performance by optimizing software updates with expert guidance.",
    image: "xks.png",
  },
  {
    id: 4,
    heading: "Network Operation ",
    desc: "Maximize XKS performance by optimizing software updates with expert guidance.",
    image: "Router.png",
  },
  {
    id: 5,
    heading: "Connectivity ",
    desc: "Explore solutions for fixing software update issues on Connectivity with step-by-step guidance and support.",
    image: "connectIbm.png",
  },
  {
    id: 6,
    heading: "Amazon AWS ",
    desc: "Discover ways to receive assistance in resolving software update issues on Amazon AWS.",
    image: "Aws.png",
  },
  {
    id: 7,
    heading: "IBM Cloud",
    desc: "Discover ways to receive assistance in resolving software update issues on IBM Cloud.",
    image: "ibm-cloud-logo.png",
  },
  {
    id: 8,
    heading: "Oracle Cloud ",
    desc: "Maximize XKS performance by optimizing software updates with expert guidance.",
    image: "oracle 1.png",
  },
  {
    id: 9,
    heading: "Microsoft Azure",
    desc: "Explore solutions for seeking help with software update concerns on Microsoft Azure.",
    image: "microsoft-azure-logo.png",
  },
];
export const OtherresourceData = [
  {
    id: 1,
    heading: "Disaster Recovery",
    desc: "Unlock expertise and efficiency with instructive guides designed for administrators' success.",
    image: "googledark.png",
  },
  {
    id: 2,
    heading: "IBM Cloud",
    desc: "Access comprehensive training documentation tailored for administrators on Google Cloud.",
    image: "googledark.png",
  },
  {
    id: 3,
    heading: "Educational Materials For Admins",
    desc: "Access a wealth of educational materials curated to elevate the knowledge and capabilities of administrators.",
    image: "googledark.png",
  },
];
export const Trainingdata = [
  {
    id: 1,
    heading:
      "Exploring Key Data Modeling Families: A Foundation for Google Cloud Analytics Mastery",
    desc: "This course presents an introduction to one of the primary modeling families utilized by Data Analysts in...",
    image1: "/google.png",
    image2: "/machine--learning--03.png",
  },
  {
    id: 2,
    heading:
      "Mastering AI Management: A Comprehensive Guide with Amazon AWS Services",
    desc: "The Amazon AWS service provides a comprehensive guide to ensure effective AI management.",
    image1: "/Aws.png",
    image2: "/ai--trust.png",
  },
  {
    id: 3,
    heading: "Microsoft Azure Fundamentals: Describe cloud concepts",
    desc: "The Microsoft Azure service provides a comprehensive guide to ensure effective management and optimizati...",
    image1: "/microsoft-azure-logo.png",
    image2: "/machine--learning--03.png",
  },
  {
    id: 4,
    heading:
      "Exploring Key Data Modeling Families: A Foundation for Google Cloud Analytics Mastery",
    desc: "This course presents an introduction to one of the primary modeling families utilized by Data Analysts in...",
    image1: "/google.png",
    image2: "/machine--learning--03.png",
  },
  {
    id: 5,
    heading:
      "Mastering AI Management: A Comprehensive Guide with Amazon AWS Services",
    desc: "The Amazon AWS service provides a comprehensive guide to ensure effective AI management.",
    image1: "/Aws.png",
    image2: "/ai--trust.png",
  },
  {
    id: 6,
    heading: "Microsoft Azure Fundamentals: Describe cloud concepts",
    desc: "The Microsoft Azure service provides a comprehensive guide to ensure effective management and optimizati...",
    image1: "/microsoft-azure-logo.png",
    image2: "/machine--learning--03.png",
  },
  {
    id: 7,
    heading:
      "Exploring Key Data Modeling Families: A Foundation for Google Cloud Analytics Mastery",
    desc: "This course presents an introduction to one of the primary modeling families utilized by Data Analysts in...",
    image1: "/google.png",
    image2: "/machine--learning--03.png",
  },
  {
    id: 8,
    heading:
      "Mastering AI Management: A Comprehensive Guide with Amazon AWS Services",
    desc: "The Amazon AWS service provides a comprehensive guide to ensure effective AI management.",
    image1: "/Aws.png",
    image2: "/ai--trust.png",
  },
  {
    id: 9,
    heading: "Microsoft Azure Fundamentals: Describe cloud concepts",
    desc: "The Microsoft Azure service provides a comprehensive guide to ensure effective management and optimizati...",
    image1: "/microsoft-azure-logo.png",
    image2: "/machine--learning--03.png",
  },
];

export const msg_headerData5 = [
  {
    header: "Notification",
    key: "message",
  },
  {
    header: "Date",
    key: "notification_time",
  },
];

export const dropdown_items = [
  {
    id: "",
    label: "All",
  },
  {
    id: "major_outage",
    label: "MAJOR OUTAGE",
  },

  {
    id: "operational",
    label: "OPERATIONAL",
  },
  {
    id: "degraded_performance",
    label: "DEGRADED PERFORMANCE",
  },
  {
    id: "partial_outage",
    label: "PARTIAL OUTAGE",
  },
];

export const dropdown_items2 = [
  {
    id: "",
    label: "All",
  },
  {
    id: "scheduled",
    label: "Scheduled",
  },

  {
    id: "completed",
    label: "Resolved",
  },
  {
    id: "investigating",
    label: "Investigating",
  },
  {
    id: "monitoring",
    label: "Monitoring",
  },
];
export const Planned_header2 = [
  {
    header: "Service",
    key: "components_name",
  },

  {
    header: "Subject",
    key: "name",
  },

  {
    header: "Status",
    key: "status",
  },
  {
    header: "Start Date",
    key: "created_at",
  },
];
export const Planned_header = [
  {
    header: "Service",
    key: "components_name",
  },
  {
    header: "Subject",
    key: "name",
  },
  {
    header: "Status",
    key: "status",
  },
  {
    header: "Start Date",
    key: "created_at",
  },
];

export const ticheaders = [
  {
    header: "Ticket#",
    key: "id",
  },
  {
    header: "Date created",
    key: "date",
  },
  {
    header: "Subject",
    key: "subject",
  },
  {
    header: "Status",
    key: "status",
  },
];

export const SeverityList = [
  {
    id: "1",
    label: "Low Impact (Severity Four) (Response Time < 48 Hours)",
  },
  {
    id: "2",
    label: "Normal Impact (Severity Three) (Response Time < 24 Hours)",
  },
  {
    id: "3",
    label: "Significant Impact (Severity Two) (Response Time < 8 Hours)",
  },
  {
    id: "4",
    label: "Critical Impact (Severity One) (Response Time < 1 Hours)",
  },
];

export const ServiceList = [
  {
    id: "1",
    label: "Uvation-identity",
  },
  {
    id: "2",
    label: "Uvation-support",
  },
  {
    id: "3",
    label: "Uvation-Service-Platform",
  },
  {
    id: "4",
    label: "Uvation-megento",
  },
];

export const userdropdwonitems = [
  {
    id: "option-1",
    label: "Active",
  },
  {
    id: "option-2",
    label: "Pending",
  },
  {
    id: "option-3",
    label: "Closed",
  },
  {
    id: "option-4",
    label: "No-Status",
  },
];

export const msg_headerData = [
  {
    header: "Actions",
    key: "actions",
  },
  {
    header: "Subject",
    key: "subject",
  },
  {
    header: "Data sent",
    key: "datasent",
  },
  {
    header: "",
    key: "overflow",
  },
];

export const msg_rowData = [
  {
    id: "a",
    actions: "",
    subject: "You have one invoice waiting",
    datasent: "Wednesday, May 24th, 2020 (08:07)",
  },
  {
    id: "b",
    actions: "",
    subject: "We added 10 more services",
    datasent: "Wednesday, May 27th, 2020 (08:07)",
  },
  {
    id: "c",
    actions: "",
    subject: "Maintenance scheduled for May 18th, 00:00 AM",
    datasent: "Wednesday, May 26th, 2020 (08:07)",
  },
  {
    id: "d",
    actions: "",
    subject: "Email verification security code request received",
    datasent: "Wednesday, May 26th, 2020 (08:07)",
  },
  {
    id: "e",
    actions: "",
    subject: "Welcome to Uvation Cloud Services!",
    datasent: "Wednesday, May 28th, 2020 (08:07)",
  },
  {
    id: "f",
    actions: "",
    subject: "You have one invoice waiting",
    datasent: "Wednesday, May 26th, 2020 (08:07)",
  },
  {
    id: "g",
    actions: "",
    subject: "You have one invoice waiting",
    datasent: "Wednesday, May 26th, 2020 (08:07)",
  },
  {
    id: "h",
    actions: "",
    subject: "We added 10 more services",
    datasent: "Wednesday, May 28th, 2020 (08:07)",
  },
  {
    id: "i",
    actions: "",
    subject: "Maintenance scheduled for May 18th, 00:00 AM",
    datasent: "Wednesday, May 29th, 2020 (08:07)",
  },
  {
    id: "j",
    actions: "",
    subject: "Email verification security code request received",
    datasent: "Wednesday, May 26th, 2020 (08:07)",
  },
  {
    id: "k",
    actions: "",
    subject: "Welcome to Uvation Cloud Services!",
    datasent: "Wednesday, May 29th, 2020 (08:07)",
  },
];

export const indicateheadr = [
  {
    header: "Service",
    key: "service",
  },
  {
    header: "Subject",
    key: "subject",
  },
  {
    header: "Status",
    key: "status",
  },
  {
    header: "Start Date",
    key: "startdate",
  },
];

export const indicatedata = [
  {
    attached_groups: "Kevin’s VM Groups",
    id: "a",
    service: "Oracle Cloud",

    subject: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    startdate: "Thursday , March - 30th - 2023 (07:28) AM",
    status: "Major Outage",
  },

  {
    attached_groups: "Mel’s VM Groups",
    id: "e",
    service: "Oracle Cloud",

    subject: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    startdate: "Thursday , March - 30th - 2023 (07:28) AM",
    status: "Under Maintance",
  },
  {
    attached_groups: "Ronja’s VM Groups",
    id: "f",
    service: "Oracle Cloud",
    subject: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    startdate: "Thursday , March - 30th - 2023 (07:28) AM",
    status: "Operational",
  },
  {
    attached_groups: "Ronja’s VM Groups",
    id: "g",
    service: "Oracle Cloud",
    subject: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    startdate: "Thursday , March - 30th - 2023 (07:28) AM",
    status: "Partial Outage",
  },
  {
    attached_groups: "Ronja’s VM Groups",
    id: "h",
    service: "Oracle Cloud",
    subject: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    startdate: "Thursday , March - 30th - 2023 (07:28) AM",
    status: "Degraded Performance",
  },
];
export const countrycode = [
  { id: "India", dial_code: "+91", code: "IN" },
  { id: "Israel", dial_code: "+972", code: "IL" },
  { id: "Afghanistan", dial_code: "+93", code: "AF" },
  { id: "Albania", dial_code: "+355", code: "AL" },
  { id: "Algeria", dial_code: "+213", code: "DZ" },
  { id: "AmericanSamoa", dial_code: "+1 684", code: "AS" },
  { id: "Andorra", dial_code: "+376", code: "AD" },
  { id: "Angola", dial_code: "+244", code: "AO" },
  { id: "Anguilla", dial_code: "+1 264", code: "AI" },
  { id: "Antigua and Barbuda", dial_code: "+1268", code: "AG" },
  { id: "Argentina", dial_code: "+54", code: "AR" },
  { id: "Armenia", dial_code: "+374", code: "AM" },
  { id: "Aruba", dial_code: "+297", code: "AW" },
  { id: "Australia", dial_code: "+61", code: "AU" },
  { id: "Austria", dial_code: "+43", code: "AT" },
  { id: "Azerbaijan", dial_code: "+994", code: "AZ" },
  { id: "Bahamas", dial_code: "+1 242", code: "BS" },
  { id: "Bahrain", dial_code: "+973", code: "BH" },
  { id: "Bangladesh", dial_code: "+880", code: "BD" },
  { id: "Barbados", dial_code: "+1 246", code: "BB" },
  { id: "Belarus", dial_code: "+375", code: "BY" },
  { id: "Belgium", dial_code: "+32", code: "BE" },
  { id: "Belize", dial_code: "+501", code: "BZ" },
  { id: "Benin", dial_code: "+229", code: "BJ" },
  { id: "Bermuda", dial_code: "+1 441", code: "BM" },
  { id: "Bhutan", dial_code: "+975", code: "BT" },
  { id: "Bosnia and Herzegovina", dial_code: "+387", code: "BA" },
  { id: "Botswana", dial_code: "+267", code: "BW" },
  { id: "Brazil", dial_code: "+55", code: "BR" },
  { id: "British Indian Ocean Territory", dial_code: "+246", code: "IO" },
  { id: "Bulgaria", dial_code: "+359", code: "BG" },
  { id: "Burkina Faso", dial_code: "+226", code: "BF" },
  { id: "Burundi", dial_code: "+257", code: "BI" },
  { id: "Cambodia", dial_code: "+855", code: "KH" },
  { id: "Cameroon", dial_code: "+237", code: "CM" },
  { id: "Canada", dial_code: "+1", code: "CA" },
  { id: "Cape Verde", dial_code: "+238", code: "CV" },
  { id: "Cayman Islands", dial_code: "+ 345", code: "KY" },
  { id: "Central African Republic", dial_code: "+236", code: "CF" },
  { id: "Chad", dial_code: "+235", code: "TD" },
  { id: "Chile", dial_code: "+56", code: "CL" },
  { id: "China", dial_code: "+86", code: "CN" },
  { id: "Christmas Island", dial_code: "+61", code: "CX" },
  { id: "Colombia", dial_code: "+57", code: "CO" },
  { id: "Comoros", dial_code: "+269", code: "KM" },
  { id: "Congo", dial_code: "+242", code: "CG" },
  { id: "Cook Islands", dial_code: "+682", code: "CK" },
  { id: "Costa Rica", dial_code: "+506", code: "CR" },
  { id: "Croatia", dial_code: "+385", code: "HR" },
  { id: "Cuba", dial_code: "+53", code: "CU" },
  { id: "Cyprus", dial_code: "+537", code: "CY" },
  { id: "Czech Republic", dial_code: "+420", code: "CZ" },
  { id: "Denmark", dial_code: "+45", code: "DK" },
  { id: "Djibouti", dial_code: "+253", code: "DJ" },
  { id: "Dominica", dial_code: "+1 767", code: "DM" },
  { id: "Dominican Republic", dial_code: "+1 849", code: "DO" },
  { id: "Ecuador", dial_code: "+593", code: "EC" },
  { id: "Egypt", dial_code: "+20", code: "EG" },
  { id: "El Salvador", dial_code: "+503", code: "SV" },
  { id: "Equatorial Guinea", dial_code: "+240", code: "GQ" },
  { id: "Eritrea", dial_code: "+291", code: "ER" },
  { id: "Estonia", dial_code: "+372", code: "EE" },
  { id: "Ethiopia", dial_code: "+251", code: "ET" },
  { id: "Faroe Islands", dial_code: "+298", code: "FO" },
  { id: "Fiji", dial_code: "+679", code: "FJ" },
  { id: "Finland", dial_code: "+358", code: "FI" },
  { id: "France", dial_code: "+33", code: "FR" },
  { id: "French Guiana", dial_code: "+594", code: "GF" },
  { id: "French Polynesia", dial_code: "+689", code: "PF" },
  { id: "Gabon", dial_code: "+241", code: "GA" },
  { id: "Gambia", dial_code: "+220", code: "GM" },
  { id: "Georgia", dial_code: "+995", code: "GE" },
  { id: "Germany", dial_code: "+49", code: "DE" },
  { id: "Ghana", dial_code: "+233", code: "GH" },
  { id: "Gibraltar", dial_code: "+350", code: "GI" },
  { id: "Greece", dial_code: "+30", code: "GR" },
  { id: "Greenland", dial_code: "+299", code: "GL" },
  { id: "Grenada", dial_code: "+1 473", code: "GD" },
  { id: "Guadeloupe", dial_code: "+590", code: "GP" },
  { id: "Guam", dial_code: "+1 671", code: "GU" },
  { id: "Guatemala", dial_code: "+502", code: "GT" },
  { id: "Guinea", dial_code: "+224", code: "GN" },
  { id: "Guinea-Bissau", dial_code: "+245", code: "GW" },
  { id: "Guyana", dial_code: "+595", code: "GY" },
  { id: "Haiti", dial_code: "+509", code: "HT" },
  { id: "Honduras", dial_code: "+504", code: "HN" },
  { id: "Hungary", dial_code: "+36", code: "HU" },
  { id: "Iceland", dial_code: "+354", code: "IS" },

  { id: "Indonesia", dial_code: "+62", code: "ID" },
  { id: "Iraq", dial_code: "+964", code: "IQ" },
  { id: "Ireland", dial_code: "+353", code: "IE" },
  { id: "Israel", dial_code: "+972", code: "IL" },
  { id: "Italy", dial_code: "+39", code: "IT" },
  { id: "Jamaica", dial_code: "+1 876", code: "JM" },
  { id: "Japan", dial_code: "+81", code: "JP" },
  { id: "Jordan", dial_code: "+962", code: "JO" },
  { id: "Kazakhstan", dial_code: "+7 7", code: "KZ" },
  { id: "Kenya", dial_code: "+254", code: "KE" },
  { id: "Kiribati", dial_code: "+686", code: "KI" },
  { id: "Kuwait", dial_code: "+965", code: "KW" },
  { id: "Kyrgyzstan", dial_code: "+996", code: "KG" },
  { id: "Latvia", dial_code: "+371", code: "LV" },
  { id: "Lebanon", dial_code: "+961", code: "LB" },
  { id: "Lesotho", dial_code: "+266", code: "LS" },
  { id: "Liberia", dial_code: "+231", code: "LR" },
  { id: "Liechtenstein", dial_code: "+423", code: "LI" },
  { id: "Lithuania", dial_code: "+370", code: "LT" },
  { id: "Luxembourg", dial_code: "+352", code: "LU" },
  { id: "Madagascar", dial_code: "+261", code: "MG" },
  { id: "Malawi", dial_code: "+265", code: "MW" },
  { id: "Malaysia", dial_code: "+60", code: "MY" },
  { id: "Maldives", dial_code: "+960", code: "MV" },
  { id: "Mali", dial_code: "+223", code: "ML" },
  { id: "Malta", dial_code: "+356", code: "MT" },
  { id: "Marshall Islands", dial_code: "+692", code: "MH" },
  { id: "Martinique", dial_code: "+596", code: "MQ" },
  { id: "Mauritania", dial_code: "+222", code: "MR" },
  { id: "Mauritius", dial_code: "+230", code: "MU" },
  { id: "Mayotte", dial_code: "+262", code: "YT" },
  { id: "Mexico", dial_code: "+52", code: "MX" },
  { id: "Monaco", dial_code: "+377", code: "MC" },
  { id: "Mongolia", dial_code: "+976", code: "MN" },
  { id: "Montenegro", dial_code: "+382", code: "ME" },
  { id: "Montserrat", dial_code: "+1664", code: "MS" },
  { id: "Morocco", dial_code: "+212", code: "MA" },
  { id: "Myanmar", dial_code: "+95", code: "MM" },
  { id: "Namibia", dial_code: "+264", code: "NA" },
  { id: "Nauru", dial_code: "+674", code: "NR" },
  { id: "Nepal", dial_code: "+977", code: "NP" },
  { id: "Netherlands", dial_code: "+31", code: "NL" },
  { id: "Netherlands Antilles", dial_code: "+599", code: "AN" },
  { id: "New Caledonia", dial_code: "+687", code: "NC" },
  { id: "New Zealand", dial_code: "+64", code: "NZ" },
  { id: "Nicaragua", dial_code: "+505", code: "NI" },
  { id: "Niger", dial_code: "+227", code: "NE" },
  { id: "Nigeria", dial_code: "+234", code: "NG" },
  { id: "Niue", dial_code: "+683", code: "NU" },
  { id: "Norfolk Island", dial_code: "+672", code: "NF" },
  { id: "Northern Mariana Islands", dial_code: "+1 670", code: "MP" },
  { id: "Norway", dial_code: "+47", code: "NO" },
  { id: "Oman", dial_code: "+968", code: "OM" },
  { id: "Pakistan", dial_code: "+92", code: "PK" },
  { id: "Palau", dial_code: "+680", code: "PW" },
  { id: "Panama", dial_code: "+507", code: "PA" },
  { id: "Papua New Guinea", dial_code: "+675", code: "PG" },
  { id: "Paraguay", dial_code: "+595", code: "PY" },
  { id: "Peru", dial_code: "+51", code: "PE" },
  { id: "Philippines", dial_code: "+63", code: "PH" },
  { id: "Poland", dial_code: "+48", code: "PL" },
  { id: "Portugal", dial_code: "+351", code: "PT" },
  { id: "Puerto Rico", dial_code: "+1 939", code: "PR" },
  { id: "Qatar", dial_code: "+974", code: "QA" },
  { id: "Romania", dial_code: "+40", code: "RO" },
  { id: "Rwanda", dial_code: "+250", code: "RW" },
  { id: "Samoa", dial_code: "+685", code: "WS" },
  { id: "San Marino", dial_code: "+378", code: "SM" },
  { id: "Saudi Arabia", dial_code: "+966", code: "SA" },
  { id: "Senegal", dial_code: "+221", code: "SN" },
  { id: "Serbia", dial_code: "+381", code: "RS" },
  { id: "Seychelles", dial_code: "+248", code: "SC" },
  { id: "Sierra Leone", dial_code: "+232", code: "SL" },
  { id: "Singapore", dial_code: "+65", code: "SG" },
  { id: "Slovakia", dial_code: "+421", code: "SK" },
  { id: "Slovenia", dial_code: "+386", code: "SI" },
  { id: "Solomon Islands", dial_code: "+677", code: "SB" },
  { id: "South Africa", dial_code: "+27", code: "ZA" },
  {
    id: "South Georgia and the South Sandwich Islands",
    dial_code: "+500",
    code: "GS",
  },
  { id: "Spain", dial_code: "+34", code: "ES" },
  { id: "Sri Lanka", dial_code: "+94", code: "LK" },
  { id: "Sudan", dial_code: "+249", code: "SD" },
  { id: "Suriid", dial_code: "+597", code: "SR" },
  { id: "Swaziland", dial_code: "+268", code: "SZ" },
  { id: "Sweden", dial_code: "+46", code: "SE" },
  { id: "Switzerland", dial_code: "+41", code: "CH" },
  { id: "Tajikistan", dial_code: "+992", code: "TJ" },
  { id: "Thailand", dial_code: "+66", code: "TH" },
  { id: "Togo", dial_code: "+228", code: "TG" },
  { id: "Tokelau", dial_code: "+690", code: "TK" },
  { id: "Tonga", dial_code: "+676", code: "TO" },
  { id: "Trinidad and Tobago", dial_code: "+1 868", code: "TT" },
  { id: "Tunisia", dial_code: "+216", code: "TN" },
  { id: "Turkey", dial_code: "+90", code: "TR" },
  { id: "Turkmenistan", dial_code: "+993", code: "TM" },
  { id: "Turks and Caicos Islands", dial_code: "+1 649", code: "TC" },
  { id: "Tuvalu", dial_code: "+688", code: "TV" },
  { id: "Uganda", dial_code: "+256", code: "UG" },
  { id: "Ukraine", dial_code: "+380", code: "UA" },
  { id: "United Arab Emirates", dial_code: "+971", code: "AE" },
  { id: "United Kingdom", dial_code: "+44", code: "GB" },
  { id: "United States", dial_code: "+1", code: "US" },
  { id: "Uruguay", dial_code: "+598", code: "UY" },
  { id: "Uzbekistan", dial_code: "+998", code: "UZ" },
  { id: "Vanuatu", dial_code: "+678", code: "VU" },
  { id: "Wallis and Futuna", dial_code: "+681", code: "WF" },
  { id: "Yemen", dial_code: "+967", code: "YE" },
  { id: "Zambia", dial_code: "+260", code: "ZM" },
  { id: "Zimbabwe", dial_code: "+263", code: "ZW" },
  { id: "land Islands", dial_code: "", code: "AX" },
  { id: "Antarctica", dial_code: null, code: "AQ" },
  { id: "Bolivia, Plurinational State of", dial_code: "+591", code: "BO" },
  { id: "Brunei Darussalam", dial_code: "+673", code: "BN" },
  { id: "Cocos (Keeling) Islands", dial_code: "+61", code: "CC" },
  {
    id: "Congo, The Democratic Republic of the",
    dial_code: "+243",
    code: "CD",
  },
  { id: "Cote d'Ivoire", dial_code: "+225", code: "CI" },
  { id: "Falkland Islands (Malvinas)", dial_code: "+500", code: "FK" },
  { id: "Guernsey", dial_code: "+44", code: "GG" },
  { id: "Holy See (Vatican City State)", dial_code: "+379", code: "VA" },
  { id: "Hong Kong", dial_code: "+852", code: "HK" },
  { id: "Iran, Islamic Republic of", dial_code: "+98", code: "IR" },
  { id: "Isle of Man", dial_code: "+44", code: "IM" },
  { id: "Jersey", dial_code: "+44", code: "JE" },
  {
    id: "Korea, Democratic People's Republic of",
    dial_code: "+850",
    code: "KP",
  },
  { id: "Korea, Republic of", dial_code: "+82", code: "KR" },
  { id: "Lao People's Democratic Republic", dial_code: "+856", code: "LA" },
  { id: "Libyan Arab Jamahiriya", dial_code: "+218", code: "LY" },
  { id: "Macao", dial_code: "+853", code: "MO" },
  {
    id: "Macedonia, The Former Yugoslav Republic of",
    dial_code: "+389",
    code: "MK",
  },
  { id: "Micronesia, Federated States of", dial_code: "+691", code: "FM" },
  { id: "Moldova, Republic of", dial_code: "+373", code: "MD" },
  { id: "Mozambique", dial_code: "+258", code: "MZ" },
  { id: "Palestinian Territory, Occupied", dial_code: "+970", code: "PS" },
  { id: "Pitcairn", dial_code: "+872", code: "PN" },
  { id: "Réunion", dial_code: "+262", code: "RE" },
  { id: "Russia", dial_code: "+7", code: "RU" },
  { id: "Saint Barthélemy", dial_code: "+590", code: "BL" },
  {
    id: "Saint Helena, Ascension and Tristan Da Cunha",
    dial_code: "+290",
    code: "SH",
  },
  { id: "Saint Kitts and Nevis", dial_code: "+1 869", code: "KN" },
  { id: "Saint Lucia", dial_code: "+1 758", code: "LC" },
  { id: "Saint Martin", dial_code: "+590", code: "MF" },
  { id: "Saint Pierre and Miquelon", dial_code: "+508", code: "PM" },
  { id: "Saint Vincent and the Grenadines", dial_code: "+1 784", code: "VC" },
  { id: "Sao Tome and Principe", dial_code: "+239", code: "ST" },
  { id: "Somalia", dial_code: "+252", code: "SO" },
  { id: "Svalbard and Jan Mayen", dial_code: "+47", code: "SJ" },
  { id: "Syrian Arab Republic", dial_code: "+963", code: "SY" },
  { id: "Taiwan, Province of China", dial_code: "+886", code: "TW" },
  { id: "Tanzania, United Republic of", dial_code: "+255", code: "TZ" },
  { id: "Timor-Leste", dial_code: "+670", code: "TL" },
  { id: "Venezuela, Bolivarian Republic of", dial_code: "+58", code: "VE" },
  { id: "Viet Nam", dial_code: "+84", code: "VN" },
  { id: "Virgin Islands, British", dial_code: "+1 284", code: "VG" },
  { id: "Virgin Islands, U.S.", dial_code: "+1 340", code: "VI" },
];

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const renderIcon = (status, context) => {
  let style;
  let IconComponent;

  switch (status) {
    case "major_outage":
      style = {
        backgroundColor: "#DA1E28",
        color: context.globalTheme == "white" ? "white" : "black",
      }; // Red for Major Outage
      IconComponent = MisuseOutline;
      break;
    case "Under Maintance":
      style = {
        backgroundColor: "#0F62FE",
        color: context.globalTheme == "white" ? "white" : "black",
      }; // Blue for Under Maintenance
      IconComponent = Settings;
      break;
    case "operational":
      style = {
        backgroundColor: "#198038",
        color: context.globalTheme == "white" ? "white" : "black",
      }; // Green for Operational
      IconComponent = CheckmarkOutline;
      break;
    // ... other cases
    case "partial_outage":
      style = {
        backgroundColor: "#F1C21B",
        color: context.globalTheme == "white" ? "white" : "black",
      }; // Green for Operational
      IconComponent = WarningAlt;
      break;
    // ... other cases
    case "degraded_performance":
      style = {
        backgroundColor: "#FF832B",
        color: context.globalTheme == "white" ? "white" : "black",
      }; // Green for Operational
      IconComponent = WarningHex;
      break;
    // ... other cases
    default:
      style = {
        backgroundColor: "transparent",
        color: context.globalTheme == "white" ? "white" : "black",
      }; // Default case
      IconComponent = null;
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0.4rem",
        borderRadius: "16px",
        flexWrap: "no-wrap",
        width: "max-content",
        ...style,
      }}
    >
      <span
        style={{
          fontFamily: "IBM Plex Sans",
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "16px" /* 133.333% */,
          letterSpacing: "0.32px",
          color: "white",
          paddingRight: "10px",
        }}
      >
        {
          status
            .split("_")
            .map((text) => capitalizeFirstLetter(text))
            .join(" ")
          // .slice(0, 9) + "..."
        }
      </span>
      {IconComponent && <IconComponent size={16} />}
    </div>
  );
};

export const Serivity = [
  {
    id: "Low",
    text: "Low",
  },
  {
    id: "Normal",
    text: "Normal",
  },
  {
    id: "Significant",
    text: "Significant",
  },
  {
    id: "Critical",
    text: "Critical",
  },
];
export const caseType = [
  {
    id: "2",
    text: "Technical",
  },
  {
    id: "4",
    text: "Customer",
  },
];
export const items = [
  {
    id: "Open",
    text: "Open",
  },
  {
    id: "Assigned",
    text: "Assigned",
  },
  {
    id: "Pending",
    text: "Pending",
  },
  {
    id: "Closed",
    text: "Closed",
  },
];

export const headers = [
  { key: "service", header: "Service" },
  { key: "status1", header: "Dallas" }, // Renamed for uniqueness
  { key: "status2", header: "Los Angeles" },
  { key: "status3", header: "Chicago" },
  { key: "status4", header: "Houston" },
  { key: "status5", header: "Baltimore" },
];

export const rows = [
  {
    id: "1",
    service: "Oracle Cloud",
    status1: (
      <div style={{ height: "24px", width: "24px" }}>
        <div className="icon_maintence" style={{ backgroundColor: "#198038" }}>
          <CheckmarkOutline size={16} />
        </div>
      </div>
    ),
    status2: (
      <div style={{ height: "24px", width: "24px" }}>
        <div className="icon_maintence" style={{ backgroundColor: "#F1C21B" }}>
          <WarningAlt size={16} />
        </div>
      </div>
    ),
    status3: (
      <div style={{ height: "24px", width: "24px" }}>
        <div className="icon_maintence" style={{ backgroundColor: "#FF832B" }}>
          <WarningHex size={16} />
        </div>
      </div>
    ),
    status4: (
      <div style={{ height: "24px", width: "24px" }}>
        <div className="icon_maintence" style={{ backgroundColor: "#B81922" }}>
          <MisuseOutline size={16} />
        </div>
      </div>
    ),
    status5: (
      <div style={{ height: "24px", width: "24px" }}>
        <div className="icon_maintence" style={{ backgroundColor: "#0F62FE" }}>
          <Settings size={16} />
        </div>
      </div>
    ),
  },
  {
    id: "2",
    service: "Apis",
    status2: (
      <div style={{ height: "24px", width: "24px" }}>
        <div className="icon_maintence" style={{ backgroundColor: "#198038" }}>
          <CheckmarkOutline size={16} />
        </div>
      </div>
    ),
    status3: (
      <div style={{ height: "24px", width: "24px" }}>
        <div className="icon_maintence" style={{ backgroundColor: "#FF832B" }}>
          <WarningHex size={16} />
        </div>
      </div>
    ),
    status4: (
      <div style={{ height: "24px", width: "24px" }}>
        <div className="icon_maintence" style={{ backgroundColor: "#B81922" }}>
          <MisuseOutline size={16} />
        </div>
      </div>
    ),
    status5: (
      <div style={{ height: "24px", width: "24px" }}>
        <div className="icon_maintence" style={{ backgroundColor: "#0F62FE" }}>
          <Settings size={16} />
        </div>
      </div>
    ),
    status1: (
      <div style={{ height: "24px", width: "24px" }}>
        <div className="icon_maintence" style={{ backgroundColor: "#F1C21B" }}>
          <WarningAlt size={16} />
        </div>
      </div>
    ),
  },
  {
    id: "3",
    service: "Redhat OpenShift",
    status1: (
      <div style={{ height: "24px", width: "24px" }}>
        <div className="icon_maintence" style={{ backgroundColor: "#198038" }}>
          <CheckmarkOutline size={16} />
        </div>
      </div>
    ),
    status2: (
      <div style={{ height: "24px", width: "24px" }}>
        <div className="icon_maintence" style={{ backgroundColor: "#F1C21B" }}>
          <WarningAlt size={16} />
        </div>
      </div>
    ),
    status3: (
      <div style={{ height: "24px", width: "24px" }}>
        <div className="icon_maintence" style={{ backgroundColor: "#FF832B" }}>
          <WarningHex size={16} />
        </div>
      </div>
    ),
    status4: (
      <div style={{ height: "24px", width: "24px" }}>
        <div className="icon_maintence" style={{ backgroundColor: "#0F62FE" }}>
          <Settings size={16} />
        </div>
      </div>
    ),
    status5: (
      <div style={{ height: "24px", width: "24px" }}>
        <div className="icon_maintence" style={{ backgroundColor: "#B81922" }}>
          <MisuseOutline size={16} />
        </div>
      </div>
    ),
  },
  {
    id: "4",
    service: "Network Operations",
    status2: (
      <div style={{ height: "24px", width: "24px" }}>
        <div className="icon_maintence" style={{ backgroundColor: "#FF832B" }}>
          <WarningHex size={16} />
        </div>
      </div>
    ),
    status3: (
      <div style={{ height: "24px", width: "24px" }}>
        <div className="icon_maintence" style={{ backgroundColor: "#B81922" }}>
          <MisuseOutline size={16} />
        </div>
      </div>
    ),
    status4: (
      <div style={{ height: "24px", width: "24px" }}>
        <div className="icon_maintence" style={{ backgroundColor: "#198038" }}>
          <CheckmarkOutline size={16} />
        </div>
      </div>
    ),

    status5: (
      <div style={{ height: "24px", width: "24px" }}>
        <div className="icon_maintence" style={{ backgroundColor: "#F1C21B" }}>
          <WarningAlt size={16} />
        </div>
      </div>
    ),
    status1: (
      <div style={{ height: "24px", width: "24px" }}>
        <div className="icon_maintence" style={{ backgroundColor: "#0F62FE" }}>
          <Settings size={16} />
        </div>
      </div>
    ),
  },
  // ... other rows
];

export const IncidentDummyData = {
  America: { rows, headers },
  Europe: {
    rows: [
      {
        id: "1",
        service: "Oracle Cloud",
        status1: (
          <div style={{ height: "24px", width: "24px" }}>
            <div
              className="icon_maintence"
              style={{ backgroundColor: "#198038" }}
            >
              <CheckmarkOutline size={16} />
            </div>
          </div>
        ),
        status2: (
          <div style={{ height: "24px", width: "24px" }}>
            <div
              className="icon_maintence"
              style={{ backgroundColor: "#F1C21B" }}
            >
              <WarningAlt size={16} />
            </div>
          </div>
        ),
        status3: (
          <div style={{ height: "24px", width: "24px" }}>
            <div
              className="icon_maintence"
              style={{ backgroundColor: "#FF832B" }}
            >
              <WarningHex size={16} />
            </div>
          </div>
        ),
        status4: (
          <div style={{ height: "24px", width: "24px" }}>
            <div
              className="icon_maintence"
              style={{ backgroundColor: "#B81922" }}
            >
              <MisuseOutline size={16} />
            </div>
          </div>
        ),
        status5: (
          <div style={{ height: "24px", width: "24px" }}>
            <div
              className="icon_maintence"
              style={{ backgroundColor: "#0F62FE" }}
            >
              <Settings size={16} />
            </div>
          </div>
        ),
      },
      {
        id: "2",
        service: "Apis",
        status2: (
          <div style={{ height: "24px", width: "24px" }}>
            <div
              className="icon_maintence"
              style={{ backgroundColor: "#198038" }}
            >
              <CheckmarkOutline size={16} />
            </div>
          </div>
        ),
        status3: (
          <div style={{ height: "24px", width: "24px" }}>
            <div
              className="icon_maintence"
              style={{ backgroundColor: "#FF832B" }}
            >
              <WarningHex size={16} />
            </div>
          </div>
        ),
        status4: (
          <div style={{ height: "24px", width: "24px" }}>
            <div
              className="icon_maintence"
              style={{ backgroundColor: "#B81922" }}
            >
              <MisuseOutline size={16} />
            </div>
          </div>
        ),
        status5: (
          <div style={{ height: "24px", width: "24px" }}>
            <div
              className="icon_maintence"
              style={{ backgroundColor: "#0F62FE" }}
            >
              <Settings size={16} />
            </div>
          </div>
        ),
        status1: (
          <div style={{ height: "24px", width: "24px" }}>
            <div
              className="icon_maintence"
              style={{ backgroundColor: "#F1C21B" }}
            >
              <WarningAlt size={16} />
            </div>
          </div>
        ),
      },
      {
        id: "3",
        service: "Redhat OpenShift",
        status1: (
          <div style={{ height: "24px", width: "24px" }}>
            <div
              className="icon_maintence"
              style={{ backgroundColor: "#198038" }}
            >
              <CheckmarkOutline size={16} />
            </div>
          </div>
        ),
        status2: (
          <div style={{ height: "24px", width: "24px" }}>
            <div
              className="icon_maintence"
              style={{ backgroundColor: "#F1C21B" }}
            >
              <WarningAlt size={16} />
            </div>
          </div>
        ),
        status3: (
          <div style={{ height: "24px", width: "24px" }}>
            <div
              className="icon_maintence"
              style={{ backgroundColor: "#FF832B" }}
            >
              <WarningHex size={16} />
            </div>
          </div>
        ),
        status4: (
          <div style={{ height: "24px", width: "24px" }}>
            <div
              className="icon_maintence"
              style={{ backgroundColor: "#0F62FE" }}
            >
              <Settings size={16} />
            </div>
          </div>
        ),
        status5: (
          <div style={{ height: "24px", width: "24px" }}>
            <div
              className="icon_maintence"
              style={{ backgroundColor: "#B81922" }}
            >
              <MisuseOutline size={16} />
            </div>
          </div>
        ),
      },
      {
        id: "4",
        service: "Network Operations",
        status1: (
          <div style={{ height: "24px", width: "24px" }}>
            <div
              className="icon_maintence"
              style={{ backgroundColor: "#0F62FE" }}
            >
              <Settings size={16} />
            </div>
          </div>
        ),
        status5: (
          <div style={{ height: "24px", width: "24px" }}>
            <div
              className="icon_maintence"
              style={{ backgroundColor: "#F1C21B" }}
            >
              <WarningAlt size={16} />
            </div>
          </div>
        ),
        status3: (
          <div style={{ height: "24px", width: "24px" }}>
            <div
              className="icon_maintence"
              style={{ backgroundColor: "#B81922" }}
            >
              <MisuseOutline size={16} />
            </div>
          </div>
        ),
        status2: (
          <div style={{ height: "24px", width: "24px" }}>
            <div
              className="icon_maintence"
              style={{ backgroundColor: "#FF832B" }}
            >
              <WarningHex size={16} />
            </div>
          </div>
        ),
        status4: (
          <div style={{ height: "24px", width: "24px" }}>
            <div
              className="icon_maintence"
              style={{ backgroundColor: "#198038" }}
            >
              <CheckmarkOutline size={16} />
            </div>
          </div>
        ),
      },
    ],

    headers: [
      { key: "service", header: "Service" },
      { key: "status5", header: "Istanbul" },
      { key: "status4", header: "Barcelona" },
      { key: "status2", header: "Bordeaux" },
      { key: "status1", header: "Dubrovnik" },
      { key: "status3", header: "Liverpool" },
    ],
  },

  "Asia Pacific": {
    rows: [
      {
        id: "1",
        service: "Oracle Cloud",
        status1: (
          <div style={{ height: "24px", width: "24px" }}>
            <div
              className="icon_maintence"
              style={{ backgroundColor: "#198038" }}
            >
              <CheckmarkOutline size={16} />
            </div>
          </div>
        ),
        status2: (
          <div style={{ height: "24px", width: "24px" }}>
            <div
              className="icon_maintence"
              style={{ backgroundColor: "#F1C21B" }}
            >
              <WarningAlt size={16} />
            </div>
          </div>
        ),
        status3: (
          <div style={{ height: "24px", width: "24px" }}>
            <div
              className="icon_maintence"
              style={{ backgroundColor: "#FF832B" }}
            >
              <WarningHex size={16} />
            </div>
          </div>
        ),
        status4: (
          <div style={{ height: "24px", width: "24px" }}>
            <div
              className="icon_maintence"
              style={{ backgroundColor: "#B81922" }}
            >
              <MisuseOutline size={16} />
            </div>
          </div>
        ),
        status5: (
          <div style={{ height: "24px", width: "24px" }}>
            <div
              className="icon_maintence"
              style={{ backgroundColor: "#0F62FE" }}
            >
              <Settings size={16} />
            </div>
          </div>
        ),
      },
      {
        id: "2",
        service: "Apis",
        status2: (
          <div style={{ height: "24px", width: "24px" }}>
            <div
              className="icon_maintence"
              style={{ backgroundColor: "#198038" }}
            >
              <CheckmarkOutline size={16} />
            </div>
          </div>
        ),
        status3: (
          <div style={{ height: "24px", width: "24px" }}>
            <div
              className="icon_maintence"
              style={{ backgroundColor: "#FF832B" }}
            >
              <WarningHex size={16} />
            </div>
          </div>
        ),
        status4: (
          <div style={{ height: "24px", width: "24px" }}>
            <div
              className="icon_maintence"
              style={{ backgroundColor: "#B81922" }}
            >
              <MisuseOutline size={16} />
            </div>
          </div>
        ),
        status5: (
          <div style={{ height: "24px", width: "24px" }}>
            <div
              className="icon_maintence"
              style={{ backgroundColor: "#0F62FE" }}
            >
              <Settings size={16} />
            </div>
          </div>
        ),
        status1: (
          <div style={{ height: "24px", width: "24px" }}>
            <div
              className="icon_maintence"
              style={{ backgroundColor: "#F1C21B" }}
            >
              <WarningAlt size={16} />
            </div>
          </div>
        ),
      },
      {
        id: "3",
        service: "Redhat OpenShift",
        status1: (
          <div style={{ height: "24px", width: "24px" }}>
            <div
              className="icon_maintence"
              style={{ backgroundColor: "#198038" }}
            >
              <CheckmarkOutline size={16} />
            </div>
          </div>
        ),
        status2: (
          <div style={{ height: "24px", width: "24px" }}>
            <div
              className="icon_maintence"
              style={{ backgroundColor: "#F1C21B" }}
            >
              <WarningAlt size={16} />
            </div>
          </div>
        ),
        status3: (
          <div style={{ height: "24px", width: "24px" }}>
            <div
              className="icon_maintence"
              style={{ backgroundColor: "#FF832B" }}
            >
              <WarningHex size={16} />
            </div>
          </div>
        ),
        status4: (
          <div style={{ height: "24px", width: "24px" }}>
            <div
              className="icon_maintence"
              style={{ backgroundColor: "#0F62FE" }}
            >
              <Settings size={16} />
            </div>
          </div>
        ),
        status5: (
          <div style={{ height: "24px", width: "24px" }}>
            <div
              className="icon_maintence"
              style={{ backgroundColor: "#B81922" }}
            >
              <MisuseOutline size={16} />
            </div>
          </div>
        ),
      },
      {
        id: "4",
        service: "Network Operations",
        status5: (
          <div style={{ height: "24px", width: "24px" }}>
            <div
              className="icon_maintence"
              style={{ backgroundColor: "#F1C21B" }}
            >
              <WarningAlt size={16} />
            </div>
          </div>
        ),
        status1: (
          <div style={{ height: "24px", width: "24px" }}>
            <div
              className="icon_maintence"
              style={{ backgroundColor: "#0F62FE" }}
            >
              <Settings size={16} />
            </div>
          </div>
        ),

        status4: (
          <div style={{ height: "24px", width: "24px" }}>
            <div
              className="icon_maintence"
              style={{ backgroundColor: "#198038" }}
            >
              <CheckmarkOutline size={16} />
            </div>
          </div>
        ),
        status3: (
          <div style={{ height: "24px", width: "24px" }}>
            <div
              className="icon_maintence"
              style={{ backgroundColor: "#B81922" }}
            >
              <MisuseOutline size={16} />
            </div>
          </div>
        ),
        status2: (
          <div style={{ height: "24px", width: "24px" }}>
            <div
              className="icon_maintence"
              style={{ backgroundColor: "#FF832B" }}
            >
              <WarningHex size={16} />
            </div>
          </div>
        ),
      },
    ],

    headers: [
      { key: "service", header: "Service" },
      { key: "status1", header: "Kyoto" },
      { key: "status3", header: "Osaka" },
      { key: "status4", header: "Tokyo" },
      { key: "status5", header: "Shibuya" },
      { key: "status2", header: "Mumbai" },
    ],
  },
};
