import React, { useContext, useEffect, useState, useRef } from "react";
import {
  BorderBottom,
  BorderLeft,
  CaretDown,
  ChevronDown,
  Filter,
  NotificationOff,
  RequestQuote,
} from "@carbon/icons-react";
import {
  DataTable,
  TableContainer,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  Button,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  Pagination,
  DataTableSkeleton,
  FlexGrid,
  DatePickerInput,
  Form,
  DatePicker,
} from "@carbon/react";
import { Contextprovider } from "../../App";
import { useAccount, useMsal } from "@azure/msal-react";
import { Notificationwrapper } from "./NotificationStyle";
import moment from "moment";
import axiosInstance from "axiosintance";
import { msg_headerData5 } from "Component/Data/DataJson";
import { useSelector } from "react-redux";
import { memoize } from "proxy-memoize";
import CustomButton from "components/button/customButton";
import useWindowSize from "hooks/useWindowSize";
import ReactDOM from "react-dom";
import { contextTypes } from "react-addons-css-transition-group";
import { GlobalContext } from "ContextApi/GlobalContext";

const NotificationMessage = () => {
  const [filterBtn, setFilterBtn] = useState(false); // or your initial value
  const [Resetfilter, setResetfilter] = useState(false);
  const context = useContext(GlobalContext);
  const { width } = useWindowSize();
  const reduxNotification = useSelector(
    memoize((state) => state.notifications.unseenNotifications.data)
  );
  const [isDateValid, setIsDateValid] = useState({
    StartDatevalid: true,
    EndDateValid: true,
  });
  const [DateFormate, setDateFormate] = useState({
    StartDate: "",
    EndDate: "",
  });
  const [validationMessages, setValidationMessages] = useState({
    startDate: "",
    endDate: "",
  });
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const { useralldata, dispatch } = useContext(Contextprovider);
  const notificationData = useralldata?.UserallNotification?.data;
  const totalcount = useralldata?.UserallNotification?.count;
  const paginationRef = useRef();

  useEffect(() => {
    const pagination = paginationRef.current;

    if (pagination) {
      const icon = document.querySelector("cds--pagination__left");

      if (icon) {
        const spanTag = icon.querySelector(".cds--pagination__text");
        if (spanTag) {
          spanTag.textContent = "1–10 of 354 Notifications";
          ReactDOM.render(spanTag, icon);
        }
      }
    }
  }, []);

  useEffect(() => {
    let isValidStartDate = true;
    let isValidEndDate = true;
    let messages = { startDate: "", endDate: "" };
    const currentDate = new Date();
    if (DateFormate?.StartDate) {
      const startDate = new Date(DateFormate.StartDate);
      isValidStartDate = startDate <= currentDate;
      if (!isValidStartDate) {
        messages.startDate = "Start date must be on or before the current date";
      }
    }
    if (DateFormate?.EndDate) {
      const endDate = new Date(DateFormate.EndDate);
      isValidEndDate = endDate >= currentDate;
      if (!isValidEndDate) {
        messages.endDate = "End date must be on or after the current date";
      }
    }
    if (DateFormate?.EndDate) {
      const endDate = new Date(DateFormate.EndDate);
      isValidEndDate = endDate >= new Date(DateFormate.StartDate);
      if (!isValidEndDate) {
        messages.endDate = "End date must be on or after the Start date";
      }
    }

    setIsDateValid({
      StartDatevalid: isValidStartDate,
      EndDateValid: isValidEndDate,
    });
    setValidationMessages(messages);
  }, [DateFormate]);

  const handlePageChangedata = (pagenumber = { page: 1, pageSize: 10 }) => {
    axiosInstance
      .post(`${process.env.REACT_APP_NOTIFICATION_URL}/show_notification`, {
        userid: account?.idTokenClaims?.sub,
        platform: "uvation support",
        start_date: DateFormate?.StartDate,
        end_date: DateFormate?.EndDate,
        page_no: pagenumber.page,
        per_page: pagenumber.pageSize,
      })
      .then((res) => {
        dispatch({ type: "USER_All_NOTIFICATION", payload: res?.data });
        setFilterBtn(false);
      });
  };

  useEffect(() => {
    handlePageChangedata();
  }, [account, Resetfilter, reduxNotification]);

  return (
    <>
      <Notificationwrapper className="dark:!bg-[#161616]">
        <FlexGrid fullWidth>
          <div className="notification_content">
            <div className="headingdiv">
              <h1 className="dark:!text-white">Notifications</h1>
              <p className="dark:!text-white">
                Uvation Notifications keep you informed and in control with
                vital updates, real-time alerts, and custom notifications.
              </p>
            </div>
          </div>
        </FlexGrid>

        <FlexGrid fullWidth className="gridcolor dark:!bg-[#161616]">
          <div className="notificationdata_table">
            <div
              lg={16}
              style={{ boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.05)" }}
            >
              {notificationData?.length ? (
                <>
                  <DataTable
                    rows={notificationData}
                    headers={msg_headerData5}
                    isSortable
                  >
                    {({
                      rows,
                      headers,
                      getHeaderProps,
                      getRowProps,
                      getTableProps,
                      onInputChange,
                    }) => (
                      <TableContainer className="dark:!border-[#474747]">
                        <TableToolbar>
                          <TableToolbarContent>
                            {/* pass in `onInputChange` change here to make filtering work */}
                            <TableToolbarSearch
                              onChange={onInputChange}
                              expanded={true}
                              placeholder="Search "
                            />
                            <div className="filter_mainbtn">
                              <CustomButton
                                text=" Filter "
                                renderIcon={ChevronDown}
                                fullWidth
                                onClick={() =>
                                  setFilterBtn((prevState) => !prevState)
                                }
                                style={{
                                  backgroundColor:
                                    context.globalTheme == "white"
                                      ? "#fff"
                                      : "#262626",
                                  color:
                                    context.globalTheme == "white"
                                      ? "black"
                                      : "white",
                                  borderLeft:
                                    context.globalTheme == "white"
                                      ? "1px solid #E0E0E0"
                                      : "1px solid #474747",
                                  BorderBottom:
                                    context.globalTheme == "white"
                                      ? "1px solid #E0E0E0"
                                      : "1px solid #474747",
                                }}
                              />
                              {filterBtn && (
                                <Form>
                                  <div className="filterDateform dark:!bg-[#262626] dark:!border-[#474747]">
                                    <DatePicker
                                      dateFormat="m/d/Y"
                                      datePickerType="single"
                                      onInput={(e) =>
                                        setDateFormate({
                                          ...DateFormate,
                                          StartDate: e.target.value,
                                        })
                                      }
                                    >
                                      <DatePickerInput
                                        style={{ cursor: "pointer" }}
                                        className="dark:!bg-[#393939]"
                                        id="date-picker-default-id"
                                        placeholder="mm/dd/yyyy"
                                        labelText="Start date"
                                        type="text"
                                        invalid={
                                          !!DateFormate?.StartDate &&
                                          !isDateValid?.StartDatevalid
                                        } // Show as invalid if EndDate is set and isDateValid is false
                                        invalidText={
                                          validationMessages.startDate &&
                                          validationMessages.startDate
                                        }
                                        value={DateFormate?.StartDate}
                                      />
                                    </DatePicker>

                                    <DatePicker
                                      dateFormat="m/d/Y"
                                      style={{ cursor: "pointer" }}
                                      datePickerType="single"
                                      onInput={(e) =>
                                        setDateFormate({
                                          ...DateFormate,
                                          EndDate: e.target.value,
                                        })
                                      }
                                    >
                                      <DatePickerInput
                                        id="date-picker-default-id2"
                                        placeholder="mm/dd/yyyy"
                                        style={{ cursor: "pointer" }}
                                        labelText="End date"
                                        invalid={
                                          !!DateFormate?.EndDate &&
                                          !isDateValid?.EndDateValid
                                        }
                                        invalidText={
                                          validationMessages.endDate &&
                                          validationMessages.endDate
                                        }
                                        type="text"
                                        value={DateFormate?.EndDate}
                                      />
                                    </DatePicker>
                                    {/* {!isDateValid && (
                                    <span
                                      style={{ color: "red", fontSize: "12px" }}
                                    >
                                      End date must be after start date.
                                    </span>
                                  )} */}

                                    <div className="filterbtn_set">
                                      <Button
                                        kind="primary"
                                        onClick={() => {
                                          setResetfilter(true);
                                          setDateFormate({
                                            StartDate: "",
                                            EndDate: "",
                                          });
                                        }}
                                      >
                                        Reset
                                      </Button>

                                      <Button
                                        kind="secondary"
                                        onClick={handlePageChangedata}
                                      >
                                        Apply
                                      </Button>
                                    </div>
                                  </div>
                                </Form>
                              )}
                            </div>
                          </TableToolbarContent>
                        </TableToolbar>

                        {notificationData?.length > 0 ? (
                          <Table
                            {...getTableProps()}
                            aria-label="sample table"
                            className="notification_table"
                          >
                            <TableHead>
                              <TableRow className="dark:!bg-[#161616] ">
                                {headers.map((header) => (
                                  <>
                                    <TableHeader
                                      className="dark:!bg-[#161616]"
                                      key={header.key}
                                      {...getHeaderProps({
                                        header,
                                      })}
                                    >
                                      {header.header}
                                    </TableHeader>
                                  </>
                                ))}

                                <TableHeader className="dark:!bg-[#161616] dark:!text-[#fff]">
                                  Type
                                </TableHeader>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {rows.map((row, rowIndex) => (
                                <TableRow
                                  key={row.id}
                                  {...getRowProps({ row })}
                                >
                                  {row.cells.map((cell) => (
                                    <TableCell
                                      className="!bg-white dark:!bg-[#262626]"
                                      key={cell.id}
                                      style={{
                                        cursor: "pointer",
                                        padding: "16px ",
                                      }}
                                    >
                                      {notificationData[rowIndex].heading &&
                                        cell.info.header == "message" && (
                                          <div className="notification_heading">
                                            <h6>
                                              {
                                                notificationData[rowIndex]
                                                  .heading
                                              }
                                            </h6>
                                          </div>
                                        )}
                                      {cell.id.includes("notification_time") &&
                                      // && rows[rowIndex]?.notification_time
                                      rows[rowIndex]
                                        ? moment(cell.value).format(
                                            "MM/DD/YY, h:mm A"
                                          )
                                        : cell.value}
                                    </TableCell>
                                  ))}

                                  <TableCell className="!bg-white dark:!bg-[#262626] ">
                                    <div className="call_system  dark:!bg-[#5E96FF] dark:!text-white">
                                      System
                                    </div>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        ) : (
                          <div className="no_data">
                            <div className="request_quote_div">
                              <NotificationOff size={68} />
                            </div>
                            <h6>No Notifications</h6>
                            <span>
                              There are currently no new notifications.
                            </span>
                          </div>
                        )}
                      </TableContainer>
                    )}
                  </DataTable>
                  <Pagination
                    ref={paginationRef}
                    className="pagination dark:!bg-[#161616]"
                    backwardText="Previous page"
                    forwardText="Next page"
                    itemsPerPageText=""
                    itemText={`${parseInt(totalcount)} Notifications`}
                    page={1}
                    pageNumberText="Page Number"
                    pageSize={10}
                    pageSizes={[10, 20, 30, 40, 50]}
                    totalItems={parseInt(totalcount)}
                    onChange={handlePageChangedata}
                  />
                </>
              ) : (
                <DataTableSkeleton
                  headers={msg_headerData5}
                  aria-label="sample table"
                />
              )}
            </div>
          </div>
        </FlexGrid>
      </Notificationwrapper>
    </>
  );
};

export default NotificationMessage;
