import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "authConfig";

import { getErrorMessageAndCode } from "getErrorFromResponse";
import axiosInstance from "axiosintance";

export const getAllNotificationsService = async () => {
  const msalInstance = new PublicClientApplication(msalConfig);
  let azureId = msalInstance.getAllAccounts()[0]?.idTokenClaims?.sub;
  try {
    let { data } = await axiosInstance.post(
      process.env.REACT_APP_NOTIFICATION_URL + "/show_notification",
      {
        userid: azureId,
        platform: "uvation support",
        page_no: 1,
        per_page: 1,
      }
    );
    return data;
  } catch (err) {
    let error = getErrorMessageAndCode(err);
    throw error;
  }
};

export const getUnseenNotificationsService = async () => {
  const msalInstance = new PublicClientApplication(msalConfig);
  let azureId = msalInstance.getAllAccounts()[0]?.idTokenClaims?.sub;

  try {
    let { data } = await axiosInstance.post(
      process.env.REACT_APP_NOTIFICATION_URL + "/all_notifications",
      {
        userid: azureId,
        platform: "uvation support",
      }
    );
    return data.data;
  } catch (err) {
    const error = getErrorMessageAndCode(err);
    throw error;
  }
};

export const getDonotdistrubButton = async () => {
  const msalInstance = new PublicClientApplication(msalConfig);
  let azureId = msalInstance.getAllAccounts()[0]?.idTokenClaims?.sub;
  try {
    let { data } = await axiosInstance.post(
      process.env.REACT_APP_NOTIFICATION_URL + "/doNotDisturb",
      {
        userid: azureId,
      }
    );
    return data.data;
  } catch (err) {
    const error = getErrorMessageAndCode(err);
    throw error;
  }
};
