import { Modal, TileGroup, RadioTile, Layer } from "@carbon/react";
import React, { useContext } from "react";
import ModalWrapper from "./styles";
import { Asleep, Light } from "@carbon/icons-react";
import { useDispatch, useSelector } from "react-redux";
import { handleThemeModal, headerState } from "redux/slices/headerSlice";
import { useTheme } from "components/ThemeProvider";
import { GlobalContext } from "ContextApi/GlobalContext";
import { Contextprovider } from "App";

const ThemeModal = () => {
  const { themeModal } = useSelector(headerState);
  const dispatch = useDispatch();
  const { useralldata } = useContext(Contextprovider);
  const context = useContext(GlobalContext);

  const Darkthemsubmit = (e) => {
    //  dispatch(handleThemeModal(!themeModal));
    const theme = e === "white" ? "white" : "g100";

    context.socketdata.emit("changeTheme", {
      userid: useralldata?.userinfo.userid,
      theme: theme,
    });
    context.setGlobalTheme(theme);
  };
  return (
    <ModalWrapper>
      <Modal
        open={themeModal}
        className="theme-modal"
        size="sm"
        modalHeading="Change Theme"
        passiveModal
        onRequestClose={() => dispatch(handleThemeModal(false))}
      >
        <p className="dark:!text-white">
          Personalize the look and feel of the interface to suit your style.
          Change the UI theme to match your preferences.
        </p>{" "}
        <TileGroup
          defaultSelected={context.globalTheme || "white"}
          legend=""
          name="themeSelect"
          onChange={Darkthemsubmit}
        >
          <RadioTile
            id="theme-white"
            style={{
              marginBottom: ".5rem",
            }}
            value="white"
            className="tile"
            size="sm"
          >
            <Light size={20} />
            <span>Light</span>
          </RadioTile>
          <RadioTile
            size="sm"
            id="theme-dark"
            style={{
              marginBottom: ".5rem",
            }}
            value="g100"
            className="tile"
          >
            <Asleep size={20} />
            <span>Dark</span>
          </RadioTile>
        </TileGroup>
      </Modal>
    </ModalWrapper>
  );
};

export default ThemeModal;
