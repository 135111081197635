import axios from "axios";
import moment from "moment";
import { Tag } from "@carbon/react";
import { SupportServiceCard } from "./Component/SupportCenter/SupportCenter";
import axiosInstance from "./axiosintance";

export const Useraccesstoken = (dispatch) => {
  axiosInstance
    .post(`${process.env.REACT_APP_BACKEND_URL}/access_token`)
    .then((res) => {
      dispatch({ type: "USER_ACCESSTOKEN", payload: res });
    });
};
export const Signinlogsdata = (account, useralldata, dispatch) => {
  const headers = new Headers();
  const bearer = `Bearer ${useralldata.MsAccessToken?.data?.data?.access_token}`;
  headers.append("Authorization", bearer);
  var newData = {
    userid: useralldata?.userinfo?.userid,
    firstname: account?.idTokenClaims?.given_name,
    lastname: account?.idTokenClaims?.family_name,
  };
  axiosInstance
    .post(`${process.env.REACT_APP_BACKEND_URL}/hello`, newData)
    .then((res) => {
      dispatch({ type: "SIGN_IN_LOGS", payload: res?.data?.data?.user_info });
    });
};
export const countFetch = async (useralldata, dispatch) => {
  dispatch({ type: "USERS_ISLOADING", payload: true });

  const supporticket = new FormData();
  supporticket.append("email", useralldata?.userinfo?.email);
  supporticket.append("page", useralldata?.TablePagination?.page);
  supporticket.append("per_page", useralldata?.TablePagination.per_page);
  const responeall = await axiosInstance.post(
    `${process.env.REACT_APP_BACKENDURL_SUPPORT}/delay1`,
    supporticket
  );

  const supporticket1 = new FormData();
  supporticket1.append("email", useralldata?.userinfo?.email);
  supporticket1.append("widget_page", useralldata?.TablePagination.widget_page);
  supporticket1.append(
    "widget_per_page",
    useralldata?.TablePagination.widget_per_page
  );
  const responeallWidget = await axiosInstance.post(
    `${process.env.REACT_APP_BACKENDURL_SUPPORT}/delay2`,
    supporticket1
  );
  const supporticket2 = new FormData();
  supporticket2.append("email", useralldata?.userinfo?.email);
  const countOpen = await axiosInstance.post(
    `${process.env.REACT_APP_BACKENDURL_SUPPORT}/ticket_open`,
    supporticket2
  );
  const supporticket3 = new FormData();
  supporticket3.append("email", useralldata?.userinfo?.email);
  const countClose = await axiosInstance.post(
    `${process.env.REACT_APP_BACKENDURL_SUPPORT}/ticket_closed`,
    supporticket3
  );
  const supporticket4 = new FormData();
  supporticket4.append("email", useralldata?.userinfo?.email);
  const countNew = await axiosInstance.post(
    `${process.env.REACT_APP_BACKENDURL_SUPPORT}/ticket_new`,
    supporticket4
  );
  const supporticket5 = new FormData();
  supporticket5.append("email", useralldata?.userinfo?.email);
  const countPending = await axiosInstance.post(
    `${process.env.REACT_APP_BACKENDURL_SUPPORT}/ticket_pending`,
    supporticket5
  );
  dispatch({
    type: "TABLE_PAGINATION",
    payload: {
      totalrow: responeall?.data?.count,
    },
  });
  dispatch({
    type: "ALL_DATA",
    payload: responeall?.data?.results
      ?.filter((tic) =>
        useralldata?.Fillter.search
          ? tic.subject.toLowerCase().includes(useralldata?.Fillter.search) ||
            tic.id
              .toString()
              .toLowerCase()
              .includes(useralldata?.Fillter.search) ||
            moment(tic.created_at)
              .format("LLLL")
              .toLowerCase()
              .includes(useralldata?.Fillter.search)
          : useralldata?.Fillter.startDate == "" &&
            useralldata?.Fillter.endDate == ""
          ? moment(tic.created_at).format("L")
          : moment(tic.created_at).format("L") >=
              useralldata?.Fillter.startDate &&
            moment(tic.created_at).format("L") <= useralldata?.Fillter.endDate
      )
      .map((tic) => ({
        id: `${tic.id}`,
        date: moment(tic.created_at).format("LLLL"),
        subject: (
          <p
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: "18px",
            }}
          >
            <Tag type="cool-gray" title="Clear Filter">
              {tic.ticket_form_id == 360001970193
                ? "Technical Request"
                : "Customer Request"}
            </Tag>
            {tic.subject}
          </p>
        ),
        status: tic.status,
        datesubject: (
          <div>
            <p
              style={{
                fontSize: "14px",
                lineHeight: "18px",
                fontWeight: "500",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                color: "#383838",
                paddingRight: "0",
              }}
            >
              {tic.subject}
            </p>
            <p
              style={{
                width: "155px",
                fontSize: "12px",
                lineHeight: "15px",
                fontWeight: "300",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                color: "#383838",
                paddingRight: "0",
              }}
            >
              {moment(tic.created_at).format("llll")}
            </p>
          </div>
        ),
      })),
  });
  dispatch({
    type: "ALL_DATA_WIDTH",
    payload: responeallWidget?.data?.results?.map((tic) => ({
      id: `${tic.id}`,
      datesubject: (
        <div>
          <p
            style={{
              fontSize: "14px",
              lineHeight: "18px",
              fontWeight: "500",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              color: "#383838",
              paddingRight: "0",
            }}
          >
            {tic.subject}
          </p>
          <p
            style={{
              width: "130px",
              maxWidth: "155px",
              fontSize: "12px",
              lineHeight: "15px",
              fontWeight: "300",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              paddingRight: "0",
            }}
          >
            {moment(tic.created_at).format("llll")}
          </p>
        </div>
      ),
    })),
  });

  dispatch({
    type: "GLOBAL_STATE",
    payload: {
      pendingCount: countPending.data.count,
      closedCount: countClose.data.count,
      newCount: countNew.data.count,
      openCount: countOpen.data.count,
      fetchopen: countOpen.data.results
        ?.filter((tic) =>
          useralldata?.Fillter.search
            ? tic.subject.toLowerCase().includes(useralldata?.Fillter.search) ||
              tic.id
                .toString()
                .toLowerCase()
                .includes(useralldata?.Fillter.search) ||
              moment(tic.created_at)
                .format("LLLL")
                .toLowerCase()
                .includes(useralldata?.Fillter.search)
            : useralldata?.Fillter.startDate == "" &&
              useralldata?.Fillter.endDate == ""
            ? moment(tic.created_at).format("L")
            : moment(tic.created_at).format("L") >=
                useralldata?.Fillter.startDate &&
              moment(tic.created_at).format("L") <= useralldata?.Fillter.endDate
        )
        .map((tic) => (
          <SupportServiceCard
            key={tic.id}
            id={tic.id}
            servicename={
              tic.ticket_form_id == 360001970193
                ? "Technical Request"
                : "Customer Request"
            }
            serviceabout={tic.subject}
            created={moment(tic.created_at).format("LLLL")}
            onClick={
              () =>
                dispatch({ type: "USERS_ISLOADING_SETTICKET", payload: tic.id })

              // setticket_id(tic.id)
            }
          />
        )),
      fetchclosed: countClose.data.results
        ?.filter((tic) =>
          useralldata?.Fillter.search
            ? tic.subject.toLowerCase().includes(useralldata?.Fillter.search) ||
              tic.id
                .toString()
                .toLowerCase()
                .includes(useralldata?.Fillter.search) ||
              moment(tic.created_at)
                .format("LLLL")
                .toLowerCase()
                .includes(useralldata?.Fillter.search)
            : useralldata?.Fillter.startDate == "" &&
              useralldata?.Fillter.endDate == ""
            ? moment(tic.created_at).format("L")
            : moment(tic.created_at).format("L") >=
                useralldata?.Fillter.startDate &&
              moment(tic.created_at).format("L") <= useralldata?.Fillter.endDate
        )
        .map((tic) => (
          <SupportServiceCard
            key={tic.id}
            id={tic.id}
            servicename={
              tic.ticket_form_id == 360001970193
                ? "Technical Request"
                : "Customer Request"
            }
            serviceabout={tic.subject}
            created={moment(tic.created_at).format("LLLL")}
            onClick={
              () =>
                dispatch({ type: "USERS_ISLOADING_SETTICKET", payload: tic.id })

              //  setticket_id(tic.id)
            }
          />
        )),
      fetchpending: countPending.data.results
        ?.filter((tic) =>
          useralldata?.Fillter.search
            ? tic.subject.toLowerCase().includes(useralldata?.Fillter.search) ||
              tic.id
                .toString()
                .toLowerCase()
                .includes(useralldata?.Fillter.search) ||
              moment(tic.created_at)
                .format("LLLL")
                .toLowerCase()
                .includes(useralldata?.Fillter.search)
            : useralldata?.Fillter.startDate == "" &&
              useralldata?.Fillter.endDate == ""
            ? moment(tic.created_at).format("L")
            : moment(tic.created_at).format("L") >=
                useralldata?.Fillter.startDate &&
              moment(tic.created_at).format("L") <= useralldata?.Fillter.endDate
        )
        .map((tic) => (
          <SupportServiceCard
            key={tic.id}
            id={tic.id}
            servicename={
              tic.ticket_form_id == 360001970193
                ? "Technical Request"
                : "Customer Request"
            }
            serviceabout={tic.subject}
            created={moment(tic.created_at).format("LLLL")}
            onClick={
              () =>
                dispatch({ type: "USERS_ISLOADING_SETTICKET", payload: tic.id })

              // setticket_id(tic.id)
            }
          />
        )),
      fetchnew: countNew.data.results
        ?.filter((tic) =>
          useralldata?.Fillter.search
            ? tic.subject.toLowerCase().includes(useralldata?.Fillter.search) ||
              tic.id
                .toString()
                .toLowerCase()
                .includes(useralldata?.Fillter.search) ||
              moment(tic.created_at)
                .format("LLLL")
                .toLowerCase()
                .includes(useralldata?.Fillter.search)
            : useralldata?.Fillter.search
            ? tic.subject.toLowerCase().includes(useralldata?.Fillter.search) ||
              tic.id
                .toString()
                .toLowerCase()
                .includes(useralldata?.Fillter.search) ||
              moment(tic.created_at)
                .format("LLLL")
                .toLowerCase()
                .includes(useralldata?.Fillter.search)
            : useralldata?.Fillter.startDate == "" &&
              useralldata?.Fillter.endDate == ""
            ? moment(tic.created_at).format("L")
            : moment(tic.created_at).format("L") >=
                useralldata?.Fillter.startDate &&
              moment(tic.created_at).format("L") <= useralldata?.Fillter.endDate
        )
        .map((tic) => (
          <SupportServiceCard
            key={tic.id}
            id={tic.id}
            servicename={
              tic.ticket_form_id == 360001970193
                ? "Technical Request"
                : "Customer Request"
            }
            serviceabout={tic.subject}
            created={moment(tic.created_at).format("LLLL")}
            onClick={
              () =>
                dispatch({ type: "USERS_ISLOADING_SETTICKET", payload: tic.id })

              // setticket_id(tic.id)
            }
          />
        )),
      loading: false,
    },
  });
  dispatch({ type: "USERS_ISLOADING", payload: false });
};

export const user_signup = (accounts, account) => {
  let userdaat = {};

  const usernotification_token = localStorage.getItem("notification_token");

  const daass = accounts.map((value) => {
    if (value.idTokenClaims.auth_time) {
      userdaat.login = new Date(
        value.idTokenClaims.auth_time * 1000
      ).toUTCString();
    }

    if (value.idTokenClaims.ProfileEdit_DateTime) {
      userdaat.profile_edit_time = new Date(
        value.idTokenClaims.ProfileEdit_DateTime * 1000
      ).toUTCString();
    }
    if (value?.idTokenClaims?.isProfileEdited) {
      userdaat.isProfileEditeds = value?.idTokenClaims?.isProfileEdited;
    }
    if (value.idTokenClaims.PasswordResetDateTime) {
      userdaat.PasswordResetDateTimeu = new Date(
        value.idTokenClaims.PasswordResetDateTime * 1000
      ).toUTCString();
    }
    if (value.idTokenClaims.ChangeMFAphnNo_DateTime) {
      userdaat.ChangeMFAphnNo_DateTimeuser = new Date(
        value.idTokenClaims.ChangeMFAphnNo_DateTime * 1000
      ).toUTCString();
      if (value.idTokenClaims.ForgotPasswordDateTime) {
        userdaat.ForgotPasswordDateTime = new Date(
          value.idTokenClaims.ForgotPasswordDateTime * 1000
        ).toUTCString();
      }
      if (value.idTokenClaims.isForgotPassword) {
        userdaat.isForgotPasswords = value.idTokenClaims.isForgotPassword;
      }
    }

    return null;
  });

  if (userdaat) {
    axiosInstance
      .post(`${process.env.REACT_APP_NOTIFICATION_URL}/user_signup`, {
        userid: account?.idTokenClaims?.sub,
        email: account?.idTokenClaims?.email,
        login_time: userdaat?.login,
        forgot_password_time: userdaat?.ForgotPasswordDateTime,
        newuser: account?.idTokenClaims?.newUser,
        platform: "uvation support",
        forgot_password: userdaat.isForgotPasswords,
        token: usernotification_token,
      })
      .then((res) => {
        // userall_notification(account, dispatch);
      });
  }
};

export const userall_notification = (useralldata, dispatch) => {
  axiosInstance
    .post(`${process.env.REACT_APP_NOTIFICATION_URL}/all_notifications`, {
      userid: useralldata?.userinfo?.userid,
      platform: "uvation support",
    })
    .then((res) => {
      dispatch({ type: "USER_ALL_NOTIFICATIONAPI", payload: res?.data?.data });
    });
};
export const handlePageChangedata = (
  account,
  dispatch,
  pagenumber = { page: 1, pageSize: 10 }
) => {
  axiosInstance
    .post(`${process.env.REACT_APP_NOTIFICATION_URL}/show_notification`, {
      userid: account?.idTokenClaims?.sub,
      platform: "uvation support",
      page_no: pagenumber.page,
      per_page: pagenumber.pageSize,
    })
    .then((res) => {
      dispatch({ type: "USER_All_NOTIFICATION", payload: res?.data });
    });
};
export const salesforcetoken = (account, dispatch) => {
  // axiosInstance.post(`${process.env.REACT_APP_BACKEND_URL}/sftoken`).then((res) => {
  //   dispatch({ type: "USER_SALESFORCE_DATA", payload: res?.data });
  //   // createSalesforceContact(res.data);
  // });
};
export const user_action_notification = (
  useralldata,
  account,
  res,
  dispatch
) => {
  const usernotification_token = localStorage.getItem("notification_token");
  axiosInstance
    .post(`${process.env.REACT_APP_NOTIFICATION_URL}/action_notification`, {
      userid: account?.idTokenClaims?.sub,
      zendesk_id: res?.data?.audit?.ticket_id,
      zendesk_id_time: res?.data?.audit?.created_at,
      platform: "uvation support",
      token: usernotification_token,
    })
    .then((res) => {
      userall_notification(useralldata, dispatch);
    });
};

export const getContactinfo = (useralldata, dispatch) => {
  axios
    .get(
      `${useralldata?.fetchdata?.response?.instance_url}/services/apexrest/ContactInfo?azureId=${useralldata?.userinfo?.userid}`,
      {
        headers: {
          Authorization: `Bearer ${useralldata?.fetchdata?.response?.access_token}`,
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
    .then((res) => {
      if (res?.data?.status == "Success") {
        dispatch({ type: "GET_CONTACTINFO", payload: res?.data?.contactInfo });
        dispatch({
          type: "GET_SALESFORCEINFO",
          payload: res?.data?.companyInfo,
        });
        userprofile_Image(useralldata, res?.data?.contactInfo, dispatch);

        // SalesforceProfileimage(dispatch, res?.data?.contactInfo, useralldata);
      }
    });
};
export const userprofile_Image = (useralldata, response, dispatch) => {
  axios
    .get(
      `${useralldata?.fetchdata?.response?.instance_url}/services/apexrest/ProfileImage?contactId=${response?.recordId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${useralldata?.fetchdata?.response?.access_token}`,
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
    .then((res) => {
      dispatch({ type: "GET_USERIMAGE", payload: res });
      dispatch({
        type: "GET_BASE64PREFIX",
        payload: "data:image/png;base64,",
      });
      // setphoto(res);
      // setBase64Prefix("data:image/png;base64,");
    });
};
export const getstatusdata = (useralldata, dispatch) => {
  axiosInstance
    .post("https://notifications.uvation.com/api/getStatus", {
      userid: useralldata?.userinfo?.userid,
    })
    .then((res) => {
      dispatch({
        type: "USER_SYSTEM_NOTIFICATION_GETSTATUS",
        payload: res?.data,
      });
    });
};
export const authtoken = (account, dispatch) => {
  axios
    .post(`${process.env.REACT_APP_BACKEND_URL}/authToken`, {
      azure_id: account?.idTokenClaims?.sub,
    })
    .then((res) => {
      localStorage.setItem("authtoken", res?.data?.authToken);
    });
};
