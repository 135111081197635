import {
  Add,
  ArrowLeft,
  ArrowRight,
  Catalog,
  Education,
  IbmWatsonMachineLearning,
  Launch,
  SearchLocate,
  Time,
} from "@carbon/icons-react";
import {
  Accordion,
  AccordionItem,
  Column,
  FlexGrid,
  PaginationNav,
  Row,
} from "@carbon/react";
import CustomButton from "components/button/customButton";
import useWindowSize from "hooks/useWindowSize";
import { useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/virtual";

import TrainingWrapper from "./style";
import { useDispatch, useSelector } from "react-redux";
import { GetFaqDetails } from "redux/slices/Faq/FaqSlice";
import { GettAllDocuments } from "redux/slices/TraningGuidens/TrainingGuidensSlice";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
} from "swiper/modules";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { GlobalContext } from "ContextApi/GlobalContext";
import Slider from "react-slick";
import { useRef } from "react";
import { useLocation } from "react-router-dom";

const Training = () => {
  const { getAllDocs } = useSelector((state) => state.Document);
  const context = useContext(GlobalContext);
  const { getRecommendedData, getSingleRecommendedData } = useSelector(
    (state) => state.Articals
  );
  const { FaqData } = useSelector((state) => state.Faq);
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const swiperRef = useRef();
  const swiperRefKnow = useRef();
  const location = useLocation();

  useEffect(() => {
    // dispatch(GetFaqDetails());
    // dispatch(GettAllDocuments());
  }, [location]);

  return (
    <TrainingWrapper className="dark:!bg-[#262626] dark:!text-[#fff]">
      {/* <MobileHeaderNav/> */}
      <section className="header dark:!bg-[#161616]">
        <h4 className="!text-[#6F6F6F] dark:!text-[#fff]">support dashboard</h4>
        <h1>Training & Guides</h1>
      </section>
      <section className="top">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <div className="heading">
            <div className="icon_box dark:!bg-[#161616]">
              <IbmWatsonMachineLearning size={20} />
            </div>
            <h4>Training For Admins</h4>
          </div>
          <div style={{ padding: "12px 0" }}>
            <Link
              to={`/training/Training-For-admins`}
              style={{ display: "flex", gap: "10px", alignItems: "center" }}
            >
              View All
              <ArrowRight size={16} />
            </Link>
          </div>
        </div>
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y, Virtual]}
          pagination={{
            dynamicBullets: true,
            dynamicMainBullets: width > 768 ? 2 : 3,
          }}
          paginationDefaultColor={"white"}
          navigation={{ nextEl: "right_btn" }}
          spaceBetween={16}
          slidesPerView={width <= 768 ? 1 : 3}
          virtual
          onSwiper={(swiper) => {
            swiperRefKnow.current = swiper;
          }}
        >
          {width > 768
            ? getAllDocs?.data?.data?.data?.slice(0, 6).map((item, index) => {
                return (
                  <SwiperSlide>
                    <div className="card dark:!border-[#474747] dark:!bg-[#262626]">
                      <div className="group_icon">
                        <div className="image_box">
                          <img src={item?.image} alt="image" />
                        </div>
                        <div className="image_box2">
                          <img src={item?.image} alt="image" />
                        </div>
                      </div>
                      <h5>Training Document For Admins {item?.product_name}</h5>

                      <div className="card-content">
                        <p>
                          {item?.product_sdesc.length > 100
                            ? item?.product_sdesc.slice(0, 100) + "..."
                            : item?.product_sdesc}
                        </p>

                        <div className="time_google_content">
                          <span className="time">
                            <Time /> &nbsp;7 mins
                          </span>
                          <div className="google_conteten">
                            <ul>
                              Google<li>Data Analyst</li>
                              <li>Intermediate</li>
                            </ul>
                          </div>
                        </div>

                        <div className="card-footer">
                          <div>
                            <CustomButton
                              text="Launch"
                              icon={ArrowRight}
                              handleClick={() => {
                                navigate(
                                  `/singletraining/${item?.product_slug}`
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })
            : getAllDocs?.data?.data?.data?.slice(0, 3).map((item, index) => {
                return (
                  <SwiperSlide>
                    <div className="card dark:!border-[#474747] dark:!bg-[#262626]">
                      <div className="group_icon">
                        <div className="image_box">
                          <img src={item?.image} alt="image" />
                        </div>
                        <div className="image_box2">
                          <img src={item?.image} alt="image" />
                        </div>
                      </div>
                      <h5>Training Document For Admins {item?.product_name}</h5>

                      <div className="card-content">
                        <p>
                          {item?.product_sdesc.length > 100
                            ? item?.product_sdesc.slice(0, 100) + "..."
                            : item?.product_sdesc}
                        </p>

                        <div className="time_google_content">
                          <span className="time">
                            <Time /> &nbsp;7 mins
                          </span>
                          <div className="google_conteten">
                            <ul>
                              Google<li>Data Analyst</li>
                              <li>Intermediate</li>
                            </ul>
                          </div>
                        </div>

                        <div className="card-footer">
                          <div>
                            <CustomButton
                              text="Launch"
                              icon={ArrowRight}
                              handleClick={() => {
                                navigate(
                                  `/singletraining/${item?.product_slug}`
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
          <div className="relative flex justify-end">
            <div
              style={{
                justifyContent: "flex-end",
                display: "flex",
                gap: "8px",
                bottom: "2px",
                right: "0px",
              }}
            >
              <button style={{ cursor: "pointer", zIndex: 200 }}>
                <ArrowLeft
                  size={16}
                  onClick={() => swiperRefKnow.current.slidePrev()}
                />
              </button>

              <button style={{ cursor: "pointer", zIndex: 200 }}>
                <ArrowRight
                  size={16}
                  style={{ cursor: "pointer", zIndex: 20 }}
                  onClick={() => swiperRefKnow.current.slideNext()}
                />
              </button>
            </div>
          </div>
        </Swiper>
      </section>

      <FlexGrid
        fullWidth
        style={{ backgroundColor: "#f4f4f4" }}
        className="dark:!bg-[#161616]"
      >
        <section className="knowledge-base dark:!bg-[#161616]">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <div className="heading">
              <div className="icon_box">
                <Catalog size={24} />
              </div>
              <h4>Knowledge Base</h4>
            </div>

            <div style={{ padding: "12px 0" }}>
              <Link
                to={`/training/knowledge-base`}
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "flex-start",
                }}
              >
                View All
                <ArrowRight size={16} />
              </Link>
            </div>
          </div>

          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y, Virtual]}
            pagination={{
              dynamicBullets: true,
              dynamicMainBullets: width > 768 ? 2 : 3,
            }}
            paginationDefaultColor={"white"}
            navigation={{ nextEl: "right_btn" }}
            spaceBetween={16}
            slidesPerView={width <= 768 ? 1 : 3}
            virtual
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
            }}
          >
            {width > 768
              ? getRecommendedData?.data?.data?.data
                  ?.slice(0, 6)
                  .map((item) => {
                    return (
                      <div>
                        <SwiperSlide>
                          <div className="card dark:!bg-[#262626] dark:!border-[#474747]">
                            <div>
                              <div style={{ marginBottom: "16px" }}>
                                <div className="knowledgbase_image">
                                  <img src="/ai--trust.png" alt="" />
                                </div>
                              </div>
                              <h5>{item?.article_name}</h5>

                              <p>
                                {item?.article_sdesc.length > 100
                                  ? item?.article_sdesc.slice(0, 100) + "..."
                                  : item?.article_sdesc}
                              </p>
                            </div>
                            <Link
                              to={`/singletraining/${item.article_slug}`}
                              style={{
                                color:
                                  context.globalTheme == "white"
                                    ? "#2570FE"
                                    : "#77A6FF",
                              }}
                            >
                              <div className="google_admins">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "left",
                                  }}
                                >
                                  <span className="">
                                    <Time /> &nbsp;7 mins
                                  </span>
                                </div>
                                <ul
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                  }}
                                >
                                  Google<li>Administrator</li>
                                  <li>Beginner</li>
                                </ul>
                              </div>
                              <ArrowRight />
                            </Link>
                          </div>
                        </SwiperSlide>
                      </div>
                    );
                  })
              : getRecommendedData?.data?.data?.data
                  ?.slice(0, 3)
                  .map((item) => {
                    return (
                      <div>
                        <SwiperSlide>
                          <div className="card dark:!bg-[#262626] dark:!border-[#474747]">
                            <div>
                              <div style={{ marginBottom: "16px" }}>
                                <div className="knowledgbase_image">
                                  <img src="/ai--trust.png" alt="" />
                                </div>
                              </div>
                              <h5>{item?.article_name}</h5>

                              <p>
                                {item?.article_sdesc.length > 100
                                  ? item?.article_sdesc.slice(0, 100) + "..."
                                  : item?.article_sdesc}
                              </p>
                            </div>
                            <Link
                              to={`/singletraining/${item.article_slug}`}
                              style={{
                                color:
                                  context.globalTheme == "white"
                                    ? "#2570FE"
                                    : "#77A6FF",
                              }}
                            >
                              <div className="google_admins">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "left",
                                  }}
                                >
                                  <span className="">
                                    <Time /> &nbsp;7 mins
                                  </span>
                                </div>
                                <ul
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                  }}
                                >
                                  Google<li>Administrator</li>
                                  <li>Beginner</li>
                                </ul>
                              </div>
                              <ArrowRight />
                            </Link>
                          </div>
                        </SwiperSlide>
                      </div>
                    );
                  })}
            <div className="relative flex justify-end">
              <div
                style={{
                  justifyContent: "flex-end",
                  display: "flex",
                  gap: "8px",
                  right: "0px",
                }}
              >
                <button style={{ cursor: "pointer", zIndex: 200 }}>
                  <ArrowLeft
                    size={16}
                    onClick={() => swiperRef.current.slidePrev()}
                  />
                </button>

                <button style={{ cursor: "pointer", zIndex: 200 }}>
                  <ArrowRight
                    size={16}
                    onClick={() => swiperRef.current.slideNext()}
                  />
                </button>
              </div>
            </div>{" "}
          </Swiper>
        </section>
      </FlexGrid>

      <section className="faqs">
        <Row>
          <Column xlg={6} className="left-content z-10">
            <div className="heading">
              <div className="icon_box dark:!bg-[#161616]">
                <SearchLocate size={20} />
              </div>
              <h4>FAQ & Guides</h4>
            </div>
            <p>
              Browse through our comprehensive list of frequently asked
              questions to find quick solutions and valuable insights. If you
              can't find what you're looking for, don't worry—our dedicated
              support team is ready to assist you further.
            </p>
            <span
              className="dark:!text-[#75a0fe]"
              onClick={() => navigate("/training/Faq-Guide")}
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                margin: "10px 0",
                cursor: "pointer",
              }}
            >
              {" "}
              View More <ArrowRight size={16} />
            </span>
          </Column>

          <Column xlg={10} className="z-10">
            <Accordion
              className="dark:!text-white accordion"
              size="lg"
              style={{ overflowY: "hidden" }}
            >
              {FaqData?.data?.data?.$faqsData?.Faqs_data.map((item) => {
                return (
                  <>
                    <AccordionItem
                      title={item?.heading}
                      className="dark:!text-white "
                    >
                      {item.question_and_answer.map((item) => {
                        return (
                          <div className="faq_data dark:!text-white">
                            <h6 className="dark:!text-white">
                              {item?.question}
                            </h6>
                            <p className="dark:!text-white">{item?.answer}</p>
                          </div>
                        );
                      })}
                    </AccordionItem>
                  </>
                );
              })}
            </Accordion>
          </Column>
        </Row>
      </section>
    </TrainingWrapper>
  );
};

export default Training;
