const { default: styled } = require("styled-components");

const HomeWrapper = styled.main`
  /* background-color: #f4f4f4; */
  /* position: relative; */
  background: url("/requestbackgound.png"), #f4f4f4;
  background-position: top right 0;
  background-size: contain;
  background-repeat: no-repeat;

  @media screen and (max-width: 768px) {
    background: url("/mobilebackgroundgraphic.png"), #f4f4f4;
    background-position: top right 0;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .cds--accordion__content {
    padding-inline-end: 50px !important;
  }

  .cds--accordion__title {
    font-family: "IBM Plex Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.16px;
  }

  .cds--accordion *,
  .cds--accordion *::before,
  .cds--accordion *::after {
    font-family: "IBM Plex Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.16px;
  }

  --cds-link-primary: ${(props) =>
    props.theme.colorScheme === "white" ? "#2570FE" : "#77A6FF"};

  .header {
    padding: 32px 0;
    @media screen and (max-width: 768px) {
      padding: 32px 0 25px 0;
    }

    .cds--data-table.cds--data-table--sort th > .cds--table-header-label {
      line-height: 1;
      padding-inline-end: 1rem;
      padding-inline-start: 1rem;
      font-family: IBM Plex Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.16px;
    }

    h4 {
      font-family: IBM Plex Sans;
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0.32px;
      text-align: left;
      text-transform: uppercase;
      z-index: 1;
      color: ${(props) =>
        props.theme.colorScheme === "white" ? "#525252" : "#ffff"};
    }
    h1 {
      font-family: IBM Plex Sans;
      font-size: 28px;
      font-weight: 500;
      line-height: 36px;
      letter-spacing: 0px;
      z-index: 1;
      text-align: left;
      color: ${(props) =>
        props.theme.colorScheme === "white" ? "#000" : "#fff"};

      @media screen and (max-width: 768px) {
        line-height: 36px;
        padding-bottom: 8px;
      }
    }
    &::after {
      /* content: url("/requestbackgound.png");
      position: absolute;
      right: 0;
      z-index: 0;
      top: 0; */

      @media screen and (max-width: 768px) {
        /* content: url("/headerimagemobile.png"); */
        /* opacity: ${(props) =>
          props.theme.colorScheme === "white" ? 1 : 0.2}; */
      }
    }
  }
  .top {
    /* padding-top: 2rem; */
    padding-bottom: 17px;

    .cds--row {
      row-gap: 1rem;
      flex-wrap: wrap-reverse;
    }
    .start {
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .chat {
        padding: 1rem;
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 9px;
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05);

        background: url("/chat-box.png"),
          var(
            --cta-cta-background-04,
            linear-gradient(133deg, #164398 15.51%, #000 77.75%)
          );
        background-position: right top;
        background-repeat: no-repeat;
        background-size: cover;

        .heading {
          display: flex;
          align-items: center;
          gap: 8px;
          h4 {
            color: var(--Text-text-on-color, #fff);

            /* Fixed heading styles/heading-semi-05 */
            font-family: "IBM Plex Sans";
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px; /* 155.556% */
          }
          .icon_box {
            height: 40px;
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
              fill: white;
            }
          }
        }
        p {
          color: var(--Accessibility-Black, #000);

          font-family: Arial;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
          letter-spacing: 0.16px;
        }
        .button_row {
          display: flex;
          flex-direction: row;
          gap: 1rem;
          flex-wrap: wrap;
          margin-top: 20px;

          @media screen and (max-width: 320px) {
            flex-wrap: wrap;
          }

          @media screen and (max-width: 768px) {
            gap: 0.6rem;
          }

          .contact-buttons {
            flex: 1;
            z-index: 1;
          }
        }
      }
      .request {
        padding: 1rem;
        background: url("/chat-box.png"),
          var(
            --cta-cta-background-04,
            linear-gradient(133deg, #164398 15.51%, #000 77.75%)
          );
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05);
        background-position: right top;
        background-repeat: no-repeat;
        background-size: cover;

        .heading {
          display: flex;
          align-items: center;
          gap: 12px;
          margin-bottom: 1rem;
          .create_service_request {
            /* Fixed heading styles/heading-semi-05 */
            font-family: IBM Plex Sans;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px; /* 155.556% */

            @media screen and (max-width: 768px) {
              border: none;
              font-size: 18px;
            }
          }

          .icon_box {
            height: 40px;
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
              fill: white;
            }
          }
        }
      }
    }
    .ticket_request {
      /* border: ${(props) =>
        props.theme.colorScheme == "white"
          ? "1px solid var(--Border-border-subtle-00, #e0e0e0)"
          : "1px solid #474747"}; */
      min-height: 100%;
      position: relative;
      background-color: #fff;
      box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05);

      .view_all_request {
        @media screen and (max-width: 768px) {
          display: flex;
          justify-content: end;
          padding: 0 16px 16px;
          margin-left: 39px;
        }
        a {
          color: ${(props) =>
            props.theme.colorScheme === "white" ? "#2570FE" : "#77A6FF"};
        }
      }

      .tableHeader {
        display: flex;
        justify-content: space-between;
        padding: 1rem;
        /* border-bottom: 1px solid #717171; */
        align-items: center;
        flex-wrap: wrap;
      }

      .heading {
        display: flex;
        gap: 1rem;
        align-items: center;

        h4 {
          font-family: IBM Plex Sans;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 28px; /* 155.556% */
        }

        .icon_container {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0.5rem;
          /* background-color: ${(props) =>
            props.theme.colorScheme === "white" ? "#F4F4F4" : "#161616"};
          border-radius: 100%; */
        }
      }
      a {
        display: flex;
        gap: 10px;
        align-items: center;
        color: var(--Background-background-brand, #0f62fe);
        font-family: IBM Plex Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-decoration-line: underline;
        text-transform: capitalize;
      }
      .support-tab-content {
        padding: 0 1rem;

        .no_data {
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: space-between;
          /* align-items: center; */
          padding-top: 27px;
          padding-bottom: 119px;

          @media screen and (max-width: 768px) {
            padding-top: 62px;
            padding-bottom: 155px;
          }

          .request_quote_div {
            width: 56px;
            height: 56px;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 4px;
            background-color: ${(props) =>
              props.theme.colorScheme === "white" ? "#f4f4f4" : "#161616"};
            svg {
              color: #a8a8a8;
            }
          }
          h6 {
            color: ${(props) =>
              props.theme.colorScheme == "white" ? "#a8a8a8" : "#525252"};
            /* Fixed heading styles/heading-semi-06 */
            font-family: "IBM Plex Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
          }

          h2 {
            color: ${(props) =>
              props.theme.colorScheme == "white" ? "#d1d1d1" : "#525252"};
            font-family: Arial;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.16px;
            margin-top: 4px;
          }
        }

        tbody {
          tr {
            td {
              @media screen and (max-width: 768px) {
                border-block-end: none !important;
                border-block-start: ${(props) =>
                  props.theme.colorScheme == "white"
                    ? "1px solid #c6c6c6"
                    : "1px solid #474747"};
              }
            }
          }
        }

        .tickets-list-container {
          /* display: flex; */
          .tickets-list-item {
            &:not(:first-child + .tickets-list-item):not(:last-child) {
              border-bottom: 1px solid
                ${(props) =>
                  props.theme.colorScheme === "white" ? "#e0e0e0" : "#474747"};
            }

            padding: 0.5rem 0;
            display: flex;
            justify-content: space-between;

            .title {
              color: ${(props) =>
                props.theme.colorScheme === "white" ? "#0f62fe" : "#77A6FF"};

              font-family: "IBM Plex Sans";
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 142.857% */
              letter-spacing: 0.16px;
              text-decoration-line: underline;
            }

            .desc {
              color: #6f6f6f;

              font-family: "IBM Plex Sans";
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px; /* 133.333% */
              letter-spacing: 0.32px;
            }
          }
        }
      }
    }
  }
  .training_and_guides {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
    position: relative;
    overflow: hidden;
    z-index: 2;
    border: ${(props) =>
      props.theme.colorScheme == "white"
        ? "1px solid var(--Border-border-subtle-00, #e0e0e0)"
        : "1px solid #474747"};
    background-repeat: no-repeat;
    background-position: right bottom;
    background-image: url("/spring.png");
    background-blend-mode: soft-light;

    .heading {
      padding: 18px;
      background-color: #f4f4f4;
      gap: 1rem;
      align-items: center;
      display: flex;

      h4 {
        font-weight: 600;
        font-family: IBM Plex Sans;
        font-size: 18px;
        font-style: normal;
        line-height: 28px;
      }
    }
    .training_guide_table {
      overflow-x: scroll;
    }

    .box {
      display: flex;
      position: inherit;
      gap: 1rem;
      padding: 1rem;
      align-items: center;
      border-bottom: 1px solid #e0e0e0;
      justify-content: space-between;
      background-color: white;
      z-index: 1;
      .content {
        display: flex;
        align-items: center;
        gap: 1rem;
        .icon {
          width: 40px;
          min-width: 40px;
          height: 40px;
          background-color: #f4f4f4;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
        }
        h5 {
          font-family: IBM Plex Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 18px; /* 128.571% */
          letter-spacing: 0.16px;
        }
        p {
          font-family: Arial;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
          letter-spacing: 0.16px;
        }
      }
    }
  }

  .middle {
    .cds--row {
      row-gap: 16px;
    }

    .help_data {
      max-height: 900px;
      overflow-y: scroll;
      @media screen and (max-width: 768px) {
        padding-bottom: 34%;
      }
      .html_markdown {
        color: ${(props) =>
          props.theme.colorScheme == "white" ? "#161616" : "#fff"};
        font-family: Arial;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.16px;
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          color: ${(props) =>
            props.theme.colorScheme == "white" ? "#161616" : "#fff"};
          font-family: "IBM Plex Sans";
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px;
          letter-spacing: 0.16px;
          text-transform: capitalize;
        }

        li,
        p {
          color: ${(props) =>
            props.theme.colorScheme == "white" ? "#161616" : "#fff"};
          font-family: Arial;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.16px;
        }
      }

      .main_header {
        display: flex;
        gap: 16px;
        flex-direction: column;
        padding-bottom: 16px;
        .back {
          display: flex;
          align-items: center;
          gap: 16px;
        }
      }

      hr {
        color: ${(props) =>
          props.theme.colorScheme == "white" ? "#e0e0e0" : "#474747"};
      }

      .help_content {
        max-height: 1000px;
        overflow-y: scroll;
        h5 {
          font-family: "IBM Plex Sans";
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px; /* 142.857% */
          letter-spacing: 0.16px;
        }
        h6 {
          font-family: Arial;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
          letter-spacing: 0.16px;
        }

        .single_div {
          padding: 16px 0;
        }
      }
    }

    .article-insider {
      max-height: 576px;
      min-height: 576px;
      overflow-y: auto;
      height: 100%;
      border: 1px solid var(--border-border-subtle-01, #c6c6c6);
      padding: 1rem 1.5rem;

      li.cds--side-nav__divider {
        margin-left: 0;
        margin-right: 0;
      }

      button.back-button {
        display: flex;
        gap: 1rem;
        align-items: center;
        color: var(--link-link-primary, var(--link-link-primary, #77a6ff));
        font-family: IBM Plex Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 137.5% */
        margin-bottom: 1rem;
      }

      .title {
        padding: 1rem 0 2rem 0;

        h3 {
          color: #161616;
          font-family: IBM Plex Sans;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        p {
          color: var(--accessibility-black, #000);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 153.846% */
          letter-spacing: -0.013px;
        }
      }

      .steps {
        h3 {
          color: #161616;
          font-family: IBM Plex Sans;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        ul {
          padding: 1rem 0;

          li {
            a {
              color: var(
                --miscellaneous-interactive,
                var(--miscellaneous-interactive, #0f62fe)
              );
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px; /* 171.429% */
              letter-spacing: -0.014px;
              text-decoration-line: underline;
            }
          }
        }
      }

      .steps-container {
        display: flex;
        flex-direction: column;
        padding: 1rem 0;
        gap: 2rem;

        .steps-content {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          h3 {
            color: #161616;
            font-family: IBM Plex Sans;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }

          p.desc {
            color: var(--accessibility-black, #000);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 153.846% */
            letter-spacing: -0.013px;
          }

          h5 {
            color: #161616;
            font-family: IBM Plex Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }

          p {
            color: var(--accessibility-black, #000);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 153.846% */
            letter-spacing: -0.013px;
          }

          .icon-content-box {
            display: flex;
            gap: 0.5rem;
            padding: 1rem;
            background: var(--field, #f4f4f4);
            flex-direction: column;

            h5 {
              color: #161616;
              font-family: IBM Plex Sans;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
            }

            p {
              color: var(--accessibility-black, #000);
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 153.846% */
              letter-spacing: -0.013px;
            }
          }
        }
      }
    }

    .recommended_articles {
      max-height: 576px;
      min-height: 576px;
      overflow-y: scroll;
      height: 100%;
      border: 1px solid var(--border-border-subtle-01, #e0e0e0);

      .heading {
        padding: 1rem;
        display: flex;
        align-items: center;
        gap: 0.65rem;

        .icon_box {
          background-color: #f4f4f4;
          height: 48px;
          width: 48px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
        }

        h4 {
          font-family: "IBM Plex Sans";
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 28px;
        }
      }

      .content-body {
        .article-row {
          margin: 0 1rem;
          padding: 1rem 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid var(--border-border-subtle-01, #c6c6c6);
          cursor: pointer;
          &:hover {
            color: #2570fe;
          }
          &:last-child {
            border: unset;
          }

          h5 {
            font-family: IBM Plex Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px;
            letter-spacing: 0.16px;
          }

          p {
            font-family: Arial;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: -0.013px;
          }
        }

        .footer {
          padding-bottom: 1rem;

          h5 {
            margin: 4rem 1.5rem 1rem 1.5rem;
            font-family: IBM Plex Sans;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
          }

          .footer-body {
            .article-row {
              margin: 0 1.5rem 0 1.5rem;
              padding: 0.5rem 0;
              display: flex;
              align-items: center;
              justify-content: space-between;
              border-bottom: 1px solid var(--border-border-subtle-01, #c6c6c6);

              h6 {
                font-family: IBM Plex Sans;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 22px;
              }

              p {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px; /* 153.846% */
                letter-spacing: -0.013px;
              }
            }
          }
        }
      }
    }
  }
  .service_request_status {
    display: flex;
    flex-direction: column;
    gap: 1px;
    padding-bottom: 39px;

    .cds--row {
      row-gap: 8px;
    }
  }
  .custom_hr_1 {
    margin: 0 2.5rem;
    color: #d1d9e2;
  }

  .aidata_faq {
    display: flex;
    flex-direction: column;
    gap: 40px;

    @media screen and (max-width: 768px) {
      padding-bottom: 140px;
    }

    .faq_aiheader {
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-right: 21px;
      @media screen and (max-width: 768px) {
        padding-right: 0px;
      }

      .search_heading {
        display: flex;
        gap: 10px;
        align-items: center;
      }

      .faq_header_link {
        @media screen and (max-width: 768px) {
          margin-left: 56px;
        }

        svg {
          fill: ${(props) =>
            props.theme.colorScheme === "white" ? "#2570FE" : "#77A6FF"};
        }
      }

      .faqicon {
        width: 48px;
        height: 48px;
        flex-shrink: 0;
        background-color: #f4f4f4;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
      }
      h6 {
        color: var(--Accessibility-Black, #000);

        /* Fixed heading styles/heading-semi-05 */
        font-family: "IBM Plex Sans";
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px; /* 155.556% */
      }
      p {
        color: var(--Accessibility-Black, #000);

        /* Body styles/body-01 */
        font-family: Arial;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        letter-spacing: 0.16px;
      }
    }
    .Product_section {
      .active {
        background: var(
          --Background-background-hover,
          rgba(141, 141, 141, 0.12)
        );
        border: 2px solid #0f62fe;
        height: 100%;
        .single_producticon {
          padding: 16px 16px 48px;
          display: flex;
          min-height: 100%;

          .single_icon {
            img {
              width: 50px;
              height: 50px;
            }

            svg {
              width: 50px;
              height: 50px;
            }
          }

          .single_icon_others {
            background: ${(props) =>
              props.theme.colorScheme == "white" ? " #f4f4f4 " : "#161616"};
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 48px;
            height: 48px;

            .pictograms {
              width: 24px;
              height: 24px;
              fill: ${(props) =>
                props.theme.colorScheme == "white" ? " black" : "#f4f4f4"};
            }
          }

          h5 {
            color: var(--Accessibility-Black, #000);
            font-family: "IBM Plex Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px; /* 128.571% */
            letter-spacing: 0.16px;
            text-align: left;
          }
          p {
            color: var(--Text-text-secondary, #525252);
            font-family: Arial;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
            letter-spacing: 0.16px;
            text-align: left;
            max-width: 279px;
            min-height: 60px;
          }
        }
      }
      .card-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
        padding-right: 21px;

        @media screen and (max-width: 66rem) {
          grid-template-columns: repeat(2, 1fr);
          padding-right: 0px;
        }

        @media screen and (max-width: 53rem) {
          grid-template-columns: repeat(1, 1fr);
          padding-right: 0px;
        }
      }

      h6 {
        color: var(--Accessibility-Black, #000);

        /* Fixed heading styles/heading-semi-05 */
        font-family: "IBM Plex Sans";
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px; /* 155.556% */
      }
      .single_produccolumn {
        height: 100%;
        .single_producticon {
          padding: 16px 16px 48px;
          display: flex;
          background-color: ${(props) =>
            props.theme.colorScheme == "white" ? "white" : "#262626"};
          min-height: 100%;
          box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05);

          .single_icon {
            img {
              width: 50px;
              height: 50px;
            }
            svg {
              width: 24px;
              height: 24px;
            }
          }

          .single_icon_others {
            background: ${(props) =>
              props.theme.colorScheme == "white" ? " #f4f4f4 " : "#161616"};
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 48px;
            height: 48px;

            .pictograms {
              width: 24px;
              height: 24px;
              fill: ${(props) =>
                props.theme.colorScheme == "white" ? " black" : "#f4f4f4"};
            }
          }

          h5 {
            color: var(--Accessibility-Black, #000);
            font-family: "IBM Plex Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px; /* 128.571% */
            letter-spacing: 0.16px;
            text-align: left;
          }
          p {
            color: var(--Text-text-secondary, #525252);
            font-family: Arial;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
            letter-spacing: 0.16px;
            text-align: left;
            max-width: 279px;
            min-height: 60px;
          }
        }
      }
    }
  }
  .single_faqPage {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .faq_mainheading {
      display: flex;
      align-items: center;
      gap: 12px;
      .faq_singleicon {
        background: ${(props) =>
          props.theme.colorScheme == "white"
            ? "linear-gradient(135deg, #f4f4f4 50%, #e0e0e0 50%)"
            : "linear-gradient(135deg,#474747 50%, #161616 50%)"};
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;

        img {
          width: 24px;
          height: 24px;
        }
      }
      h5 {
        color: var(--Accessibility-Black, #000);

        /* Fixed heading styles/heading-semi-05 */
        font-family: "IBM Plex Sans";
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px; /* 155.556% */
      }
    }
  }
`;
export default HomeWrapper;
