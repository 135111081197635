import axiosInstance from "axiosintance";
import axios from "axios";

async function getServiceList() {
  return await axiosInstance
    .get("https://devapi.uvation.com/usp/admin/api/list_combined")
    .then((res) => {
      res.data;
    });
}

/**
 * @param {string} service
 */
async function getServiceIssue(service) {
  return await axiosInstance
    .post("https://devapi.uvation.com/chatbot/chatbot/getFaqs", {
      services: [service],
    })
    .then((res) => res.data.faqs[service]);
}

/**
 * @param {string} prompt
 */
async function getFaq(prompt) {
  return await axiosInstance
    .post("https://devapi.uvation.com/chatbot/chatbot/chat", {
      message: prompt,
    })
    .then((res) => {
      res.data;
    });
}

async function getTotalFaqData(keyword) {
  const containsWhitespace = (str) => /\s/.test(str);

  let lowerCaseSlug = containsWhitespace(keyword)
    ? keyword?.split(" ").join("-").toLowerCase()
    : keyword.toLowerCase();
  const formData = new FormData();
  formData.append("method", "chatbot_faqs_single");
  formData.append("post_name", lowerCaseSlug);
  try {
    const response = await axios.post(
      process.env.REACT_APP_WORDPRESS_URL,
      formData
    );

    return response;
  } catch (error) {
    throw error;
  }
}

/**
 * @param {string} prompt
 */
async function getChat(prompt) {
  return await axiosInstance
    .post("https://devapi.uvation.com/chatbot/chatbot/botResponse", {
      message: prompt,
    })
    .then((res) => res.data);
}

export const ChatbotService = Object.freeze({
  getServiceList,
  getServiceIssue,
  getFaq,
  getChat,
  getTotalFaqData,
});
