import axiosInstance from "axiosintance";
import _ from "lodash";
import moment from "moment";

const getSupportTickets = async ({ email, pageNumber, values }) => {
  try {
    let { data } = await axiosInstance.post(
      `${process.env.REACT_APP_BACKENDURL_SUPPORT}/get_user_ticket`,
      {
        StartDate: values?.StartDate && values?.StartDate,
        status: values?.status && values?.status,
        severity: values?.severity && values?.severity,
        EndDate: values?.EndDate && values?.EndDate,
        castype: values?.castype && values?.castype,
        email: !values && email,
        page_no: pageNumber?.page || 1,
        per_page: pageNumber?.pageSize || 10,
      }
    );
    return {
      tickets: data.data.results.map((tic) => {
        return {
          id: tic.id.toString(),
          status: tic.status,
          servity: tic?.fields?.[9]?.value.split(" Impact")[0] + " Impact",
          subject:
            tic.subject.length > 20
              ? tic.subject.slice(0, 20) + "..."
              : tic.subject,
          casetype:
            tic?.ticket_form_id == "360001970193" ? "Technical" : "Customer",
          created_at: moment(tic.created_at).format("MM/DD/YY"),
        };
      }),
      totalRows: data.data?.count || 0,
    };
  } catch (err) {
    console.log(err, "errrrr");
    throw _.get(err, "message");
  }
};

export default { getSupportTickets };
