const { default: styled } = require("styled-components");

const RequestWrapper = styled.main`
  /* background-color: #f4f4f4; */
  background: url("/requestbackgound.png"), #f4f4f4;
  background-position: right top;
  background-repeat: no-repeat;
  background-size: contain;
  height: 95vh;

  @media screen and (max-width: 768px) {
    background: url("/mobilebackgroundgraphic.png"), #f4f4f4;
    background-position: top right 0;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .cds--pagination {
    background-color: ${(props) =>
      props.theme.colorScheme === "white" ? "#fff" : "#262626"};
  }
  .cds--toolbar-search-container-active.cds--search {
    background-color: ${(props) =>
      props.theme.colorScheme === "white" ? "#fff" : "#262626"};
  }

  .header {
    padding: 32px 0;

    nav {
      z-index: 2;

      .cds--breadcrumb {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 8px;
        /* line-height: 30px; */

        .cds--link {
          color: ${(props) =>
            props.theme.colorScheme === "white" ? "#2570FE" : "#77A6FF"};
        }
      }
    }

    h1 {
      font-family: IBM Plex Sans;
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      line-height: 36px;
      z-index: 2;
      color: ${(props) =>
        props.theme.colorScheme === "white" ? "#000" : "#fff"};
    }

    &::after {
      /* content: url("/requestbackgound.png");
      position: absolute;
      right: 0;
      top: 0px; */
      @media screen and (max-width: 479px) {
        /* content: url("/requestmobileheaderimage.png"); */
      }
      @media screen and (min-width: 480px) and (max-width: 768px) {
        /* content: url("/headerimagemobile.png"); */
      }
    }
  }
  .content {
    padding: 2rem 0;
    .para {
      color: var(--Text-text-primary, #161616);
      font-family: Arial;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      letter-spacing: 0.13px;
    }

    .subheading {
      margin-bottom: 32px;

      @media screen and (max-width: 768px) {
        flex-direction: column;
        gap: 32px;
      }
      .button_box {
        display: flex;
        justify-content: end;

        .cds--btn {
          width: 100%;
        }

        @media screen and (max-width: 768px) {
          justify-content: start;
        }
      }
    }
  }
  .filterDateform {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    max-height: max-content;
  }
  .filterpanel {
    max-height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .support-tab-content {
    /* border: 1px solid #c6c6c6; */
    padding-top: 32px;
    overflow-x: auto;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05);

    .table_row {
      background-color: #fff;
      cursor: pointer;
    }

    .table_row:hover {
      border: 2px solid #2570fe;
      background-color: ${(props) =>
        props.theme.colorScheme == "white" ? "#e0e0e0" : "#474747"};
    }

    .table_column:hover {
      border-top: 2px solid #2570fe;
      border-bottom: 2px solid #2570fe;
      background-color: ${(props) =>
        props.theme.colorScheme == "white" ? "#e0e0e0" : "#474747"};
    }

    .active {
      border: 1px solid #2570fe;
      background-color: ${(props) =>
        props.theme.colorScheme == "white" ? "#a8a8a8" : "#393939"};
    }

    @media screen and (max-width: 768px) {
      .cds--data-table-container {
        .cds--toolbar-content {
          display: block;
          .filter_mainbtn {
            width: 100%;
            .cds--btn--primary {
              min-width: 100%;
            }
          }
        }
      }
    }
  }

  .filter_container {
    .cds--multi-select {
      max-height: 100%;
      height: 100%;
      border: 1px solid #c6c6c6;
    }
  }

  .no_data {
    display: flex;
    flex-direction: column;
    height: 100%;
    /* align-items: center; */
    margin-top: 87px;
    margin-bottom: 171px;
    padding-left: 120px;
    .request_quote_div {
      width: 56px;
      height: 56px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 4px;
      background-color: ${(props) =>
        props.theme.colorScheme === "white" ? "#f4f4f4" : "#161616"};
      svg {
        color: #a8a8a8;
      }
    }
    h6 {
      color: ${(props) =>
        props.theme.colorScheme == "white" ? "#a8a8a8" : "#525252"};

      /* Fixed heading styles/heading-semi-03 */
      font-family: "IBM Plex Sans";
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: 35px; /* 159.091% */
    }

    span {
      color: ${(props) =>
        props.theme.colorScheme == "white" ? "#d1d1d1" : "#525252"};

      /* Fixed heading styles/heading-reg-07 */
      font-family: "IBM Plex Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 128.571% */
      letter-spacing: 0.16px;
      margin-top: 4px;
    }
  }
`;
export default RequestWrapper;
