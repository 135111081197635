import styled from "styled-components";
const TrainingWrapper = styled.main`
  background-image: url("/UV-tech-pattern.png");
  background-repeat: no-repeat;
  background-position: bottom left;

  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    position: unset;
  }

  .swiper-horizontal
    > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
  .swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    left: 0;
    transform: translateX(0%);
  }

  .swiper-pagination-bullet.swiper-pagination-bullet-active-next,
  .swiper-pagination-bullet.swiper-pagination-bullet-active-prev,
  .swiper-pagination-bullet.swiper-pagination-bullet-active-prev-prev,
  .swiper-pagination-bullet.swiper-pagination-bullet-active-next-next {
    display: none;
  }

  @media screen and (max-width: 768px) {
    background-image: url("/UV-tech-pattern-faq.png");
  }

  .header {
    padding: 32px;
    background-color: #f4f4f4;
    display: flex;
    flex-direction: column;
    position: relative;

    h4 {
      font-family: IBM Plex Sans;
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0.32px;
      text-align: left;
      font-style: normal;
      text-transform: uppercase;
      z-index: 1;
    }
    h1 {
      font-family: IBM Plex Sans;
      font-size: 28px;
      font-weight: 500;
      line-height: 37px;
      font-style: normal;
      letter-spacing: 0px;
      z-index: 1;
      text-align: left;
      color: ${(props) =>
        props.theme.colorScheme === "white" ? "#000" : "#fff"};
    }
    &::after {
      content: url("/headerbackground.png");
      position: absolute;
      right: 0;
      z-index: 0;
      top: 0;

      @media screen and (max-width: 768px) {
        content: url("/headerimagemobile.png");
      }
    }
  }
  .top {
    padding: 38px 41px 38px 46px;

    @media screen and (max-width: 768px) {
      padding: 1rem 1rem;
    }
    .heading {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-bottom: 1rem;
      .icon_box {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f4f4f4;
        border-radius: 50%;
        width: 40px;
        min-width: 40px;
        height: 40px;
        min-height: 40px;
      }
      h4 {
        font-family: IBM Plex Sans;
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
      }
    }
    .card {
      .image_box2 {
        background: #f4f4f4;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        img {
          width: 24px;
          height: 24px;
        }
      }
      padding: 16px;
      border: 1px solid var(--Border-border-subtle-00, #e0e0e0);
      margin-bottom: 16px;
      display: flex;
      gap: 10px;
      flex-direction: column;
      .group_icon {
        display: flex;
        align-items: center;
        gap: 16px;
        .image_box {
          background: linear-gradient(135deg, #f4f4f4 50%, #e0e0e0 50%);
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 48px;
          height: 48px;

          img {
            width: 24px;
            height: 24px;
          }
        }
      }
      h5 {
        font-family: IBM Plex Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0.16px;
      }
      .card-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        gap: 24px;

        .time_google_content {
          display: flex;
          flex-direction: column;
          gap: 12px;
        }

        p {
          font-size: 14px;
          font-family: IBM Plex Sans;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;
        }
        .time {
          color: var(
            --layer-layer-selected-disabled,
            var(--Layer-layer-selected-disabled, #8d8d8d)
          );

          /* Subhead lg - IBM Plex Sans */
          font-family: "IBM Plex Sans";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 17px; /* 141.667% */
          letter-spacing: 0.32px;
        }
        .google_conteten {
          ul {
            display: flex;
            align-items: center;
            gap: 8px;
            color: var(
              --layer-layer-selected-disabled,
              var(--Layer-layer-selected-disabled, #8d8d8d)
            );

            /* Subhead lg - IBM Plex Sans */
            font-family: "IBM Plex Sans";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 17px; /* 141.667% */
            letter-spacing: 0.32px;

            li {
              list-style: disc;
              list-style-position: inside;
            }
          }
        }
        .card-footer {
          display: flex;
          /* margin-top: 24px; */
          justify-content: space-between;
          align-items: center;
        }
      }
      &:hover {
        .image_box {
          img {
            transform: scale(1.1);
          }
        }
      }
    }
    .training-for-admins-pagination {
      margin-top: 2rem;
      ul {
        justify-content: center;
      }
    }
  }
  .knowledge-base {
    padding: 24px 0;
    background-color: #f4f4f4;

    .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
      position: unset;
    }

    .swiper-horizontal
      > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
    .swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
      left: 0;
      transform: translateX(0%);
    }

    .swiper-pagination-bullet.swiper-pagination-bullet-active-next,
    .swiper-pagination-bullet.swiper-pagination-bullet-active-prev,
    .swiper-pagination-bullet.swiper-pagination-bullet-active-prev-prev,
    .swiper-pagination-bullet.swiper-pagination-bullet-active-next-next {
      display: none;
    }

    .heading {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-bottom: 1rem;
      .icon_box {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 40px;
        min-width: 40px;
        height: 40px;
        min-height: 40px;
      }
      h4 {
        font-family: IBM Plex Sans;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
      }
    }
  }
  .card {
    height: 100%;
    display: flex;
    padding: 1.5rem 1rem;
    flex-direction: column;
    justify-content: space-between;
    gap: 28px;
    border: 1px solid #e0e0e0;
    background: #fff;
    margin-bottom: 16px;

    h5 {
      font-family: IBM Plex Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.16px;
      /* margin-bottom: 1rem; */
      height: 48px;
    }
    p {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
    }
    a {
      text-align: end;
      display: flex;
      gap: 0.5rem;
      justify-content: space-between;
      align-items: center;
      color: var(--link-link-primary, var(--link-link-primary, #0f62fe));
      font-family: IBM Plex Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      .google_admins {
        display: flex;
        gap: 10px;
        flex-direction: column;

        span {
          text-align: left;
          color: var(
            --layer-layer-selected-disabled,
            var(--Layer-layer-selected-disabled, #8d8d8d)
          );

          /* Subhead lg - IBM Plex Sans */
          font-family: "IBM Plex Sans";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 17px; /* 141.667% */
          letter-spacing: 0.32px;
        }
        ul {
          color: var(
            --layer-layer-selected-disabled,
            var(--Layer-layer-selected-disabled, #8d8d8d)
          );

          /* Subhead lg - IBM Plex Sans */
          font-family: "IBM Plex Sans";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 17px; /* 141.667% */
          letter-spacing: 0.32px;
          li {
            list-style: disc;
            list-style-position: inside;
          }
        }
      }
    }
    .knowledgbase_image {
      background: linear-gradient(135deg, #f4f4f4 50%, #e0e0e0 50%);
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 48px;
    }
  }
  .faqs {
    padding: 3rem 2.5rem;
    padding-bottom: 227px;
    overflow: hidden;

    @media screen and (max-width: 768px) {
      padding: 1.5rem;
      padding-bottom: 160px;
    }

    .accordion {
      .cds--accordion__item {
        .cds--accordion__title {
          color: ${(props) =>
            props.theme.colorScheme === "white" ? "#161616" : "#fff"};
        }
      }
    }

    .left-content {
      z-index: 2;
      span {
        color: var(--link-link-primary, var(--Default-Blue-60, #2570fe));
        font-family: "IBM Plex Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 137.5% */
      }
      @media screen and (max-width: 768px) {
        margin-bottom: 10%;
      }

      .heading {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-bottom: 1rem;
        .icon_box {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #f4f4f4;
          border-radius: 50%;
          width: 40px;
          min-width: 40px;
          height: 40px;
          min-height: 40px;
        }
        h4 {
          font-family: IBM Plex Sans;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 28px; /* 155.556% */
        }
      }
      p {
        font-family: Roboto;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 153.846% */
        letter-spacing: -0.013px;
      }
    }
  }
  .faq_data {
    margin: 20px 0;

    @media screen and (max-width: 768px) {
      margintop: 20px;
    }

    h6 {
      color: var(--Accessibility-Black, #000);
      font-family: IBM Plex Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px; /* 128.571% */
      letter-spacing: 0.16px;
      margin-bottom: 10px;
    }
    p {
      color: var(--Accessibility-Black, #000);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px; /* 200% */
    }
  }

  .swiper-pagination {
    left: 0;
    bottom: 0;
    text-align: left;
  }
  .second-column {
    padding: 0;
  }
`;
export default TrainingWrapper;
