import {
  Add,
  ArrowRight,
  ChevronDown,
  ChevronRight,
  ChevronUp,
  Close,
  FilterEdit,
  List,
  Subtract,
  Time,
} from "@carbon/icons-react";
import {
  Checkbox,
  Column,
  Dropdown,
  FlexGrid,
  IconButton,
  Layer,
  Modal,
  Loading,
  PaginationNav,
  Row,
  Search,
  Theme,
  Accordion,
  AccordionItem,
  HeaderPanel,
  HeaderMenuButton,
  CheckboxGroup,
  SideNavDivider,
  SideNavLink,
  SideNav,
  SideNavItems,
} from "@carbon/react";
import { GlobalContext } from "ContextApi/GlobalContext";
import CustomButton from "components/button/customButton";
import React, {
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { TraningAdminswrapper } from "./style";
import useWindowSize from "hooks/useWindowSize";
import {
  GetFilterData,
  GetKnowledgeBaseData,
} from "redux/slices/TraningGuidens/TrainingGuidensSlice";
import { handleSearch } from "redux/slices/headerSlice";

const TrainingForAdmins = () => {
  const context = useContext(GlobalContext);
  const dispatch = useDispatch();

  const { width } = useWindowSize();
  const [isfilterModal, setFilterModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const location = useLocation();
  const { getAllDocs, filterOptions, filterData } = useSelector(
    (state) => state.Document
  );

  const [selectedProductOptions, setSelectedProductOptions] = useState([]);
  const [selectedRoleOptions, setSelectedRoleOptions] = useState([]);
  const [selectedLevelOptions, setSelectedLevelOptions] = useState([]);
  const [page, setPage] = useState(0);
  const post_per_page = 15;

  const toggleOption = (option) => {
    if (selectedProductOptions.includes(option)) {
      setSelectedProductOptions(
        selectedProductOptions.filter((item) => item !== option)
      );
    } else {
      setSelectedProductOptions([...selectedProductOptions, option]);
    }
  };

  const toggleLevelOption = (option) => {
    if (selectedLevelOptions.includes(option)) {
      setSelectedLevelOptions(
        selectedLevelOptions.filter((item) => item !== option)
      );
    } else {
      setSelectedLevelOptions([...selectedLevelOptions, option]);
    }
  };

  const toggleRoleOption = (option) => {
    if (selectedRoleOptions.includes(option)) {
      setSelectedRoleOptions(
        selectedRoleOptions.filter((item) => item !== option)
      );
    } else {
      setSelectedRoleOptions([...selectedRoleOptions, option]);
    }
  };

  const handleOptions = (actualOption, categoryType) => {
    if (categoryType == "Product") {
      toggleOption(actualOption.id);
    }
    if (categoryType == "Roles") {
      toggleRoleOption(actualOption.id);
    }

    if (categoryType == "Levels") {
      toggleLevelOption(actualOption.id);
    }
  };

  const isTrainingForAdminPage = useMemo(
    () => location.pathname === "/training/Training-For-admins",
    [location]
  );

  const handlePageChange = (e) => {
    setPage(e);
  };

  const handleResetSearch = () => {
    isTrainingForAdminPage
      ? dispatch(
          GetFilterData({
            page_num: page,
            post_per_page: post_per_page,
            product_name: selectedProductOptions,
            roles: selectedRoleOptions,
            levels: selectedLevelOptions,
            search_term: "",
          })
        )
      : dispatch(
          GetKnowledgeBaseData({
            page_num: page,
            post_per_page: post_per_page,
            product_name: selectedProductOptions,
            roles: selectedRoleOptions,
            levels: selectedLevelOptions,
            search_term: "",
          })
        );
  };

  const handleSearch = (e) => {
    if (e.key == "Enter") {
      isTrainingForAdminPage
        ? dispatch(
            GetFilterData({
              page_num: page,
              post_per_page: post_per_page,
              product_name: selectedProductOptions,
              roles: selectedRoleOptions,
              levels: selectedLevelOptions,
              search_term: searchInput,
            })
          )
        : dispatch(
            GetKnowledgeBaseData({
              page_num: page,
              post_per_page: post_per_page,
              product_name: selectedProductOptions,
              roles: selectedRoleOptions,
              levels: selectedLevelOptions,
              search_term: searchInput,
            })
          );
    }
  };

  useEffect(() => {
    isTrainingForAdminPage
      ? dispatch(
          GetFilterData({
            page_num: page,
            post_per_page: post_per_page,
            product_name: selectedProductOptions,
            roles: selectedRoleOptions,
            levels: selectedLevelOptions,
            search_term: searchInput,
          })
        )
      : dispatch(
          GetKnowledgeBaseData({
            page_num: page,
            post_per_page: post_per_page,
            product_name: selectedProductOptions,
            roles: selectedRoleOptions,
            levels: selectedLevelOptions,
            search_term: searchInput,
          })
        );
  }, [
    selectedProductOptions,
    selectedRoleOptions,
    selectedLevelOptions,
    page,
    location,
  ]);

  return (
    <Theme theme={context?.globalTheme == "white" ? "white" : "g90"}>
      <TraningAdminswrapper>
        <FlexGrid fullWidth>
          <div className="training_adminsmainheading">
            <Row>
              <Column lg={12}>
                <h6 className="!text-[#6F6F6F] dark:!text-[#ffff]">
                  SUPPORT DASHBOARD
                </h6>
                <h4 className="dark:!text-[white]">
                  {isTrainingForAdminPage
                    ? "Training For Admins"
                    : "Knowledge Base"}
                </h4>
              </Column>
              <Column lg={4}>
                <Layer>
                  <div style={{ paddingTop: width <= 768 && "32px" }}>
                    <Search
                      labelText=""
                      size="lg"
                      placeholder="Search input text"
                      closeButtonLabelText="Clear search input"
                      id="search-1"
                      onChange={(e) => setSearchInput(e.target.value)}
                      onKeyDown={(e) => handleSearch(e)}
                      onClear={() => {
                        setSearchInput("");
                        handleResetSearch();
                      }}
                    />
                  </div>
                </Layer>
              </Column>
            </Row>
          </div>
        </FlexGrid>

        <FlexGrid
          fullWidth
          style={{
            backgroundColor:
              context?.globalTheme == "white" ? "#fff" : "#262626",
          }}
        >
          <Row style={{ padding: "32px 0" }}>
            {width <= 479 && (
              <Column xs={16} sm={16}>
                <div style={{ padding: "0px 0px 32px 16px" }}>
                  <CustomButton
                    text={"Filter"}
                    size="lg"
                    style={{ width: "92vw" }}
                    type="submit"
                    handleClick={() => {
                      // setFilterModal(true);
                      setOpen(!open);
                    }}
                    fullWidth
                    icon={ChevronDown}
                  />
                </div>
              </Column>
            )}
            {open && width <= 768 && (
              <CustomSidePanel
                open={open}
                setOpen={setOpen}
                // context={context}
                selectedProductOptions={selectedProductOptions}
                selectedRoleOptions={selectedRoleOptions}
                selectedLevelOptions={selectedLevelOptions}
                handleOptions={handleOptions}
                filterOptions={filterOptions}
              />
            )}

            <Column lg={16}>
              <div className="mainfilter_heading">
                {width > 768 && <h1 className="dark:!text-[white]">Filter</h1>}
                <h6>
                  Viewing{" "}
                  {filterData?.data?.total > 1
                    ? `${filterData?.data?.total || 0} products`
                    : `${filterData?.data?.total || 0} product`}
                </h6>
              </div>
            </Column>

            {width >= 768 && (
              <Column lg={4}>
                <div className="filter_data">
                  {filterOptions?.data?.data?.map((value, index) => (
                    <div>
                      <ul>
                        <li style={{ padding: "16px" }}>
                          <h6 className="dark:!text-[#fff]">
                            {value?.category}
                          </h6>
                        </li>
                        {value?.subcat?.map((subvalue, subinde) => (
                          <li style={{ padding: "0px 16px 8px" }}>
                            <Checkbox
                              labelText={subvalue?.label}
                              id={subvalue?.id}
                              onChange={() =>
                                handleOptions(subvalue, value?.category)
                              }
                            />
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </Column>
            )}

            <Column lg={12}>
              <div className="card-container ">
                {filterData?.status != "loading" ? (
                  filterData?.data?.data?.map((item, ind) => (
                    <DataCard
                      key={ind}
                      value={item}
                      isTrainingForAdminPage={isTrainingForAdminPage}
                    />
                  ))
                ) : (
                  <Loading loading={filterData?.status == "loading"} />
                )}
              </div>
              {width > 768 && filterData?.data?.total_pages > 1 && (
                <div
                  style={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    padding: "20px",
                  }}
                >
                  <PaginationNav
                    itemsShown={10}
                    totalItems={filterData?.data?.total_pages}
                    onChange={(e) => handlePageChange(e)}
                    page={page}
                  />
                </div>
              )}
            </Column>
          </Row>
        </FlexGrid>
      </TraningAdminswrapper>
    </Theme>
  );
};

function DataCard({ value, isTrainingForAdminPage }) {
  const navigate = useNavigate();
  const context = useContext(GlobalContext);

  const decodedText = {
    __html: `Training Document For Admins ${value?.product_name}`,
  };

  const KnowledgeDecodedText = {
    __html: `Mastering AI Management: ${value?.product_name}`,
  };

  if (isTrainingForAdminPage)
    return (
      <div className="View_moredata">
        <div className="View_moredata_cards">
          {/* <div className="medilling_Icon"> */}
          {/* <div className="Icon1">
              <img src={value?.image} alt={value.image1} />
            </div> */}
          {/* <div className="Icon2">
              <img src={value?.image2} alt={value.image2} />
            </div> */}
          {/* </div> */}
          <h4
            className="dark:!text-[white]"
            dangerouslySetInnerHTML={decodedText}
          ></h4>
          <p className="dark:!text-[white]">
            {value?.product_sdesc.split(" ").length > 15
              ? value?.product_sdesc?.split(" ").slice(0, 13).join(" ") + "..."
              : value?.product_sdesc}
          </p>
          <div
            className="time"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              paddingTop: "24px",
            }}
          >
            <Time size={16} />
            <span>{value?.time}</span>
          </div>
          <ul>
            {value?.product}
            <li> {value?.roles}</li>
            <li> {value?.levels}</li>
          </ul>
        </div>
        <div style={{ marginTop: "24px" }}>
          <CustomButton
            size={"md"}
            icon={ArrowRight}
            text={"Launch"}
            handleClick={() =>
              navigate(`/singletraining/${value.product_slug}`, {
                state: isTrainingForAdminPage,
              })
            }
          />
        </div>
      </div>
    );

  return (
    <div className="View_moredata">
      <div className="View_moredata_cards View_moredata_cards_fix">
        {/* <div className="medilling_Icon">
          <div className="Icon1">
            <img src={value.image} alt={value.image1} />
          </div>
        </div> */}
        <div className="cards-body">
          <h5
            className="dark:!text-[white]"
            // dangerouslySetInnerHTML={KnowledgeDecodedText}
          >
            {`Mastering AI Management: ${
              value?.product_name.split(" ").length > 10
                ? value?.product_name?.split(" ").slice(0, 8).join(" ") + "..."
                : value?.product_name
            }`}
          </h5>

          <p>
            {value?.product_sdesc.split(" ").length > 15
              ? value?.product_sdesc?.split(" ").slice(0, 13).join(" ") + "..."
              : value?.product_sdesc}
          </p>
        </div>
        <div className="cards-body">
          <div
            className="time"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              paddingTop: "42px",
            }}
          >
            <Time size={16} />
            <span>{value?.time}</span>
          </div>

          <div className="flex justify-between gap-2">
            <ul>
              {value?.product}
              <li> {value?.roles}</li>
              <li> {value?.levels}</li>
            </ul>

            {/* <IconButton
            label={"View"}
            onClick={() => navigate("/singletraining/slug")}
            align="right"
            size="sm"
            kind="ghost"
          > */}
            <ArrowRight
              size={21}
              onClick={() => navigate(`/singletraining/${value.product_slug}`)}
              fill={context.globalTheme == "white" ? "#2570Fe" : "#75a0fe"}
            />
          </div>
          {/* </IconButton> */}
        </div>
      </div>
    </div>
  );
}

function CustomAccordion({
  selectedProductOptions,
  selectedRoleOptions,
  selectedLevelOptions,
  handleOptions,
  filterOptions,
}) {
  const [openAccordionIndex, setOpenAccordionIndex] = useState(-1);

  const handleAccordionClick = (index) => {
    if (openAccordionIndex === index) {
      setOpenAccordionIndex(-1);
    } else {
      setOpenAccordionIndex(index);
    }
  };

  return (
    <>
      <Accordion className="accordion">
        {filterOptions?.data?.data?.map((value, index) => (
          <>
            <AccordionItem
              // title={`${value?.category} ${
              //   selectedProductOptions?.length > 0
              //     ? `(${selectedProductOptions?.length})`
              //     : ""
              // }`}
              title={value?.category}
              onClick={() => handleAccordionClick(0)}
            >
              {value?.subcat?.map((subvalue, index) => (
                <CheckboxGroup>
                  <Checkbox
                    labelText={
                      subvalue?.label > 23
                        ? subvalue?.label.slice(0, 23) + "..."
                        : subvalue?.label
                    }
                    checked={
                      selectedProductOptions?.includes(subvalue.id) ||
                      selectedLevelOptions?.includes(subvalue.id) ||
                      selectedRoleOptions?.includes(subvalue.id)
                    }
                    id={subvalue?.id}
                    onChange={() => handleOptions(subvalue, value?.category)}
                  />
                </CheckboxGroup>
              ))}
            </AccordionItem>
          </>
        ))}
      </Accordion>
    </>
  );
}

function CustomSidePanel({
  open,
  setOpen,
  selectedProductOptions,
  selectedRoleOptions,
  selectedLevelOptions,
  handleOptions,
  filterOptions,
}) {
  return (
    <>
      <SideNav
        className="dark:!bg-[#262626] !bg-[#fff]  side_tab "
        style={{
          boxShadow:
            "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
          marginTop: "40px",
        }}
        isPersistent={false}
        aria-label="Side navigation"
        expanded={open}
        isFixedNav={false}
        isChildOfHeader={true}
      >
        <SideNavItems className="dark:!text-[#fff] !bg-[#fff] dark:!bg-[#161616]">
          <SideNavLink className="dark:!text-[#fff]  !bg-[#fff] dark:!bg-[#161616]">
            <div className="header">
              <h2 className="dark:!text-white">Filter</h2>

              <HeaderMenuButton
                renderMenuIcon={<Close size={16} />}
                onClick={() => setOpen(!open)}
              />
            </div>
            <hr />
            <CustomAccordion
              selectedLevelOptions={selectedLevelOptions}
              selectedProductOptions={selectedProductOptions}
              selectedRoleOptions={selectedRoleOptions}
              handleOptions={handleOptions}
              filterOptions={filterOptions}
            />
          </SideNavLink>
        </SideNavItems>
      </SideNav>
    </>
  );
}

export default TrainingForAdmins;
